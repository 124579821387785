import store, { dispatch, state } from '__common/store';
import { OPEN_DRAWER_PAGE } from 'actions';
import { parseProjectConfig } from '../saveProject';
import {
  isRMFamily,
  isSF,
  isRM5,
  isRMDT,
  isGFT,
  isSMFamily,
  isSFMFamily,
  isULA,
  isRMIFIProduct,
  isMetalX,
  isRM10,
  isSMTiltVariations,
  isSfmInfinity,
  isSolarMount,
  isNxtHorizon,
  isAscender,
  isEcoFoot2Plus,
  isGroundProduct,
  isRM10Evolution,
  isSMTilt,
  isRMGridFlex,
  isRM10orRM10Evo,
  isSMTiltPR,
  isSunframeMicroRail,
  isCanadianChangesProduct,
  isRmGridflex10,
  isNxtTilt,
} from '__common/constants/products';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { inchesToMillimeters } from '__common/calculations/inchesToMillimeters';
import { validateModuleSize, validateWidth, validateLength, _isInRange } from '__common/components/moduleSelector/components/customModuleValidation';
import { isModuleSelectorValid, isModuleLoaded } from '__common/components/moduleSelector/moduleSelectorHelper';
import { RAIL_SYSTEM_PAGE, LOCATION_AND_LOADS_PAGE, MODULE_SELECTION_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import frontEdgeHeightField, { MAX_FRONT_EDGE_HEIGHT_FOR_DRIVEN } from 'projectDesign/components/projectConfiguration/fields/frontEdgeHeight';
import isString from 'lodash/isString';
import { AttachmentOptimization } from 'projectDesign/components/projectConfiguration/fields/attachmentOptimizationCriteria';
import { isNumber, round } from 'lodash';
import { AttachmentType } from 'projectDesign/components/projectConfiguration/fields/attachmentType';
import { isASCE705, isASCE710, isASCE716, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { upliftAllowableForCustom, upliftAllowableForFlashloc, upliftAllowableForOMG, upliftAllowableForUAnchor } from 'projectDesign/components/projectConfiguration/fields/upliftAllowable';
import { shearAllowableForCustom, shearAllowableForFlashloc, shearAllowableForOMG, shearAllowableForUAnchor } from 'projectDesign/components/projectConfiguration/fields/shearAllowable';
import { getAttachmentTypeOptions } from 'projectDesign/components/projectConfiguration/fields/metalXAttachmentType';
import { ROOF_TYPES } from 'projectDesign/components/projectConfiguration/fields/metalXRoofType';
import { RESTRICTED_MODEL_IDS, _width_range } from '__common/constants/modules';
import { maxDownPointLoadToBeAllowedField } from 'projectDesign/components/projectConfiguration/fields/maxDownPointLoadToBeAllowed';
import { CLEAR_INVALID_FIELD, SET_FIELD_INVALID } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import { greaterThanEqualToProjectVersion, applyShortestBuildingLength, applyDeadloadFactorLimitChanges, applyRM5Revamp, applyEcoFoot2PlusRM10andEvoSetbackChanges, applyRMGF5SetbackChanges } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import parapetHeightNumeric from 'projectDesign/components/projectConfiguration/fields/parapetHeightNumeric';
import riskCategory, { RiskCategory } from 'projectDesign/components/projectConfiguration/fields/riskCategory';
import { isSMFlushMetric } from '__common/utils/products';
import { apiField as windSpeedApiField } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { buildingHeightGreaterThan72WarningCondition, buildingStoreyWarningCondition, isSpanMultipleOfRafterSpacing, roofSlopeWarningCondition, attachmentWarningConditionForRiskCategoryIV, isOsbOrPlywood, isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';
import { shortestBuildingLengthField, shortestBuildingLengthMetric } from 'projectDesign/components/projectConfiguration/fields/shortestBuildingLength';
import { longestBuildingLengthField, longestBuildingLengthMetric } from 'projectDesign/components/projectConfiguration/fields/longestBuildingLength';
import { showErrorAlert } from '__common/modules/alerts';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { cmsToInches, feetsToCms, lbsToKgs, metersToCms, psfToKpa } from '__common/calculations/unitConversions';
import { getSeismicSetback } from '__editor/panelsEditor/components/roofZones/utils/setBackDistanceCollisionDetections';
import rafterSpacing, { rafterSpacingMetric } from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import span, { preferredSpanMetric } from 'projectDesign/components/projectConfiguration/fields/span';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import nbccWindPressure from 'projectDesign/components/projectConfiguration/fields/nbccWindPressure';
import { RoofType } from 'projectDesign/components/projectConfiguration/utils/constants';
import seismicSdsField from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import {metricTornadoSpeedField, apiField as tornadoSpeedApiField} from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import seismicSd1Field from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeedField from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import longTransitionPeriodsField, { apiField as longTransitionPeriodsApiField } from 'projectDesign/components/projectConfiguration/fields/longTransitionPeriods';
import shortestRowLength from 'projectDesign/components/projectConfiguration//fields/shortestRowLength';
import longestRowLength from 'projectDesign/components/projectConfiguration//fields/longestRowLength';
import { riskCategoryCheckForTornadoSpeed } from 'projectDesign/components/projectConfiguration/constraints/constraints';
import productsSettingsData from '__editor/panelsEditor/components/productsSettings/productsSettingsData';
import { FoundationType } from 'projectDesign/components/projectConfiguration/fields/foundationType';

export const validateProject = (productId: number, openDrawer: boolean) => {
  const { drawingManager: { roofs }, moduleSelector: { modelData }, projectConfiguration: { projectEnvConfig: { building_code, seismic_sds, tornado_speed, risk_category }, inputUnit } } = state();

  if (!isModuleSelectorValid() || !isModuleLoaded()) {
    return false;
  }

  if (Object.keys(modelData).length === 0) {
    return false;
  }

  if (!(isGFT(productId) || isULA(productId)) && !checkModelId()) {
    return false;
  }

  if (!anyPanelsDrawn()) {
    return false;
  }

  if (!isZipcodeValid(openDrawer)) {
    return false;
  }

  if (roofs && Object.keys(roofs).length) {
    if(isASCE722(building_code) && !(checkSeismicSds(seismic_sds, openDrawer) && checkTornadoSpeedLimit(tornado_speed, risk_category, openDrawer, inputUnit))){
      return false;
    }

    if ((isRMFamily(productId) && !checkRmFamily(openDrawer)) || (isEcoFoot2Plus(productId) && !checkEcofoot2Plus(openDrawer))) {
      return false;
    }

    if ((isSMFamily(productId) || isSFMFamily(productId)) && !checkSmFamily(modelData.width, modelData.height, productId, openDrawer)) {
      return false;
    }

    if (isAscender(productId) && !checkAscenderfamily(modelData.width, modelData.height, productId, openDrawer)) {
      return false;
    }

    if (isNxtHorizon(productId) && !checknhFamily(modelData.width, modelData.height, productId, openDrawer)) {
      return false;
    }

    if (isSMTiltPR(productId) && !checkSmTiltPRFamily(modelData.width, modelData.height, productId, openDrawer)) {
      return false
    }

    if (isGFT(productId) && !checkGFT(openDrawer)) {
      return false;
    }

    if (isULA(productId) && !checkULA(openDrawer)) {
      return false;
    }
    if (isMetalX(productId) && !checkMetalX(openDrawer)) {
      return false;
    }

    if (isSFMFamily(productId) && !checkSFMFamily(openDrawer)) {
      return false;
    }

    if (!checkEnvironmentalFactors(productId)) {
      setTimeout(() => {
        dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
      });
      return false;
    }
    return true;
  }
};

export function checkModelId() {
  const { moduleSelector: { modelData }, } = state();
  if (modelData?.id) {
    for (let i = 0; i < RESTRICTED_MODEL_IDS.length; i++) {
      if (modelData?.id === RESTRICTED_MODEL_IDS[i]) {
        dispatch(OPEN_DRAWER_PAGE(MODULE_SELECTION_PAGE));
        showErrorAlert('The selected module is not compatible as per fire rating, please select another module for your project.');
        return false;
      }
    }
    return true;
  }
}

function checkRmFamily(openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      productId,
      projectVersion,
      projectConfigurated,
      inputUnit
    },
    moduleSelector: {
      modelData: {
        width,
        thickness,
        height,
      },
    },
    user: { isStaff, isPrivilegedUser },
  } = state();

  const parsedEnvConfig = parseProjectConfig(projectEnvConfig);

  let {
    seismic_s1,
    wind_exposure,
    building_height,
    roof_type,
    parapet_height,
    parapet_height_input,
    building_length,
    setback_distance,
    block_weight,
    attachment_optimization_criteria,
    roof_psf_limit,
    total_weight_on_roof_limit,
    attachment_type,
    uplift_allowable,
    shear_allowable,
    topographical_factor_kzt,
    snow_load,
    wind_speed,
    allow_mechanical_attachments,
    dead_load_factor_modification,
    limit_down_point_loads,
    max_down_point_load_to_be_allowed,
    is_half_block_allowed,
    half_block_weight,
    shortest_building_length,
    created_user_is_staff,
    building_code,
    deflectors,
    risk_category,
    zipcode,
    nbcc_wind_pressure,
    rain_load,
    anchor_type,
    seismic_sd1,
  } = parsedEnvConfig;

  const thicknessInches = isMetricUnit(inputUnit) ? cmsToInches(thickness) : thickness;

  return checkWindExposure(wind_exposure, openDrawer) &&
    checkBuildingHeight(building_height, openDrawer) &&
    checkAttacmentsAreRequired(building_code, productId, openDrawer) &&
    checkRoofType(roof_type, openDrawer) &&
    checkSnowLoadLimit(snow_load, openDrawer) &&
    (isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) ? true : checkWindSpeedLimit(wind_speed, openDrawer) && checkRiskCategory(risk_category, openDrawer)) &&
    checkThickness(thicknessInches, productId, openDrawer) &&
    checkRiskCategory(risk_category, openDrawer)
    &&
    (isRMIFIProduct(productId) ?
      checkTopographicalFactorKzt(topographical_factor_kzt, openDrawer, productId) &&
      checkSetbackDistance(setback_distance, openDrawer) &&
      checkSeismicS1(seismic_s1, openDrawer) &&
      checkRoofPsfLimit(roof_psf_limit, attachment_optimization_criteria, openDrawer) &&
      checkTotalWeightOnRoofLimit(total_weight_on_roof_limit, attachment_optimization_criteria, openDrawer) :
      checkLongestBuildingLength(building_length, openDrawer) &&
      checkShortestBuildingLength(building_length, openDrawer)
    )
    &&
    (isRMIFIProduct(productId) || ((isStaff || isPrivilegedUser) && isRMFamily(productId) && !(isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode))) ?
      checkDeadloadFactor(dead_load_factor_modification, openDrawer, building_code, wind_exposure, risk_category, wind_speed, building_height) : true
    )
    && (isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) ?
      checkNbccWindPressure(nbcc_wind_pressure) &&
      checkRainLoad(rain_load, openDrawer)
      : true
    )
    &&
    (isRMIFIProduct(productId) || isRM10Evolution(productId) || isRmGridflex10(productId) ?
      checkParapetHeightRmIFIEvo(parapet_height_input, inputUnit, openDrawer) :
      checkParapetHeight(parapet_height, openDrawer)
    )
    &&
    (applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) ?
      checkSeismicS1(seismic_s1, openDrawer) &&
      checkRMSetbackDistance(productId, projectEnvConfig, inputUnit, openDrawer)
      : true
    )
    &&
    (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || isRMDT(productId) || applyRM5Revamp(projectVersion) ?
      (limit_down_point_loads ? checkMaxDownPointLoad(max_down_point_load_to_be_allowed, openDrawer, inputUnit) : true) : true
    )
    &&
    (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || applyRM5Revamp(projectVersion) ?
      checkRoofPsfLimit(roof_psf_limit, attachment_optimization_criteria, openDrawer) &&
      checkHalfBlockWeight(is_half_block_allowed, half_block_weight, block_weight, openDrawer) &&
      (allow_mechanical_attachments ?
        checkUpliftAllowable(Number(uplift_allowable), attachment_type, productId, projectVersion, anchor_type, inputUnit, openDrawer, projectEnvConfig?.choice_of_fastend) &&
        checkShearAllowable(Number(shear_allowable), attachment_type, productId, projectVersion, anchor_type, inputUnit, openDrawer, projectEnvConfig?.choice_of_fastend) : true) : true
    )
    &&
    (isRMIFIProduct(productId) ? true : applyShortestBuildingLength(projectVersion) ?
      checkLongestBuildingLength(building_length, openDrawer) &&
      checkLongestAndShortestBuildingLength(building_length, shortest_building_length, openDrawer) &&
      checkShortestBuildingLength(shortest_building_length, openDrawer) : checkLongestBuildingLength(building_length, openDrawer))
    &&
    (applyRM5Revamp(projectVersion) ? checkWindDeflectors(deflectors, openDrawer) && checkSeismicS1(seismic_s1, openDrawer) : true)
    &&
    checkRMModuleArea(width, height, productId, isStaff, openDrawer, inputUnit) &&
    checkRMModuleDims(width, height,  productId, isStaff, openDrawer, inputUnit) &&
    checkRMBlockWeight(block_weight, inputUnit, openDrawer) && 
    (((isRM10(productId) || isRM10Evolution(productId)) && (projectConfigurated ? isStaff && created_user_is_staff : isStaff))?
    checkAdditionalUserInputs(projectEnvConfig, openDrawer) : true) &&
    (isASCE722(building_code) ? checkSeismicSd1(seismic_sd1, openDrawer) : true)
}


function checkEcofoot2Plus(openDrawer: boolean) {
  const {
    projectConfiguration: {
      productId,
      projectEnvConfig,
      projectConfigurated,
      projectVersion,
      inputUnit
    },
    moduleSelector: {
      modelData: {
        width,
        height,
        thickness,
      },
    },
    user: { isStaff },
  } = state();

  const parsedEnvConfig = parseProjectConfig(projectEnvConfig);

  const {
    wind_speed,
    snow_load,
    limit_down_point_loads,
    max_down_point_load_to_be_allowed,
    building_length,
    shortest_building_length,
    building_height,
    parapet_height,
    created_user_is_staff,
    seismic_s1,
    block_weight,
    zipcode,
    nbcc_wind_pressure,
    rain_load,
  } = parsedEnvConfig;

  const thicknessInches = isMetricUnit(inputUnit) ? cmsToInches(thickness) : thickness;

  return checkEcofoot2PlusModuleArea(width, height, productId, isStaff, openDrawer, inputUnit)
    && (!isCanadianZipcode(zipcode) ? checkWindSpeedLimit(wind_speed, openDrawer) : true)
    && checkSnowLoadLimit(snow_load, openDrawer) &&
    checkLongestBuildingLength(building_length, openDrawer) &&
    checkShortestBuildingLength(shortest_building_length, openDrawer)
    && checkBuildingHeight(building_height, openDrawer)
    && checkThickness(thickness, productId, openDrawer)
    && checkLongestAndShortestBuildingLength(building_length, shortest_building_length, openDrawer)
    && checkParapetHeight(parapet_height, openDrawer)
    && checkRMBlockWeight(block_weight, inputUnit, openDrawer)
    && ((projectConfigurated ? isStaff && created_user_is_staff : isStaff) ?
      checkAdditionalUserInputs(projectEnvConfig, openDrawer) : true)
    && checkThickness(thicknessInches, productId, openDrawer)
    && (limit_down_point_loads ? checkMaxDownPointLoad(max_down_point_load_to_be_allowed, openDrawer)
      : true)
    && (applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) ?
      checkSeismicS1(seismic_s1) &&
      checkEcofoot2PlusSetbackDistance(productId, projectEnvConfig, inputUnit, openDrawer)
      : true
    )
    && (isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) ?
      checkNbccWindPressure(nbcc_wind_pressure) &&
      checkRainLoad(rain_load, openDrawer)
      : true
    );
}


function checkSFMFamily(openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      productId,
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const parsedEnvConfig = parseProjectConfig(projectEnvConfig);

  const { wind_speed, snow_load } = parsedEnvConfig;

  return checkWindSpeedLimit(wind_speed, openDrawer) &&
    checkSnowLoadLimit(snow_load, openDrawer) &&
    checkThickness(thickness, productId, openDrawer);
}


function checkSmFamily(width: number, height: number, productId: number, openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      inputUnit,
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const { topographical_factor_kzt, building_code, wind_speed, tilt, clamps_choices, snow_load, shortest_building_length, building_height, risk_category, rafter_spacing_inches, preferred_span, roof_type, roof_substrate, attachment_type, } = projectEnvConfig;

  if (isASCE716or722(building_code) && !checkRiskCategory(risk_category)) {
    return false;
  }

  if (!checkWindSpeedLimit(Number(wind_speed), openDrawer) && !checkSnowLoadLimit(Number(snow_load), openDrawer)) {
    return false;
  }

  if (!(isOsbOrPlywood(roof_type, attachment_type, roof_substrate)) && !checkRafterSpacing(Number(rafter_spacing_inches), openDrawer)) {
    return false;
  }

  if (!checkWindSpeedLimit(wind_speed, openDrawer)) {
    return false;
  }

  if (!checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkPreferredSpan(Number(preferred_span), openDrawer)) {
    return false;
  }

  if (!checkSMModuleDims(width, height, productId, openDrawer, inputUnit)) {
    return false;
  }

  if (!checkShortestBuildingLength(Number(shortest_building_length), openDrawer)) {
    return false;
  }


  if (isSfmInfinity(productId) || (isSolarMount(productId) && tilt === null) || isNxtHorizon(productId)) {
    if (!checkTopographicalFactorKzt(topographical_factor_kzt, openDrawer, productId, wind_speed, building_code, tilt)) {
      if (openDrawer) {
        dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
      }
      return false;
    }
  }

  if (isSMTilt(productId)) {
    if (!checkClampsChoice(clamps_choices, openDrawer)) {
      return false;
    }
  }

  if (isSfmInfinity(productId) || (isSolarMount(productId) && tilt === null)) {
    if (!checkBuildingHeight(building_height, openDrawer)) {
      return false;
    }
  }

  if (!(isSunframeMicroRail(productId) || isOsbOrPlywood(roof_type, attachment_type, roof_substrate)) && !checkSpanAndRafterSpacing(preferred_span, rafter_spacing_inches, openDrawer)) {
    return false;
  }

  if (!checkRoofTypeMissing(roof_type)) {
    return false
  }

  return checkThickness(thickness, productId, openDrawer);
}


function checkSmTiltPRFamily(width: number, height: number, productId: number, openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      inputUnit
    },
  } = state();
  const { wind_speed, snow_load, shortest_building_length, building_height, rafter_spacing_inches } = projectEnvConfig;

  if (!checkWindSpeedLimit(wind_speed, openDrawer) && !checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkWindSpeedLimit(wind_speed, openDrawer)) {
    return false;
  }

  if (!checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkRafterSpacing(Number(rafter_spacing_inches), openDrawer)) {
    return false;
  }

  if (!checkSMModuleDims(width, height, productId, openDrawer, inputUnit)) {
    return false;
  }

  if (!checkShortestBuildingLength(shortest_building_length, openDrawer)) {
    return false;
  }

  if (!checkBuildingHeight(building_height, openDrawer)) {
    return false;
  }

  return true;
}

function checkAscenderfamily(width: number, height: number, productId: number, openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig
    },
  } = state();

  const parsedEnvConfig = parseProjectConfig(projectEnvConfig);

  const { shortest_building_length, building_length, building_height, parapet_height_num, rail_length_for_thermal_gap, allow_thermal_gap, wind_speed } = parsedEnvConfig;

  return checkShortestBuildingLength(shortest_building_length, openDrawer,) &&
    checkLongestBuildingLength(building_length, openDrawer) &&
    checkBuildingHeight(building_height, openDrawer) &&
    checkSMModuleDims(width, height, productId, openDrawer) &&
    checkParapetHeightNumeric(parapet_height_num, openDrawer) &&
    checkRailLengthForThermalGap(rail_length_for_thermal_gap, allow_thermal_gap, openDrawer) &&
    checkWindSpeedLimit(wind_speed, openDrawer) &&
    checkLongestAndShortestBuildingLength(building_length, shortest_building_length, openDrawer)
}

function checknhFamily(width: number, height: number, productId: number, openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      inputUnit
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const { topographical_factor_kzt, building_code, wind_speed, tilt, snow_load, shortest_building_length, building_height, rafter_spacing_inches, preferred_span,roof_type, risk_category } = projectEnvConfig;

  if (!checkWindSpeedLimit(wind_speed, openDrawer) && !checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkRafterSpacing(Number(rafter_spacing_inches), openDrawer)) {
    return false;
  }

  if (!checkSMModuleDims(width, height, productId, openDrawer, inputUnit)) {
    return false;
  }

  if (!checkShortestBuildingLength(shortest_building_length, openDrawer)) {
    return false;
  }

  if (isNxtHorizon(productId)) {
    if (!checkTopographicalFactorKzt(topographical_factor_kzt, openDrawer, productId, wind_speed, building_code, tilt)) {
      if (openDrawer) {
        dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
      }
      return false;
    }
  }

  if (!checkBuildingHeight(building_height, openDrawer)) {
    return false;
  }

  if (!checkSpanAndRafterSpacing(preferred_span, rafter_spacing_inches, openDrawer)) {
    return false;
  }

  if (!checkWindSpeedLimit(wind_speed, openDrawer)) {
    return false;
  }

  if (!checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkRoofTypeMissing(roof_type)) {
    return false
  }
  
  if(isASCE716or722(building_code) && !checkRiskCategory(risk_category, openDrawer)) {
    return false;
  }

  return checkThickness(thickness, productId, openDrawer);
}

function checkGFT(openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      productId,
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const { rail_arrangement_type, seismic_ss, seismic_s1, wind_exposure, risk_category, wind_on_ice, ice_thickness, front_edge_height, tilt, foundation_length, wind_speed, snow_load, building_code, seismic_sd1, long_transition_periods_tl, longest_row_length, shortest_row_length, foundation_type } = projectEnvConfig;

  if (!checkWindSpeedLimit(wind_speed, openDrawer) && !checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkWindSpeedLimit(wind_speed, openDrawer)) {
    return false;
  }

  if (!checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (
    !checkWindExposure(wind_exposure) ||
    !checkSeismicSs(seismic_ss) ||
    !checkSeismicS1(seismic_s1) ||
    !checkWindOnIce(wind_on_ice) ||
    !checkIceThickness(ice_thickness) ||
    !checkFieldNotEmpty(risk_category) ||
    (isASCE722(building_code) && (!checkSeismicSd1(seismic_sd1, openDrawer) || !checkLongTransitionPeriods(long_transition_periods_tl, openDrawer) || !checkLongestRowLength(longest_row_length, openDrawer) || !checkShortestRowLength(shortest_row_length, openDrawer)))
  ) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
    }
    return false;
  }
  if (!checkFieldNotEmpty(rail_arrangement_type) || !checkFrontEdgeHeight(front_edge_height, tilt, foundation_type) || !checkFieldNotEmpty(foundation_length)) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
    }
    return false;
  }


  return checkThickness(thickness, productId, openDrawer);

}

function checkMetalX(openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      productId,
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const { seismic_ss, wind_exposure, risk_category, topographical_factor_kzt, building_code, wind_speed, attachment_type, roof_type, material_thickness, snow_load, shortest_building_length, building_height, preferred_span, rafter_spacing_inches } = projectEnvConfig;

  if (!checkWindSpeedLimit(wind_speed, openDrawer) && !checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (!checkWindSpeedLimit(wind_speed, openDrawer)) {
    return false;
  }

  if (!checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (
    !checkWindExposure(wind_exposure) ||
    !checkTopographicalFactorKztMetalX(topographical_factor_kzt, wind_speed, building_code) ||
    !checkSeismicSs(seismic_ss) ||
    (isASCE716(building_code) ? !checkFieldNotEmpty(risk_category) : false)
  ) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
    }
    return false;
  }
  if (!checkAttachmentTypeMetalX(attachment_type, roof_type, material_thickness)) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
    }
    return false;
  }
  if (!checkShortestBuildingLength(shortest_building_length, openDrawer)) {
    return false;
  }
  if (!checkBuildingHeight(building_height, openDrawer)) {
    return false;
  }
  if (!checkMetalXSpans(preferred_span, openDrawer)) {
    return false;
  }
  if (!checkSpanAndRafterSpacing(preferred_span, rafter_spacing_inches, openDrawer)) {
    return false;
  }
  return true && checkThickness(thickness, productId, openDrawer);

}


function checkULA(openDrawer: boolean) {
  const {
    projectConfiguration: {
      projectEnvConfig,
      productId,
    },
    moduleSelector: {
      modelData: {
        thickness,
      },
    },
  } = state();

  const { seismic_ss, seismic_s1, wind_exposure, risk_category, front_edge_height, rails_per_module, wind_speed, snow_load, building_code, seismic_sd1, long_transition_periods_tl, longest_row_length, shortest_row_length } = projectEnvConfig;

  if (!checkWindSpeedLimit(wind_speed, openDrawer) && !checkSnowLoadLimit(snow_load, openDrawer)) {
    return false;
  }

  if (
    !checkWindExposure(wind_exposure) ||
    !checkSeismicSs(seismic_ss) ||
    !checkSeismicS1(seismic_s1) ||
    !checkFieldNotEmpty(risk_category) ||
    (isASCE722(building_code) && (!checkSeismicSd1(seismic_sd1, openDrawer) || !checkLongTransitionPeriods(long_transition_periods_tl, openDrawer) || !checkLongestRowLength(longest_row_length, openDrawer) || !checkShortestRowLength(shortest_row_length, openDrawer)))
  ) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
    }
    return false;
  }

  if (!checkFrontEdgeHeightUla(front_edge_height)) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
    }
    return false;
  }

  if (!checkFieldNotEmpty(rails_per_module)) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
    }
    return false;
  }
  return checkThickness(thickness, productId, openDrawer);


}


function checkEnvironmentalFactors(productId) {
  const { projectConfiguration: { projectEnvConfig: { building_code, snow_load, elevation, seismic_ss, wind_speed } } } = state();
  return checkWindSpeed(wind_speed) && checkBuildingCode(building_code) && checkSnow(snow_load) && checkElevation(elevation) && (isAscender(productId) || checkSeismic(seismic_ss));
}


export function checkWindExposure(wind_exposure: string, openDrawer?: boolean) {
  switch (wind_exposure) {
    case 'B':
    case 'C':
    case 'D':
      return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkNbccWindPressure(nbcc_wind_pressure: number, openDrawer?: boolean) {
  if (nbccWindPressure.min <= nbcc_wind_pressure && nbcc_wind_pressure <= nbccWindPressure.max) return true;
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkRainLoad(rain_load: number, openDrawer?: boolean) {
  if (isNumber(rain_load) && 0.1 <= rain_load && rain_load <= 1) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkTopographicalFactorKzt(topographical_factor_kzt: number, openDrawer?: boolean, productId?: any, windSpeed?: number, buildingCode?: number, tilt?: any) {
  if (isRMIFIProduct(productId)) {
    if (topographical_factor_kzt >= 1 && topographical_factor_kzt <= 5) {
      return true;
    }
  }
  if (isSfmInfinity(productId) || (isSolarMount(productId) && tilt === null) || isNxtHorizon(productId) || isSMTiltPR(productId)) {
    if (isASCE710(buildingCode) || isASCE705(buildingCode)) {
      if (topographical_factor_kzt >= 1 && topographical_factor_kzt <= 5 && topographical_factor_kzt * windSpeed <= 190) {
        return true;
      }
    } else if (isASCE716or722(buildingCode) && topographical_factor_kzt >= 1 && topographical_factor_kzt <= 5) {
      return true;
    }
  }
  if (isAscender(productId)) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkSnowLoadLimit(snow_load: number, openDrawer?: boolean) {
  const { lowerLimit, upperLimit } = getSnowLoadLimits();
  if (snow_load >= lowerLimit && snow_load <= upperLimit) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkWindSpeedLimit(wind_speed: number, openDrawer?: boolean) {
  const { lowerLimit, upperLimit } = getWindSpeedLimits();
  dispatch(CLEAR_INVALID_FIELD(windSpeedApiField));
  if (wind_speed >= lowerLimit && wind_speed <= upperLimit) {
    return true;
  }
  dispatch(CLEAR_INVALID_FIELD(windSpeedApiField));
  dispatch(SET_FIELD_INVALID(windSpeedApiField, `Wind speed must lie between ${lowerLimit} and ${upperLimit}.`));

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkDeadloadFactor(dead_load_factor_modification: number, openDrawer: boolean, building_code: number, wind_exposure: string, risk_category: number, wind_speed: number, building_height: number) {
  const { projectConfiguration: { projectVersion, productId } } = state()
  if (isRmGridflex10(productId)){
    if([RiskCategory.III, RiskCategory.IV].includes(risk_category)){
      if(dead_load_factor_modification >= 0.001 && dead_load_factor_modification <= 0.6){
        return true
      }
    } else {
      if (dead_load_factor_modification >= 0.001 && dead_load_factor_modification <= 0.75) {
        return true;
      }
    }
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
    }
    return false;
  }
  if (isASCE705(building_code) || (!isASCE705(building_code) && (wind_exposure === 'D' || risk_category > 3 || wind_speed > 140 || building_height > 100)) && applyDeadloadFactorLimitChanges(projectVersion)) {
    if (dead_load_factor_modification >= 0.001 && dead_load_factor_modification <= 0.6) {
      return true;
    }
  } else {
    if (dead_load_factor_modification >= 0.001 && dead_load_factor_modification <= 1) {
      return true;
    }
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }
  return false;
}

export function checkTornadoSpeedLimit(tornado_speed: number, riskCategory: number, openDrawer?: boolean, inputUnit?: number) {
  if(riskCategoryCheckForTornadoSpeed(riskCategory)) {
    dispatch(CLEAR_INVALID_FIELD(tornadoSpeedApiField));
    const { min, max } = isMetricUnit(inputUnit) ? metricTornadoSpeedField : tornadoSpeedField;
    if (tornado_speed >= min && tornado_speed <= max) {
      return true;
    }

    dispatch(SET_FIELD_INVALID(tornadoSpeedApiField, `tornado speed must lie between ${min} and ${max}`));

    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
    }

    return false;
  }
  else
    return true;
}

export function checkLongTransitionPeriodsLimit(long_transition_periods: number, openDrawer?: boolean) {
  dispatch(CLEAR_INVALID_FIELD(longTransitionPeriodsApiField));
  const { min, max } = longTransitionPeriodsField;
  if (long_transition_periods >= min && long_transition_periods <= max) {
    return true;
  }

  dispatch(SET_FIELD_INVALID(longTransitionPeriodsApiField, `Long Transition Periods must lie between ${min} and ${max}`));

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}


export function checkTopographicalFactorKztMetalX(topographical_factor_kzt: number, windSpeed?: number, buildingCode?: number, openDrawer?: boolean) {
  if (isASCE710(buildingCode)) {
    if (topographical_factor_kzt >= 1 && topographical_factor_kzt <= 5 && topographical_factor_kzt * windSpeed <= 190) {
      return true;
    }
  } else if (isASCE716or722(buildingCode) && topographical_factor_kzt >= 1 && topographical_factor_kzt <= 5) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkAttachmentTypeMetalX(attachment_type: number, roof_type: number, material_thickness: number, openDrawer?: boolean) {
  const standingSeamSelected = roof_type === ROOF_TYPES.STANDING_SEAM;
  const rPanelSelected = roof_type === ROOF_TYPES.R_PANEL;
  const corrugatedSelected = roof_type === ROOF_TYPES.CORRUGATED_METAL;
  const roofTypeOptions = [standingSeamSelected, rPanelSelected, corrugatedSelected];
  const attachment_values = getAttachmentTypeOptions(roofTypeOptions, material_thickness).map(i => i.value);

  if (attachment_values.includes(attachment_type)) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}


export function checkSMModuleDims(width: number, height: number, productId: number, openDrawer?: boolean, inputUnit?: number) {
  const widthError = validateWidth(width, productId, false, inputUnit);
  const heightError = validateLength(height, productId, inputUnit);

  if (heightError.length === 0 && widthError.length === 0) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  return false;
}

function checkRMModuleArea(width: number, height: number, productId: number, isStaffUser: boolean, openDrawer: boolean, inputUnit: number) {
  const areaError = validateModuleSize(width, height, productId, isStaffUser, inputUnit);

  if (areaError.length === 0) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  return false;
}

function checkEcofoot2PlusModuleArea(width: number, height: number, productId: number, isStaffUser: boolean, openDrawer: boolean, inputUnit: number) {
  const areaError = validateModuleSize(width, height, productId, isStaffUser, inputUnit);

  if (areaError.length === 0) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  return false;
}

export function checkMaxDownPointLoad(maxDownPointLoad: number, openDrawer?: boolean, inputUnit: number = 1) {

  if (maxDownPointLoad !== undefined && maxDownPointLoad >= maxDownPointLoadToBeAllowedField(inputUnit).min && maxDownPointLoad <= maxDownPointLoadToBeAllowedField(inputUnit).max) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
  }

  return false;
}


function checkRMModuleDims(width: number, height:number, productId: number, isStaffUser: boolean, openDrawer: boolean, inputUnit: number) {
  const widthError = validateWidth(width, productId, isStaffUser, inputUnit);
  const heightError = validateLength(height, productId, inputUnit)

  if (heightError.length === 0 && widthError.length === 0) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  return false;
}

export function checkRMBlockWeight(block_weight: number, inputUnit: number, openDrawer?: boolean) {
  if (block_weight && block_weight.constructor === Number && block_weight >= 1 &&
    (isMetricUnit(inputUnit) ? block_weight <= round(lbsToKgs(100), 1) : block_weight <= 100)
  ) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkSMTiltPRThickness(thicknessInches: number, productId: number, openDrawer?: boolean) {
  const thicknessMM = inchesToMillimeters(thicknessInches);
  // 1.18 in = 29.972 mm
  // 30 mm = 1.18110236 in
  // We don't need that heigh precision
  // so we can allow some rounding (especially that the mm field converts the value to integers)
  const thicknessMMRounded = parseFloat(thicknessMM.toFixed(1));
  if (thicknessMMRounded >= 30) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }
  return false;
}

export function checkThickness(thicknessInches: number, productId: number, openDrawer?: boolean) {

  const thicknessInMm = Math.round(inchesToMillimeters(thicknessInches))
  const { projectConfiguration: { projectVersion } } = store.getState()
  if (((!greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['module_thickness_changes']) || (isRM10(productId) || isRM10Evolution(productId) || isRMGridFlex(productId))) && thicknessInches > 0) || ((isRM5(productId) || isRMDT(productId)) && (thicknessInMm >= 30 && thicknessInMm <= 50)) || (!(isRM5(productId) || isRMDT(productId)) && thicknessInMm >= 30)) {
    return true;
  }
  else if (isRmGridflex10(productId)) {
    if (thicknessInMm >= 30 && thicknessInMm <=46){
      return true;
    }else {
      return false;
    }
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('module-selection'));
  }

  return false;
}

export function checkRoofType(roof_type: number, openDrawer?: boolean) {

  if (roof_type && roof_type > 0 && roof_type < 23) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}


export function checkEcoFoot2PlusRoofType(roof_type: number, openDrawer?: boolean) {
  if (roof_type && roof_type > 0 && roof_type <= 12) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkEcoFoot2PlusSurface(ecofoot_surface: number, openDrawer?: boolean) {
  if (ecofoot_surface && ecofoot_surface > 0 && ecofoot_surface < 4) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkEcoFoot2PlusSlipSheet(slip_sheet: number, openDrawer?: boolean) {
  if (slip_sheet && slip_sheet > 0 && slip_sheet < 3) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}


export function checkParapetHeight(parapet_height: number, openDrawer?: boolean) {
  
  if (checkFieldNotEmpty(parapet_height) && parapet_height.constructor === Number && parapet_height > 0 && parapet_height < 4) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}


export function checkParapetHeightRmIFIEvo(parapet_height_input: number, inputUnit: number, openDrawer?: boolean) {
  if (checkFieldNotEmpty(parapet_height_input) && parapet_height_input.constructor === Number && parapet_height_input >= 0 &&
    (isMetricUnit(inputUnit) ? parapet_height_input <= (feetsToMeters(100) | 0) : parapet_height_input <= 100)) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkHalfBlockWeight(is_half_block_allowed: boolean, half_block_weight: number, block_weight: number, openDrawer?: boolean) {
  if (!is_half_block_allowed || half_block_weight < block_weight) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}


export function checkRoofPsfLimit(roof_psf_limit: number, attachment_optimization_criteria: AttachmentOptimization, openDrawer?: boolean) {
  if (attachment_optimization_criteria !== AttachmentOptimization.PSF_LIMIT) return true;
  if (roof_psf_limit && roof_psf_limit.constructor === Number && roof_psf_limit >= 1 && roof_psf_limit <= 100) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkUpliftAllowable(allowable: number | String, attachment_type: AttachmentType, productId: number, projectVersion, anchor_type, inputUnit: number, openDrawer?: boolean, choice_of_fastend = undefined) {
  const _allowable = Number(allowable);
  if (_allowable) {
    if (attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM &&
      Number(allowable) >= upliftAllowableForFlashloc(productId, inputUnit).min && Number(allowable) <= upliftAllowableForFlashloc(productId, inputUnit).max) {
      return true;
    } else if (attachment_type === AttachmentType.U_ANCHOR_U2400 &&
      Number(allowable) >= upliftAllowableForUAnchor(productId, anchor_type, projectVersion, inputUnit, choice_of_fastend).min && Number(allowable) <= upliftAllowableForUAnchor(productId, anchor_type, projectVersion, inputUnit, choice_of_fastend).max) {
      return true;
    } else if (attachment_type === AttachmentType.CUSTOM &&
      Number(allowable) >= upliftAllowableForCustom(productId, inputUnit).min && Number(allowable) <= upliftAllowableForCustom(productId, inputUnit).max) {
      return true;
    } else if (attachment_type === AttachmentType.OMG &&
      Number(allowable) >= upliftAllowableForOMG(productId, inputUnit).min && Number(allowable) <= upliftAllowableForOMG(productId, inputUnit).max) {
      return true;
    }
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkShearAllowable(allowable: number, attachment_type: AttachmentType, productId: number, projectVersion, anchor_type, inputUnit: number, openDrawer?: boolean, choice_of_fastend = undefined) {
  const _allowable = Number(allowable);
  if (_allowable) {
    if (attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM &&
      Number(allowable) >= shearAllowableForFlashloc(productId, inputUnit).min && Number(allowable) <= shearAllowableForFlashloc(productId, inputUnit).max) {
      return true;
    } else if (attachment_type === AttachmentType.U_ANCHOR_U2400 &&
      Number(allowable) >= shearAllowableForUAnchor(productId, anchor_type, projectVersion, inputUnit, choice_of_fastend).min && Number(allowable) <= shearAllowableForUAnchor(productId, anchor_type, projectVersion, inputUnit, choice_of_fastend).max) {
      return true;
    } else if (attachment_type === AttachmentType.CUSTOM &&
      Number(allowable) >= shearAllowableForCustom(productId, inputUnit).min && Number(allowable) <= shearAllowableForCustom(productId, inputUnit).max) {
      return true;
    } else if (attachment_type === AttachmentType.OMG &&
      Number(allowable) >= shearAllowableForOMG(productId, inputUnit).min && Number(allowable) <= shearAllowableForOMG(productId, inputUnit).max) {
      return true;
    }
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkTotalWeightOnRoofLimit(total_weight_on_roof_limit: number, attachment_optimization_criteria: AttachmentOptimization, openDrawer?: boolean) {
  if (attachment_optimization_criteria !== AttachmentOptimization.TOTAL_WEIGHT_ON_THE_ROOF) return true;
  if (total_weight_on_roof_limit && total_weight_on_roof_limit.constructor === Number && total_weight_on_roof_limit >= 0 && total_weight_on_roof_limit <= 100000000) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkSetbackDistance(setbackDistance: number, openDrawer?: boolean) {
  if (setbackDistance && isNumber(setbackDistance) && setbackDistance > 0 && setbackDistance <= 100) return true;

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export function checkRMSetbackDistance(productId: number, projectEnvConfig: projectEnvConfig, inputUnit: number, openDrawer?: boolean) {
  const { lowerLimit, upperLimit } = getSetbackLimits();
  const { setback_distance, building_height, allow_mechanical_attachments } = projectEnvConfig;
  const seismic_setback_distance = isMetricUnit(inputUnit) ? round(feetsToCms(getSeismicSetback(projectEnvConfig, productId)), 1) : round(getSeismicSetback(projectEnvConfig, productId), 1);
  if (
    setback_distance && isNumber(setback_distance) &&
    setback_distance >= lowerLimit &&
    setback_distance <= upperLimit &&
    (isMetricUnit(inputUnit) ? setback_distance < metersToCms(building_height) / 2 : setback_distance < building_height / 2) &&
    !(setback_distance < seismic_setback_distance && !allow_mechanical_attachments)
  )
    return true;
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export function checkEcofoot2PlusSetbackDistance(productId: number, projectEnvConfig: projectEnvConfig, inputUnit: number, openDrawer?: boolean) {
  const { setback_distance, building_height } = projectEnvConfig;
  const { lowerLimit, upperLimit } = getSetbackLimits();
  const seismic_setback_distance = isMetricUnit(inputUnit) ? round(feetsToCms(getSeismicSetback(projectEnvConfig, productId)), 1) : round(getSeismicSetback(projectEnvConfig, productId), 1);
  if (
    setback_distance && isNumber(setback_distance) &&
    setback_distance >= lowerLimit &&
    setback_distance <= upperLimit &&
    (isMetricUnit(inputUnit) ? setback_distance < metersToCms(building_height) / 2 : setback_distance < building_height / 2) &&
    seismic_setback_distance <= setback_distance
  )
    return true;
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export const checkLongestBuildingLength = (building_length: number, openDrawer?: boolean) => {

  const { lowerLimit, upperLimit } = getLongestBuildingLengthLimits();

  if (building_length >= lowerLimit && building_length <= upperLimit && building_length.constructor === Number) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}


export const checkShortestBuildingLength = (shortest_building_length: number, openDrawer?: boolean,) => {

  const { lowerLimit, upperLimit } = getShortestBuildingLengthLimits();

  if (shortest_building_length >= lowerLimit && shortest_building_length <= upperLimit && shortest_building_length.constructor === Number) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export const checkShortestRowLength = (shortest_row_length: number, openDrawer?: boolean,) => {
  if (shortest_row_length >= shortestRowLength.min && shortest_row_length <= shortestRowLength.max && shortest_row_length.constructor === Number) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
  }
  return false;
}

export const checkLongestRowLength = (longest_row_length: number, openDrawer?: boolean,) => {
  if (longest_row_length >= longestRowLength.min && longest_row_length <= longestRowLength.max && longest_row_length.constructor === Number) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
  }
  return false;
}

export function checkObstruction(obstruction: number | undefined | null, openDrawer) {
  if (obstruction !== 0 && obstruction !== undefined && obstruction !== null) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('obstructions'));
  }

  return false;
}

export function checkBuildingCode(building_code: number) {
  return !isNaN(building_code) && building_code !== null;
}

export function checkWindSpeed(wind_speed: number) {
  return !isNaN(wind_speed) && wind_speed !== null;
}

export function checkTornadoSpeed(tornado_speed: number){
  return !isNaN(tornado_speed) && tornado_speed !== null;
}

export function checkSnow(snow_load: number) {
  return !isNaN(snow_load) && snow_load !== null;
}

export function checkElevation(elevation: number) {
  return !isNaN(elevation);
}

export function checkSeismic(seismic: string | number, openDrawer?: boolean) {
  const { projectConfiguration: { productId, projectEnvConfig: { tilt } } } = state();

  if (((isSMTiltVariations(productId) || isNxtTilt(productId)) && (tilt || tilt === 0)) || isSF(productId)) {
    return true;
  }
  const _seismic = Number(seismic);
  const isValid = seismic != null && !isNaN(_seismic) && _seismic > 0 && _seismic <= 3.1;
  if (!isValid && openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return isValid;
}

export function checkFrontEdgeHeight(frontEdgeHeight: number | string, tilt: number | string, foundationType: number) {
  if (!frontEdgeHeight) return false;
  const val = Number(frontEdgeHeight);
  let { min, max } = frontEdgeHeightField.data[Number(tilt)];
  if (foundationType === FoundationType.DRIVEN) {
    max = MAX_FRONT_EDGE_HEIGHT_FOR_DRIVEN
  }
  return val >= min && val <= max;
}

export function checkFrontEdgeHeightUla(front_edge_height: number) {
  if (front_edge_height >= 1.5 && front_edge_height <= 5) {
    return true;
  }
  return false;
}

export function checkFieldNotEmpty(field: number | string) {
  return !!field || field === 0;
}

export function checkSeismicSs(seismic: string | number) {
  const _seismic = isString(seismic) ? parseFloat(seismic) : seismic;
  return !isNaN(_seismic) && _seismic > 0 && _seismic <= 3.1;
}

export function checkSeismicS1(seismic: string | number, openDrawer?: boolean) {
  const _seismic = isString(seismic) ? parseFloat(seismic) : seismic;
  if (typeof (_seismic) != "undefined" && _seismic != null && !isNaN(_seismic) && _seismic >= 0 && _seismic <= 3.1) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}


export function checkSeismicSds(seismic: string | number, openDrawer ?: boolean) {
  const _seismic = isString(seismic) ? parseFloat(seismic) : seismic;
  if (typeof(_seismic) != "undefined" && _seismic != null && !isNaN(_seismic) && _seismic >= seismicSdsField.min && _seismic <= seismicSdsField.max) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkSeismicSd1(seismic: string | number, openDrawer ?: boolean) {
  const _seismic = isString(seismic) ? parseFloat(seismic) : seismic;
  if (typeof(_seismic) != "undefined" && _seismic != null && !isNaN(_seismic) && _seismic >= seismicSd1Field.min && _seismic <= seismicSd1Field.max) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkLongTransitionPeriods(long_transition_periods: string | number, openDrawer ?: boolean) {
  const _long_transition_periods = isString(long_transition_periods) ? parseFloat(long_transition_periods) : long_transition_periods;
  if (typeof(_long_transition_periods) != "undefined" && _long_transition_periods != null && !isNaN(_long_transition_periods) && _long_transition_periods >= longTransitionPeriodsField.min && _long_transition_periods <= longTransitionPeriodsField.max) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('location-and-loads'));
  }

  return false;
}

export function checkRiskCategory(risk_category: string | number, openDrawer ?: boolean) {
  dispatch(CLEAR_INVALID_FIELD(riskCategory.apiField));
  if(checkFieldNotEmpty(risk_category)) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE(LOCATION_AND_LOADS_PAGE));
  }
  dispatch(SET_FIELD_INVALID(riskCategory.apiField, `Please select a risk category`));
}

function checkWindOnIce(windOnIce: string | number) {
  const _windOnIce = isString(windOnIce) ? parseFloat(windOnIce) : windOnIce;
  return !isNaN(_windOnIce) && _windOnIce >= 0 && _windOnIce <= 80;
}

function checkIceThickness(iceThickness: string | number) {
  const _iceThickness = isString(iceThickness) ? parseFloat(iceThickness) : iceThickness;
  return !isNaN(_iceThickness) && _iceThickness >= 0 && _iceThickness <= 2.5;
}



export function checkBuildingHeight(building_height: number, openDrawer?: boolean) {

  const { lowerLimit, upperLimit } = getBuildingHeightLimits();

  if (building_height.constructor === Number && building_height >= lowerLimit && building_height <= upperLimit) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}


export const checkParapetHeightNumeric = (parapet_height_num: number, openDrawer?: boolean,) => {
  if (parapet_height_num.constructor === Number && parapet_height_num >= parapetHeightNumeric.min && parapet_height_num <= parapetHeightNumeric.max) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export const checkRailLengthForThermalGap = (rail_length_for_thermal_gap: number, allow_thermal_gap: boolean, openDrawer?: boolean,) => {
  if (allow_thermal_gap) {
    if (rail_length_for_thermal_gap && rail_length_for_thermal_gap.constructor === Number) {
      return true;
    }
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE('rail-system'));
    }
    return false;
  }
  else return true;
}


export function checkLongestAndShortestBuildingLength(longest_building_length: number, shortest_building_length: number, openDrawer?: boolean) {

  if (longest_building_length >= shortest_building_length) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function getWindSpeedLimits(): { lowerLimit: number, upperLimit: number } {
  const { projectConfiguration: { productId, projectEnvConfig: { building_code }, inputUnit } } = state();
  let lowerLimit = 85;
  let upperLimit = 180;
  if (isAscender(productId) || isMetricUnit(inputUnit)) {
    upperLimit = 266;
    lowerLimit = 136;
  }
  else if (isSolarMount(productId) || isNxtHorizon(productId) || isSFMFamily(productId) || isMetalX(productId) || isSMTiltPR(productId)) {
    upperLimit = isASCE705(building_code) ? 170 : 190;
    lowerLimit = isASCE710(building_code) ? 95 : 85;
  }
  else if (isNxtHorizon(productId)) {
    upperLimit = 190;
    lowerLimit = 95;
  }
  else if (isSMTilt(productId)) {
    upperLimit = 150;
  }
  else if (isGroundProduct(productId)) {
    upperLimit = 165;
  }

  return {
    lowerLimit,
    upperLimit
  }

}

export const getSnowLoadLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { productId, inputUnit }, user: { isPrivilegedUser } } = state();
  const lowerLimit = 0;
  let upperLimit = 60;

  if (isAscender(productId) || isMetricUnit(inputUnit)) {
    upperLimit = round(psfToKpa(60), 2);
    if ((isRM5(productId) || isRM10orRM10Evo(productId)) && isPrivilegedUser) {
      upperLimit = round(psfToKpa(100), 2);
    }
  }
  else if (isSolarMount(productId) || isSMTiltPR(productId) || isNxtHorizon(productId) || isSFMFamily(productId) || isMetalX(productId) || isRM5(productId) || isRM10(productId) || isEcoFoot2Plus(productId) || ((isRMIFIProduct(productId) || isRmGridflex10(productId) || isRM10Evolution(productId)) && isPrivilegedUser)) {
    upperLimit = 100;
  }

  return {
    lowerLimit,
    upperLimit
  }

}

export const getSetbackLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { inputUnit } } = state();
  let lowerLimit = 1;
  let upperLimit = 100;

  if (isMetricUnit(inputUnit)) {
    lowerLimit = 30;
    upperLimit = 999;
  }

  return {
    lowerLimit,
    upperLimit
  }

}

export function checkClampsChoice(clampsChoice: string | number, openDrawer?: boolean) {
  const _clampsChoice = isString(clampsChoice) ? parseFloat(clampsChoice) : clampsChoice;
  const isOk = !isNaN(_clampsChoice) && _clampsChoice in [1, 2, 3, 4];

  if (!isOk && openDrawer) {
    dispatch(OPEN_DRAWER_PAGE(RAIL_SYSTEM_PAGE));
  }

  return isOk;
}

export const getShortestBuildingLengthLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { productId, inputUnit } } = state();
  let apiField = shortestBuildingLengthField(inputUnit, productId);

  if (isAscender(productId)) apiField = shortestBuildingLengthMetric;


  return { lowerLimit: apiField.min, upperLimit: apiField.max }
}

export const getLongestBuildingLengthLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { productId, inputUnit } } = state();
  let apiField = longestBuildingLengthField(inputUnit, productId);
  if (isAscender(productId)) apiField = longestBuildingLengthMetric;

  return { lowerLimit: apiField.min, upperLimit: apiField.max }

}

export const getBuildingHeightLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { productId, inputUnit } } = state();
  let lowerLimit = 15;
  let upperLimit = 60;

  if (isAscender(productId)) {
    lowerLimit = 1;
    upperLimit = 18;
  }
  else if (isMetricUnit(inputUnit)) {
    lowerLimit = 4.6;
    upperLimit = 45.7;
    if (isEcoFoot2Plus(productId)) {
      upperLimit = round(feetsToMeters(100), 1);
    } else if (isRM10orRM10Evo(productId) || isRM5(productId)|| isRmGridflex10(productId)) {
      upperLimit = round(feetsToMeters(150), 1);
    }

  }
  else if (isSMTiltPR(productId)) {
    lowerLimit = 1;
  }
  else if (isEcoFoot2Plus(productId) || isRMDT(productId)) {
    upperLimit = 100;
  }
  else if (isRM10orRM10Evo(productId) || isRMIFIProduct(productId) || isRM5(productId) || isRmGridflex10(productId)) {
    upperLimit = 150;
  }

  return {
    lowerLimit,
    upperLimit
  }
}

export const getRafterSpacingLimits = (): { lowerLimit: number, upperLimit: number } => {
  const { projectConfiguration: { productId } } = state();
  let lowerLimit = 1;
  let upperLimit = 144;

  if (isAscender(productId) || isSMFlushMetric()) {
    lowerLimit = 2;
    upperLimit = 366;
  }

  return {
    lowerLimit,
    upperLimit
  }

}

const checkAttacmentsAreRequired = (building_code: number, productId: number, openDrawer: boolean) => {
  const { projectConfiguration: { projectVersion } } = state();
  const checkForRmMechanicalAttachments = roofSlopeWarningCondition() || buildingStoreyWarningCondition() || buildingHeightGreaterThan72WarningCondition() || attachmentWarningConditionForRiskCategoryIV();

  if ((isRM10orRM10Evo(productId) || applyRM5Revamp(projectVersion) || isRMGridFlex(productId)) && isASCE716or722(building_code) && checkForRmMechanicalAttachments){
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE('rail-system'));
    }
    return false;
  }
  else return true;
}


export const checkRafterSpacing = (rafter_spacing: number, openDrawer?: boolean,) => {
  const { projectConfiguration: { inputUnit } } = state();
  let rafterSpacingField = rafterSpacing;
  if (isMetricUnit(inputUnit)) rafterSpacingField = rafterSpacingMetric;
  let rafterSpacingVal = rafter_spacing * 1;
  if (rafterSpacingVal.constructor === Number && rafterSpacingVal >= rafterSpacingField.min && rafterSpacingVal <= rafterSpacingField.max) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export const checkPreferredSpan = (preferred_span: number, openDrawer?: boolean,) => {
  const { projectConfiguration: { inputUnit } } = state();
  let preferredSpanField = span;
  if (isMetricUnit(inputUnit)) preferredSpanField = preferredSpanMetric;
  if (preferred_span.constructor === Number && preferred_span >= preferredSpanField.min && preferred_span <= preferredSpanField.max) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

export function checkAdditionalUserInputs(projectEnvConfig, openDrawer?: boolean) {
  const { velocity_pressure_exposure_coefficient_kz, ground_elevation_factor_ke, wind_directionality_factor_kd, topographical_factor_kzt, roof_exposure, soil_class, numberical_coefficient, design_life_factor_fc } = projectEnvConfig;
  const fields = [velocity_pressure_exposure_coefficient_kz, ground_elevation_factor_ke, wind_directionality_factor_kd, topographical_factor_kzt, roof_exposure, soil_class, numberical_coefficient, design_life_factor_fc];
  const isOk = fields.every(field => {
    return field !== null && field !== undefined && field !== '';
  });
  if (isOk) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('additional-user-inputs'));
  }

  return false;
}


export function checkWindDeflectors(deflectors: string | number, openDrawer?: boolean) {
  if (!!deflectors && (Number(deflectors) >= 1 && Number(deflectors) <= 4)) {
    return true;
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

function checkSpanAndRafterSpacing(preferred_span: string | number, rafter_spacing_inches: string | number, openDrawer?: boolean) {
  if (!!preferred_span && (!!rafter_spacing_inches && Number(rafter_spacing_inches) >= rafterSpacing.min && Number(rafter_spacing_inches) <= rafterSpacing.max)
    && (isSpanMultipleOfRafterSpacing(preferred_span, rafter_spacing_inches))) {
    return true;
  };
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}

function checkMetalXSpans(preferred_span: string | number, openDrawer?: boolean) {
  if (!!preferred_span && (Number(preferred_span) >= 12 && Number(preferred_span) <= 72)) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function isZipcodeValid(openDrawer?: boolean) {
  const { projectConfiguration: { projectEnvConfig: { zipcode } } } = state();
  if (zipcode === "" || zipcode === null) {
    if (openDrawer) {
      dispatch(OPEN_DRAWER_PAGE('location-and-loads'));

    }
    return false;
  }
  return true;
}

export function checkRoofTypeMissing(roof_type: string | number | undefined | null, openDrawer?: boolean) {

  if (roof_type !== undefined && roof_type !== null) {
    return true;
  }

  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }

  return false;
}

export function checkRoofSubstrate(attachment_type, roof_type, roof_substrate, openDrawer?: boolean){
  if (roof_type === RoofType.SHINGLE && [AttachmentType.FLASHLOC_DUO, AttachmentType.STRONGHOLD_ATT_BUTYL, AttachmentType.SFM_BUTYL_DTD, AttachmentType.SM_BUTYL_DTD].includes(attachment_type)){
    if (roof_substrate === null || roof_substrate === undefined){
      return true;
    }
  }
  if (openDrawer) {
    dispatch(OPEN_DRAWER_PAGE('rail-system'));
  }
  return false;
}



