import React from 'react';

type Props = {
  totalPrice: number,
};

export default class TotalListPrice extends React.Component<Props> {
  render() {
    const { totalPrice } = this.props;
    return (
    <div className="total-table-price">
    <div>
      TOTAL PRICE
    </div>
    <div>
      (USD)
    </div>
    <div className="total-price">
      {(totalPrice).toFixed(2)}
    </div>
  </div>);
  }
}
