import { state } from '__common/store';
import L from 'leaflet';
import { polygonOptions } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerConfigs';

export const emptyRoofColor = '#F26522';

export const fullRoofColor = '#0083C2';

export default (coords: { lat: number, lng: number }[], roofId: number): bingRoofOnMap => {
  const { drawingManager: { roofs } } = state();
  let newRoofId;

  if (roofId) {
    newRoofId = roofId;
  } else {
    if (roofs && Object.keys(roofs).length) {
      newRoofId = Number(Object.keys(roofs)[Object.keys(roofs).length - 1]) + 1;
    } else {
      newRoofId = 1;
    }
  }

  const roofColor = roofId && roofs[roofId] && roofs[roofId].panels && roofs[roofId].panels.length ? fullRoofColor : emptyRoofColor;
  const id = roofId || newRoofId;
  const options = {
    color: roofColor,
    noClip: true,
    fillColor: roofColor,
    fillOpacity: polygonOptions.fillOpacity,
  };

  const shape = {
    overlay: L.polygon(coords, options),
    type: 'Polygon',
    id,
  };
  shape.overlay.id = id;
  return shape;
};
