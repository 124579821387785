import { getStage } from '__editor/panelsEditor/components/stage/stage';
import store, { dispatch, state } from '__common/store';
import { getNearestPanelsIds } from '__common/components/exposure/exposureHelper';
import { DEBUG } from 'debug';
import { isAscender, isGFT, isRM10Evolution, isRM5, isRMDT } from '__common/constants/products';
import { ICURSOR_STICK_POINT } from '../cursor/utils/snapToGridStickyPoints';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, REMOVE_PANELS, SET_GONE_THROUGH_THE_PANEL_EDITOR, SET_USER_CLICKED_YES_OR_NO } from 'actions';

export function _drawStickyPoints(stickyPoints: ICURSOR_STICK_POINT[]) {
  if (!DEBUG.showPanelsStickyPoints) {
    return;
  }

  const stage = getStage();
  const stickyPointsContainer = new PIXI.Container();
  stickyPoints.map((point) => {
    const stickPoint = new PIXI.Graphics();
    stickPoint.beginFill(0x000000, 0.5);
    stickPoint.drawCircle(point.x, point.y, 0.3);
    stickPoint.endFill();

    if (DEBUG.showPanelsStickyPointsLabels) {
      const stickPointLabel = new PIXI.Text(point.corner.toString(), {
        fontSize: '18px',
        fill: 'red',
      });
  
      const xOffset = 10 / stage.scale.x;
      const yOffset = 5 / stage.scale.y;
      stickPointLabel.x = point.x + xOffset;
      stickPointLabel.y = point.y + yOffset;
      stickPointLabel.width /= stage.scale.x;
      stickPointLabel.height /= stage.scale.y;
      stickPointLabel.zIndex = 1000;
      stickPointLabel.id = 'stick-point-label';
  
      stickyPointsContainer.addChild(stickPointLabel);
    }
    stickyPointsContainer.addChild(stickPoint);
  });

  stage.addChild(stickyPointsContainer);

  return stickyPointsContainer;
}

export function _drawRestrictedArea(rTreeBounds: rTreeBounds, panel: PIXI.Graphics) {
  // uncomment for debug restricted area
  if (!DEBUG.showPanelsRestrictedArea) {
    return;
  }

  const { settings: { canvasCenter } } = state();
  const restrictedArea = new PIXI.Graphics();
  restrictedArea.beginFill(0x000000, 0.5);
  restrictedArea.lineStyle(0x000000);
  restrictedArea.drawRect(
    rTreeBounds.minX + canvasCenter.x,
    rTreeBounds.minY + canvasCenter.y,
    rTreeBounds.maxX - rTreeBounds.minX,
    rTreeBounds.maxY - rTreeBounds.minY,
  );
  restrictedArea.endFill();
  getStage().addChild(restrictedArea);
}

export const getPanelSize = () => {
  const { background: { metersPerPixel }, moduleSelector: { modelData: { height, width } },
  projectConfiguration: { productId, inputUnit }, } = state();
  let module_height, module_width;
  
  if(isAscender(productId) || isMetricUnit(inputUnit)) {
    module_width = cmsToMeters(Number(width)) / metersPerPixel;
    module_height =cmsToMeters(Number(height)) / metersPerPixel;    
  }
  else {
    module_width = inchesToMeters(Number(width)) / metersPerPixel;
    module_height = inchesToMeters(Number(height)) / metersPerPixel;
  }


  return {
    width: module_width,
    height: module_height,
  };
};

export function getStoredPanelBounds(panel: panelInState) {
  const x = panel.x - panel.width / 2;
  const y = panel.y - panel.height / 2;
  return {
    minX: x,
    minY: y,
    maxX: x + panel.width,
    maxY: y + panel.height,
    id: panel.id,
    siblingId: panel.siblingId,
  };
}

export const CheckingImportedRoofStatus = () =>{
  const {drawingManager:{roofs}, projectConfiguration:{gone_through_panel_editor}} = state();
    const allRoofsGoneThroughPanelEditor = Object.values(roofs).every(roof=>roof.seen === true);
    if(allRoofsGoneThroughPanelEditor && !gone_through_panel_editor){
      dispatch(SET_GONE_THROUGH_THE_PANEL_EDITOR(true));
    }  
}

export function hasTilt() {
  const { projectConfiguration: { projectEnvConfig: { tilt } } } = state();
  return typeof tilt !== 'undefined' && tilt !== null && tilt.constructor === Number && tilt > -1;
}

export const getNearstPanelsInState = (panel: panelInState) => {
  const { background: { bgXY: { x, y } } } = state();
  return getNearestPanelsIds(panel, x, y);
};

export function shouldUseDoublePanels(productId: number): boolean {
  const { projectConfiguration:{ projectEnvConfig:{structure_type}} } = state();
  
  return isGFT(productId) || isRMDT(productId) || (isAscender(productId) && !(structure_type == 1 || structure_type == 2)) ;
}


export const createPanelArrayGrid = (panels: panelInState[], panelBayMapping: panelBayMapping= new Map()) => {
  const EMPTY_CELL = 0;
  const {
    projectConfiguration: {productId},
    settings: {
      rowSpacing, 
      columnSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = store.getState();

  const panelHeight = panels[0].height;
  const panelWidth = panels[0].width;
    
  let moduleWidthMeters = panelWidth * metersPerPixel;
  if(isRMDT(productId)) {
    moduleWidthMeters *= 2;
  }
  const moduleLengthMeters = panelHeight * metersPerPixel;
  const xMargin = 0.001 + columnSpacing + 0.05;
  const yMargin = 0.001 + rowSpacing + 0.05;
  const xValues = panels.map((panel) => panel.x * metersPerPixel);
  const yValues = panels.map((panel) => panel.y * metersPerPixel);

  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  const dxRaw = (xMax - xMin) / (moduleWidthMeters  + columnSpacing);
  const dyRaw = (yMax - yMin) / (moduleLengthMeters + rowSpacing);

  let dx = Number(Math.round(dxRaw)) + 1;

  let dy = Number(Math.round(dyRaw)) + 1;

  const grid = [];
  const toggleAttachmentsGrid = [];
  let panelConfig;

  for (let i = 0; i < dy; i++) {
      grid.push([]);
      toggleAttachmentsGrid.push([]);
      for (let j = 0; j < dx; j++) {
          grid[i].push(EMPTY_CELL);
          toggleAttachmentsGrid[i].push(EMPTY_CELL);
      }
  }
  panels.forEach(panel => {
      const { x, y } = panel;
      const column = Math.abs(
      Number((
          (xMin - x * metersPerPixel) / (moduleWidthMeters  + columnSpacing)
      ).toFixed(4)),
      );

      const row = Math.abs(
      Number((
          (yMin - y * metersPerPixel) / (moduleLengthMeters + rowSpacing)
      ).toFixed(4)),
      );
      dx = Math.abs(column - Number(column.toFixed(1)));
      dy = Math.abs(row - Number(row.toFixed(1)));

      if (dx < xMargin && dy < yMargin) {
          const columnIndex = Number(Math.round(column));
          const rowIndex = Number(Math.round(row));
            grid[rowIndex][columnIndex] = 1;
            if(panel.attached) {
                toggleAttachmentsGrid[rowIndex][columnIndex] = 1;
            }

            if(isRM10Evolution(productId) || isRM5(productId)){
              let manualAttachments
              if(panelBayMapping.has(panel.id)){
                manualAttachments = panelBayMapping.get(panel.id).map(bay=> bay.attached)
              } else{
                manualAttachments = panel.manualAttachments
              }
              toggleAttachmentsGrid[rowIndex][columnIndex] = manualAttachments
            }
            if(!panelConfig && panel.panelConfig) {
                panelConfig = panel.panelConfig;
            }
      }
  });
  return {grid, toggleAttachmentsGrid, panelConfig};
};

export const openConfirmationModalForOverflowingModules = (totalPanels, panelIdsToRemove, selectedRoofId) => {
  setTimeout(() => {
    dispatch(OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
      `${totalPanels} modules overflow the roof!`,
      `Delete all ${totalPanels} overflowing modules?`,
      () => {
        dispatch(REMOVE_PANELS(panelIdsToRemove));
        dispatch(SET_USER_CLICKED_YES_OR_NO(selectedRoofId,true));
      },
      () => {
        dispatch(SET_USER_CLICKED_YES_OR_NO(selectedRoofId, false));
      },
      null,
      null,
      'YES, DELETE OVERFLOWING PANELS',
      'NO, WILL DELETE THEM MYSELF',
      {dialogStyle: { opacity: 0.7 }},
      true
    ))
  }, 800);
}
