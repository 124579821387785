export const CUSTOM_MANUFACTURING_ID = 185;
export const CUSTOM_MODEL_ID = 1000001;
export const SM_TILT_MODULE_LENGTH_ORIENTATION_LIMIT = 84.42;  //Inches
export const WARNING_MODULE_THICKNESSES = [37, 42, 43, 44, 48, 49]

export const _length_range = {
rm_gridflex: {
    '0.0.1': {
    minLength: 60,
    maxLength: 100,
    }
},
rm_gridflex_10: {
    '0.0.1': {
    minLength: 65,
    maxLength: 100,
    }
},
gft: {
    '0.0.49': {
    minLength: 50,
    maxLength: 97,
    },
    '0.0.1': {
    minLength: 50,
    maxLength: 110,
    }
},
ula: {
    '0.0.1': {
    minLength: 50,
    maxLength: 110,
    }
},
ecofoot2_plus: {
    '0.0.44': {
    minLength: 60,
    maxLength: 90, 
    },
    '0.0.27': {
    minLength: 60,
    maxLength: undefined,
    },
    '0.0.2': {
    minLength: 0,
    maxLength: 98,
    },
    '0.0.1': {
    minLength: 20,
    maxLength: 84,
    }
},
rm_10_evolution: {
    '0.0.27': {
    minLength: 60,
    maxLength: undefined,
    },
    '0.0.2': {
    minLength: undefined,
    maxLength: undefined,
    },
    '0.0.1': {
    minLength: 20,
    maxLength: 120,
    }
},
rm: {
    '0.0.27': {
    minLength: 60,
    maxLength: undefined,
    },
    '0.0.2': {
    minLength: undefined,
    maxLength: undefined,
    },
    '0.0.1': {
    minLength: 20,
    maxLength: 120,
    }
},
rm_5: {
    '0.0.27': {
    minLength: 60,
    maxLength: undefined,
    },
    '0.0.3': {
    minLength: 0,
    maxLength: 98,
    },
    '0.0.1': {
    minLength: 20,
    maxLength: 120,
    }
},
rm_dt: {
    '0.0.27': {
    minLength: 60,
    maxLength: undefined,
    },
    '0.0.3': {
    minLength: undefined,
    maxLength: undefined,
    },
    '0.0.1': {
    minLength: 20,
    maxLength: 120,
    }
},
sm_tilt_pr: {
    '0.0.1': {
        minLength: 10,
        maxLength: 100,
    }
},
ascender: {
    '0.0.1': {
    minLength: 195.5,
    maxLength: 238.5,
    }
},
other: {
    '0.0.1': {
    minLength: 20,
    maxLength: 120,
    }
}
};

export const _width_range = {
rm_5: {
    '0.0.27': {
    minWidth: 37,
    maxWidth: undefined,
    },
    '0.0.3': {
    minWidth: undefined,
    maxWidth: undefined,
    },
    '0.0.1': {
    minWidth: 32.3,
    maxWidth: 48.3,
    }
},
rm: {
    '0.0.27': {
    minWidth: 37,
    maxWidth: undefined,
    },
    '0.0.2': {
    minWidth: undefined,
    maxWidth: undefined,
    },
    '0.0.1': {
    minWidth: 36.7,
    maxWidth: 44.7,
    maxWidthStaff: 46.2,
    }
},
ecofoot2_plus: {
    '0.0.27': {
    minWidth: 37,
    maxWidth: undefined,
    },
    '0.0.2': {
    minWidth: undefined,
    maxWidth: undefined,
    },
    '0.0.1': {
    minWidth: 10,
    maxWidth: 100,
    }
},
rm_dt: {
    '0.0.27': {
    minWidth: 37,
    maxWidth: undefined,
    },
    '0.0.3': {
    minWidth: undefined,
    maxWidth: undefined,
    },
    '0.0.1': {
    minWidth: 35.2,
    maxWidth: 45.1,
    }
},
rm_10_evolution: {
    '0.0.27': {
    minWidth: 37,
    maxWidth: undefined,
    },
    '0.0.2': {
    minWidth: undefined,
    maxWidth: undefined,
    },
    '0.0.1': {
    minWidth: 10,
    maxWidth: 100,
    }
},
rm_gridflex: {
    '0.0.1': {
    minWidth: 38,
    maxWidth: 44.65,
    }
},
rm_gridflex_10: {
    '0.0.1': {
    minWidth: 39,
    maxWidth: 52,
    }
},
ascender: {
    '0.0.1': {
    minWidth: 99.0,
    maxWidth: 130.4,
    }
},
sm_tilt_pr: {
    '0.0.1': {
        minWidth: 10,
        maxWidth: 84.42,
    }
},
other: {
    '0.0.1': {
    minWidth: 10,
    maxWidth: 100,
    }
},
};


export const _version_new_area_map = {
    '0.0.3': ['rm_5','rm_dt'],
    '0.0.2': ['ecofoot2_plus','rm','rm_10_evolution', 'rm_gridflex_10'],
}

export const _module_area_map = {
    old : {
        minArea: 17,
        maxArea: 32
    },
    new : {
        minArea: 17,
        maxArea: 34,
    }
}

export const _module_area_map_metric = {
    old : {
        minArea: 1.58,
        maxArea: 2.97
    },
    new : {
        minArea: 1.58,
        maxArea: 3.16,
    }
}

export const RESTRICTED_MODEL_IDS = [1004006, 1004058, 1004059, 1004062, 1004063];

