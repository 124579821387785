import _ from 'lodash';
import polygonsIntersect from 'polygons-intersect';
import { RoofZonesCorner, getVirtualRoofZonesAreas, getRoofZonesAreasMemoized as getRoofZonesAreas} from '../roofZones';
import { getWindZoneDistance } from '__editor/panelsEditor/components/roofZones/utils/windZoneDistance';
import { roofZonesExist, createEdgeZonePolygon, getMinXofPolygon, getMinYofPolygon, RectCircleColliding } from '../roofZonesCollisionsHelper';
import { panelPolygon } from '../../roofEdges/roofEdgesCollisions';
import { state } from '__common/store';
import { isAscender, isCommercialProduct, isResidentialProduct } from '__common/constants/products';
import { collisionCircle, roofZonesClassification } from './roofZonesClassifications';
import { shouldUpgradeResidentalASCE716Zone } from './residentialsZoneExceptions';
import { getStage } from '../../stage/stage';
import { isFlatRoof } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { getFlatRoofZoneCollision } from './flatRoofZonesCollisionsDetection';
import { getGableAndHipRoofZoneCollision } from './gableAndHipRoofZonesCollisionsDetection';
import { EDGES_TYPE } from "./edgesType";
import { DEBUG } from 'debug';
import { isBlankMap } from '__common/constants/map';
import { getIndexFromCollision, getEdgeTypeFromCollision } from './collisions';
import { getCornerArea } from '__editor/panelsEditor/components/roofZones/utils/getCornerArea';
import { getCornerCollision } from '__editor/panelsEditor/components/roofZones/utils/collisions';
import moize from 'moize';


export interface IAREA_WITH_INDEX {
  coords: { x: number, y: number }[];
  index: number;
  zone: string;
  edge: number;
}

interface CHECK_COLLISION_WITH_WIND_ZONES {
  panel: { x: number, y: number, width: number, height: number };
  relativeToCenter: boolean;
  roofEdges: google.maps.LatLngLiteral[];
  roofCenter: cordPoint;
  zoom: number;
  buildingHeightFt: number;
  metersPerPixel: number;
  bgRotationDegrees: number;
  bgXOffset: number;
  bgYOffset: number;
  panelWidth: number;
  panelHeight: number;
  insideOfPolygon: boolean;
  buildingType: number;
  roofPitch: string;
  distancePx?: number;
  roofId: number;
}

export const DEFAULT_CLASSIC_DESIGNER_ZONE = (productId: number) => isResidentialProduct(productId) ? 1 : 2;

export const CHECK_POINT_DISTANCE = 50;

const getRestrictedAreaPolygon = (rotatedRoofZone) =>  rotatedRoofZone.edges.map(createEdgeZonePolygon);

const getRestrictedAreaPolygonMemoized = getRestrictedAreaPolygon;

export const checkCollisionWithWindZones = ({
  panel,
  relativeToCenter,
  roofEdges,
  roofCenter,
  zoom,
  buildingHeightFt,
  metersPerPixel,
  bgRotationDegrees,
  bgXOffset,
  bgYOffset,
  panelWidth,
  panelHeight,
  insideOfPolygon,
  buildingType,
  roofPitch,
  distancePx,
  roofId,
}: CHECK_COLLISION_WITH_WIND_ZONES): { zone: roofZoneNumber, edgeType?: number } => {
  const { roofsSelector: { mapType }, projectConfiguration: { productId, projectEnvConfig: {tilt} }, background: {roofEdgesPixiCords} } = state();

  const defaultZone = DEFAULT_CLASSIC_DESIGNER_ZONE(productId);

  if (isBlankMap(mapType)) {
    return { zone: defaultZone };
  }

  if (buildingHeightFt === undefined) {
    return { zone: defaultZone };
  }

  const distance = distancePx || getWindZoneDistance(metersPerPixel, productId);

  const rotatedRoofZone = getRoofZonesAreas(
    {roofEdges, roofCenter,  roofEdgesPixiCords, roofPitch, roofId: roofId, distance, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});
  
  if (!roofZonesExist(rotatedRoofZone)) {
    return { zone: defaultZone };
  }

  clearResidentalZonesHelper();


  const panelPoly = panelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panelWidth, panelHeight);

  const restrictedAreaPolygons = getRestrictedAreaPolygonMemoized(rotatedRoofZone);

  if (isCommercialProduct(productId)) {
    let collisionsSet: string = restrictedAreaPolygons.reduce((acc, area) => findEdgesCollisions(acc, area, panelPoly), '');
    collisionsSet = rotatedRoofZone.corners.reduce((acc, corner: collisionCircle) => findEdgesCircleCollision(acc, corner, distance, panelPoly, panel), collisionsSet);
    return { zone: roofZonesClassification(collisionsSet) };
  }

  if (isResidentialProduct(productId)) {
    if (isFlatRoof(buildingType)) {
      return getFlatRoofZoneCollision({
        panelPoly,
        roofEdges,
        roofCenter,
        zoom,
        buildingHeightFt,
        metersPerPixel,
        bgRotationDegrees,
        bgXOffset,
        bgYOffset,
        roofPitch,
        insideOfPolygon,
        roofId,
        roofEdgesPixiCords,
        productId,
        tilt,
        mapType,
      });
    }

    let roofZone =  getGableAndHipRoofZoneCollision({
      restrictedAreaPolygons,
      rotatedRoofZone,
      roofEdges,
      bgRotationDegrees,
      bgXOffset,
      bgYOffset,
      buildingType,
      roofCenter,
      distance,
      panelPoly,
      roofPitch,
      zoom,
    });
    
    if(isAscender(productId)) {
      if(roofZone.zone == 1) {
        const rotatedRoofZone2 =  getRoofZonesAreas(
          {roofEdges, roofCenter,  roofEdgesPixiCords, roofPitch, roofId: roofId,distance: distance * 5/2, zoom, rotationDegrees: bgRotationDegrees, bgOffSet: {x: bgXOffset, y: bgYOffset}, insideOfPolygon, productId, tilt, mapType,});

        const restrictedAreaPolygons2 = rotatedRoofZone2.edges.map(createEdgeZonePolygon);
        const roofZone2 = getGableAndHipRoofZoneCollision({
          restrictedAreaPolygons: restrictedAreaPolygons2,
          rotatedRoofZone: rotatedRoofZone2,
          roofEdges,
          bgRotationDegrees,
          bgXOffset,
          bgYOffset,
          buildingType,
          roofCenter,
          distance: distance*5/2,
          panelPoly,
          roofPitch,
          zoom,
        });
        if(roofZone2.zone == 1) {
          roofZone =  {zone: 4, edgeType: 3}
        }
      }
    }
    return roofZone;
  }
  return { zone: defaultZone };
};

export const checkCollisionWithVirtualWindZones = ({
  panel,
  relativeToCenter,
  metersPerPixel,
  bgXOffset,
  bgYOffset,
  insideOfPolygon,
}: { 
  panel: panelInState,
  relativeToCenter: boolean,
  metersPerPixel: number,
  bgXOffset: number,
  bgYOffset: number,
  insideOfPolygon: boolean,
}): { zone: roofZoneNumber, edgeType?: number } => {
  const { projectConfiguration: { productId }, background: { roofEdgesPixiCords } } = state();

  const distance = getWindZoneDistance(metersPerPixel, productId);
  const rotatedRoofZone = getVirtualRoofZonesAreas(distance, roofEdgesPixiCords, insideOfPolygon);
  const panelPoly = panelPolygon(panel, relativeToCenter, bgXOffset, bgYOffset, panel.width, panel.height);
  const restrictedAreaPolygons = getRestrictedAreaPolygonMemoized(rotatedRoofZone);

  if (isCommercialProduct(productId)) {
    let collisionsSet: string = restrictedAreaPolygons.reduce((acc, area) => findEdgesCollisions(acc, area, panelPoly), '');
    collisionsSet = rotatedRoofZone.corners.reduce((acc, corner: collisionCircle) => findEdgesCircleCollision(acc, corner, distance, panelPoly, panel), collisionsSet);
    return { zone: roofZonesClassification(collisionsSet) };
  }

  const defaultZone = DEFAULT_CLASSIC_DESIGNER_ZONE(productId);

  return { zone: defaultZone };
};

const findEdgesCollisions = (collisions: string, area: PolygonInterface, panelPoly: PolygonInterface) => {
  const intersect = polygonsIntersect(area.points, panelPoly.points);
  let currentCounter = collisions;
  if (intersect.length && !currentCounter.includes(area.zone)) {
    currentCounter += area.zone;
  }
  return currentCounter;
};

export const findLinesCollisions = (collisions: any[], area: PolygonInterface, panelPoly: PolygonInterface) => {
  const intersect = polygonsIntersect(area.points, panelPoly.points);
  if (intersect.length) {
    const areaWithIndex = {
      coords: area.points,
      index: area.index,
      zone: area.zone,
      edge: area.edge,
    };

    collisions.push(areaWithIndex);
  }

  return collisions;
};

const findEdgesCircleCollision = (collisions: string, corner: collisionCircle, distance: number, panelPoly: PolygonInterface, panel: { x: number, y: number, width: number, height: number }) => {
  const minX = getMinXofPolygon(panelPoly);
  const minY = getMinYofPolygon(panelPoly);
  let currentCounter = collisions;
  const circle = { x: corner.pos.x, y: corner.pos.y, r: distance };
  const rect = { x: minX, y: minY, w: panel.width, h: panel.height };
  const colliding = RectCircleColliding(circle, rect);

  if (colliding && !currentCounter.includes(corner.zone)) {
    currentCounter += corner.zone;
  }

  return currentCounter;
};

export const findCornerCollisionIds = (
  collisions: string[], 
  corner: RoofZonesCorner, 
  panelPoly: PolygonInterface,
): string[] => {
  const cornerArea = getCornerArea(corner);
  const cornerAreaPoints = (
    cornerArea.length > 0 ? 
    cornerArea : []
  );
  const intersection = polygonsIntersect(panelPoly.points, cornerAreaPoints);
  const colliding = intersection.length > 0;
  if (colliding && !collisions.includes(`${corner.index}c`)) {
    collisions.push(getCornerCollision(corner.index, corner.edge));
  }

  return collisions;
};

export const checkZoneAngles = (collisions: (IAREA_WITH_INDEX|PolygonInterface)[], collisionIndexes: string[], roofEdges: PolygonInterface, roofZoneDistance: number) => {
  return collisionIndexes.some((collisionIndex: string) => {
    const index = getIndexFromCollision(collisionIndex);
    const collision = getCurrentCollision(collisions, index);

    if (!collision) {
      return false;
    }
    const currentIndex = collision.index;

    const BLACK = 0x000000;
    const RED = 0xFF0000;
    const WHITE = 0xFFFFFF;

    const nextCollision = getNextCollision(collisions, currentIndex);

    if (nextCollision) {
      const { p0, p1, p2 } = getCollisionPoints(collision, nextCollision);

      drawResidentialZonesHelper(p0, BLACK, 'currentPoint');
      drawResidentialZonesHelper(p1, RED, 'sharedPoint');
      drawResidentialZonesHelper(p2, WHITE, 'nextPoint');

      const shouldUpdate = shouldUpgradeResidentalASCE716Zone(
        p0.x, 
        p0.y, 
        p1.x, 
        p1.y, 
        p2.x, 
        p2.y, 
        CHECK_POINT_DISTANCE, 
        roofEdges, 
        roofZoneDistance,
      );
      
      if (shouldUpdate) {
        drawResidentialZonesHelper(p0, BLACK, 'prevPoint');
        drawResidentialZonesHelper(p1, RED, 'sharedPoint');
        drawResidentialZonesHelper(p2, WHITE, 'currentPoint');
      }

      return shouldUpdate;
    }

    return false;
  });
};

const getCurrentCollision = (collisions:  (IAREA_WITH_INDEX|PolygonInterface)[], currentIndex: number) => {
  return collisions.find((col) => col.index === currentIndex);
};
const getNextCollision = (collisions: (IAREA_WITH_INDEX|PolygonInterface)[], currentIndex: number) => {
  const index = currentIndex === collisions.length - 1 ? 0 : currentIndex + 1;
  return collisions.find((col) => col.index === index);
};

const getCollisionPoints = (firstCollision, secondCollision) => {
  const firstArray = [firstCollision.points[0], firstCollision.points[1]];
  const secondArray = [secondCollision.points[0], secondCollision.points[1]];


  const middlePoint = firstArray.filter(o => secondArray.some(({ x, y }) => o.x === x && o.y === y));
  const edgePointsOne = _.differenceWith(firstArray, secondArray, _.isEqual);
  const edgePointsTwo = _.differenceWith(secondArray, firstArray, _.isEqual);

  return {
    p0: edgePointsOne[0],
    p1: middlePoint[0],
    p2: edgePointsTwo[0],
  };
};

const clearResidentalZonesHelper = () => {
  // turn off when debuging

  if (!DEBUG.showFlatASCE716HelperPoints) {
    return true;
  }

  const stage = getStage();
  stage.children = stage.children.filter((child: any) => {
    return  !['prevPoint', 'sharedPoint', 'nextPoint', 'currentPoint','middlePoint',  'roof'].includes(child.id);
  });
};

export const drawResidentialZonesHelper = ({ x, y }: { x: number, y: number }, color: number, name: string, zIndex = 100) => {
  // turn off when debuging

  if (!DEBUG.angle315checkerHelper) {
    return true;
  }

  const stage = getStage();
  const existingChild = stage.getChildByName(name);
  if (existingChild) {
    stage.removeChild(existingChild);
  }

  const point = new PIXI.Graphics();
  point.beginFill(color, 0.80);
  point.drawCircle(x, y, 4);
  point.id = name;
  point.name = name;
  point.zIndex = zIndex;
  point.endFill();
  stage.addChild(point);
};

export const getEdgeType = (collisions: string[]): EDGES_TYPE => {
  const edgesTypes = collisions.map(getEdgeTypeFromCollision);

  return Math.min(...edgesTypes);
};
