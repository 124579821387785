import { Action } from '__common/store/action';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { Observable } from 'rxjs';

import { ObstructionsActionTypes } from '__editor/panelsEditor/components/obstructions/ObstructionsActionsConstants';
import { ADD_OBSTRUCTION_ACTION } from '__editor/panelsEditor/components/obstructions/ObstructionsActions';

import { isPanelCollideWithObstacles } from '../obstructions/obstructionsCollisions';
import { removePanelsWithNoSiblings } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import { REMOVE_PANELS } from '../panels/panelsActions';

export function filterOutPanelsCollidingWithObstruction(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(ObstructionsActionTypes.ADD_OBSTRUCTION)
  .map((action: Action<ADD_OBSTRUCTION_ACTION>) => {
    const {
      panels: {
        panels: _panels,
      },
      background: { 
        bgXY,
      }, 
      settings: { 
        panelWidth, 
        panelHeight,
      },
    } = store.getState();
    const panels = (<panelInState[]>_panels);
    const { roofId } = action.payload;
    const panelsToKeep = panels.filter(panel => !isPanelCollideWithObstacles(panel, roofId, bgXY.x, bgXY.y, panelWidth, panelHeight));
    const panelsOnlyWithSiblings = removePanelsWithNoSiblings(panelsToKeep);
    const idsToKeep = panelsOnlyWithSiblings.map(panel => panel.id);
    const idsToRemove = panels.filter((panel) => !idsToKeep.includes(panel.id)).map((panel => panel.id));
    return REMOVE_PANELS(idsToRemove);
  });
}
