import { areStandardClampsSelected, stdClampsInSelections } from '../../bomHelpers';
import { dispatch, state } from '__common/store';
import { isNxtHorizon, products, isNxtTilt, isSMTilt } from '__common/constants/products';
import { STANDARD_CLAMPS, PART_RM10_EVO_MODULE_CLAMP_BULK, PART_RM10_EVO_MODULE_CLAMP_KIT, 
  PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK, 
  MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME,
  MICRO_MNT_BND_TBOLT_SS,
  MLPE_TIGER_CLIP,
  MLPE_MOUNT_ASSY,
  BND_T_BOLT_NUT_3_8_3_4_SS,
  BND_T_BOLT_NUT_3_8_1_SS,
  mlpePartNumber,
  NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1,
  UGARD_M5,
  UGARD_M6,
  UGARD_M8,
  UGARD_CLP} from '__common/constants/parts';
import {
  ADD_ATTACHMENT_TO_BOM,
  ADD_PART_TO_BOM,
  DISABLE_LAG_BOLTS,
  DISABLE_PART,
  ENABLE_LAG_BOLTS,
  ENABLE_PART,
  FETCH_FRONT_TRIM_END_CAP,
  FETCH_RM10_MODULE_AND_HEX_BOLT,
  REMOVE_FRONT_TRIM_END_CAP,
  REMOVE_PART_FROM_BOM,
  SELECT_ATTACHMENT,
  SELECT_ACCESSORY,
  UNSELECT_ACCESSORY,
  SELECT_LAG_BOLTS,
  ADD_LFOOT,
  REMOVE_LFOOT,
  SELECT_TILE_REPLACEMENT_TYPE,
  ADD_TILE_REPLACEMENT_ITEM,
  REMOVE_TILE_REPLACEMENT_ITEM,
  SELECT_ROOF_ATTACHMENT_TYPE,
  ADD_ATTACHMENT_ITEM,
  REMOVE_ATTACHMENT_ITEM,
  UPDATE_QUANTITY,
  REMOVE_STRONGHOLD_ATT_KIT_COMP,
  ADD_STRONGHOLD_ATT_KIT_COMP,
  ADD_FLASHKIT_PRO_SB,
  REMOVE_FLASHKIT_PRO_SB,
  FETCH_RM10_EVO_MODULE_CLAMP_KIT,
  FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK,
  FETCH_RM10_EVO_MODULE_CLAMP_BULK,
  FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS,
} from '../../bomActions';
import {
  ADD_FLASHKIT_PRO,
  ADD_FLASHLOC_COMP_KIT,
  ADD_TILE_HOOK,
  REMOVE_FLASHKIT_PRO,
  REMOVE_FLASHLOC_COMP_KIT,
  REMOVE_TILE_HOOK,
  SELECT_ATTACHMENT_TYPE,
  UNSELECT_LAG_BOLTS,
} from 'actions';
import { FETCH_RAILS } from '../railsSelector/railsSelectorActions';
import { showErrorAlert } from '__common/modules/alerts';
import { IS_GRIDPLEX_RM10_EVO_RM5_RMDT, IS_SMFLUSH_AND_GFT } from 'projectDesign/components/projectConfiguration/utils/constants';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

const FRONT_TRIM_PART_NUMER = '206072D-B';

const hooksWithoutFlashing = ['004CT5S', '004AT1S', '004ST1S'];

const UGARD_PARTS = [UGARD_M5, UGARD_M6, UGARD_M8, UGARD_CLP];

export const changeAttachmentType = (attachmentType: string) => {
  dispatch(UNSELECT_LAG_BOLTS());
  dispatch(SELECT_ATTACHMENT_TYPE(attachmentType));

  if (attachmentType === 'Tile Hook') {
    dispatch(ADD_TILE_HOOK());
  } else {
    dispatch(REMOVE_TILE_HOOK());
  }

  if (attachmentType === 'Flashkit Pro') {
    dispatch(ADD_FLASHKIT_PRO());
  } else {
    dispatch(REMOVE_FLASHKIT_PRO());
  }

  if (attachmentType === 'Flashkit Pro SB') {
    dispatch(ADD_FLASHKIT_PRO_SB());
  } else {
    dispatch(REMOVE_FLASHKIT_PRO_SB());
  }

  // selection of 'None' as a roof attachment keeps L Foot in partslist
  if (attachmentType === 'L Foot Only' || attachmentType === '2 Piece Aluminum Standoff' || attachmentType === 'None') {
    dispatch(ADD_LFOOT());
  } else {
    dispatch(REMOVE_LFOOT());
  }

  if (attachmentType === 'FLASHLOC COMP KIT') {
    dispatch(ADD_FLASHLOC_COMP_KIT());
  } else {
    dispatch(REMOVE_FLASHLOC_COMP_KIT());
  }
  // if (attachmentType == "STRONGHOLD ATT COMP - NON SEALANT") {
  //   dispatch(ADD_STRONGHOLD_ATT_COMP_NON_SEALANT());
  //   dispatch(REMOVE_STRONGHOLD_ATT_KIT_COMP());
  // }
  if (attachmentType == 'STRONGHOLD ATT KIT COMP') {
    dispatch(ADD_STRONGHOLD_ATT_KIT_COMP());
    // dispatch(REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT());
  } else {
    dispatch(REMOVE_STRONGHOLD_ATT_KIT_COMP())
  }
};

export const changeTileReplacementType = (group: string, selectedGroupItem: string, groupItemTypes: string[]) => {
  dispatch(SELECT_TILE_REPLACEMENT_TYPE(group, selectedGroupItem));
  groupItemTypes.map(itemType => {
    if (itemType === selectedGroupItem) dispatch(ADD_TILE_REPLACEMENT_ITEM(group, itemType));
    else dispatch(REMOVE_TILE_REPLACEMENT_ITEM(group, itemType));
  });

};

export const changeMetalXRoofAttachmentType = (group: string, selectedGroupItem: string, groupItemTypes: string[]) => {
  standingSeamAttachmentsCheck(group);
  dispatch(SELECT_ROOF_ATTACHMENT_TYPE(group, selectedGroupItem));

  groupItemTypes.map(itemType => {
    if (itemType === selectedGroupItem) dispatch(ADD_ATTACHMENT_ITEM(group, itemType));
    else dispatch(REMOVE_ATTACHMENT_ITEM(group, itemType));
  });
};


export const changeAttachment = (attachment: string, attachmentGroup: string, attachments: bomPart[]) => {
  
  const selectedPart = attachments.filter(singleAttachment => singleAttachment.number.includes(attachment.slice(0, -1)))[0];
  dispatch(SELECT_ATTACHMENT(attachmentGroup, attachment));
  dispatch(ADD_ATTACHMENT_TO_BOM(selectedPart));

  if (attachment === 'QMSE-LAG' || attachment === '004050M') {
    dispatch(UNSELECT_LAG_BOLTS());
    dispatch(DISABLE_LAG_BOLTS());
    dispatch(REMOVE_PART_FROM_BOM('LAGS'));
    dispatch(REMOVE_PART_FROM_BOM('WASHERS'));
  } else {
    dispatch(ENABLE_LAG_BOLTS());
  }

  if (hooksWithoutFlashing.includes(attachment)) {
    dispatch(REMOVE_PART_FROM_BOM('004FLCT'));
    dispatch(SELECT_ATTACHMENT('flashings', ''));
  }

  if (attachment === '004050M') {
    dispatch(DISABLE_PART('304001C'));
  } else {
    dispatch(ENABLE_PART('304001C'));
  }
};

export const showAlertsOnPartsSelection = (partsList: bomPartsList, partNumber: string) => {
  if ( !partsList.find(part => part.number.includes(partNumber))) {
    if (partNumber === 'WRMCNSD1' ) {
      showErrorAlert('Quantity is one per row. Add/remove as required for NXT UMOUNT ns wire mgmt clip ( WRMCNSD1 )');
    }
  }
}

export const selectAccessory = (accessory: bomPart, partsList: bomPartsList, accessoriesList: bomPartsList, continuous_trims_sections: number, productId: string, projectId: string, rm_roof_pad: roof_pad) => {
  dispatch(SELECT_ACCESSORY(accessory.number));
  showAlertsOnPartsSelection(partsList, accessory.number);

  const { bom : { extraParameters : { row_to_row_bonding_qty } },projectConfiguration : { productId : product_id, projectVersion : projectVersion} } = state()
  const forMlpe  = (isNxtHorizon(product_id) || isNxtTilt(product_id)) ? accessoriesList.find(e=>e.number === 'LUGMLPE1M') : null
  const forLug = (isNxtHorizon(product_id) || isNxtTilt(product_id)) ? accessoriesList.find(e=>e.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1) : null
  const forRowToRowBonding = ((isNxtHorizon(product_id) || isNxtTilt(product_id)) && row_to_row_bonding_qty > 0) ? accessoriesList.find(e=>e.number ==='LUGMLPE1R') : null

  // for mlpe
  if ((accessory.number === '008114M') && (isNxtHorizon(product_id) || isNxtTilt(product_id))) {
    dispatch(UNSELECT_ACCESSORY('LUGMLPE1M'));
    dispatch(UPDATE_QUANTITY(forMlpe));
  }

  if (accessory.number === 'LUGMLPE1M') {
    dispatch(REMOVE_PART_FROM_BOM('008114M'));
  }

  //  for row to row bonding
  if (row_to_row_bonding_qty > 0 && (isNxtHorizon(product_id) || isNxtTilt(product_id))) {
    if (accessory.number === 'LUGMLPE1R') {
      dispatch(REMOVE_PART_FROM_BOM('008000U'));
      dispatch(REMOVE_PART_FROM_BOM('008015S'));
    }
  
    if (accessory.number === '008000U') {
      dispatch(REMOVE_PART_FROM_BOM('008015S'));
      dispatch(UNSELECT_ACCESSORY('LUGMLPE1R'));
      dispatch(UPDATE_QUANTITY(forRowToRowBonding));
    }
  
    if (accessory.number === '008015S') {
      dispatch(REMOVE_PART_FROM_BOM('008000U'));
      dispatch(UNSELECT_ACCESSORY('LUGMLPE1R'));
      dispatch(UPDATE_QUANTITY(forRowToRowBonding));
    }
  }

  // for lug 
  if (accessory.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1) {
    dispatch(REMOVE_PART_FROM_BOM("008009P"));
    dispatch(REMOVE_PART_FROM_BOM("008002S"));
  }

  if ((accessory.number === "008009P") && (isNxtHorizon(product_id) || isNxtTilt(product_id))) {
    dispatch(REMOVE_PART_FROM_BOM("008002S"));
    dispatch(UNSELECT_ACCESSORY(NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1));
    dispatch(UPDATE_QUANTITY(forLug));
  }

  if ((accessory.number === "008002S") && (isNxtHorizon(product_id) || isNxtTilt(product_id))) {
    dispatch(REMOVE_PART_FROM_BOM("008009P"));
    dispatch(UNSELECT_ACCESSORY(NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1));
    dispatch(UPDATE_QUANTITY(forLug));

  }

  if (accessory.type === 'Front Trim') {
    let fetch_front_trim_cap_or_not = false
    if (!areStandardClampsSelected(partsList) && !stdClampsInSelections()) {
      fetch_front_trim_cap_or_not = true
    }
    dispatch(FETCH_FRONT_TRIM_END_CAP(products[productId], continuous_trims_sections, projectId, accessory.quantity, fetch_front_trim_cap_or_not));
    return dispatch(ADD_PART_TO_BOM(accessory));
  }

  if (accessory.type === STANDARD_CLAMPS.type) {
    if (isFrontTrimSelected(partsList)) {
      dispatch(REMOVE_PART_FROM_BOM('008025S'));
    }

    const { railsSelector: { railsList } } = state();
    return dispatch(FETCH_RAILS(railsList, projectId, true));
  }

  if ( accessory.number === '310760' && rm_roof_pad?.is_roof_pad_required){
    return dispatch(UPDATE_QUANTITY(accessory))
  }

  if(accessory.number === 'LUGMLPE1M' || accessory.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 || accessory.number === 'LUGMLPE1R') {
    const { bom: { partsList } } = state();
    if( !partsList.find(part => part.number.includes(NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1))) {
      dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1)));
    }
    return dispatch(UPDATE_QUANTITY(accessory));
  }

  dispatch(ADD_PART_TO_BOM(accessory));

  if (accessory.number === '310750') {
    dispatch(REMOVE_PART_FROM_BOM('310749'));
    return dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, '310751')));
  }

  if (accessory.number === '310751') {
    dispatch(REMOVE_PART_FROM_BOM('310749'));
    return dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, '310750')));
  }

  if (accessory.number === '310749') {
    dispatch(REMOVE_PART_FROM_BOM('310750'));
    return dispatch(REMOVE_PART_FROM_BOM('310751'));
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK) {
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, PART_RM10_EVO_MODULE_CLAMP_BULK)));
    return dispatch(REMOVE_PART_FROM_BOM(PART_RM10_EVO_MODULE_CLAMP_KIT));
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_BULK) {
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK)));
    return dispatch(REMOVE_PART_FROM_BOM(PART_RM10_EVO_MODULE_CLAMP_KIT));
  }

  if (products[productId] !== 14 && products[productId] !== 15 && products[productId] !== 8 && products[productId]!=26) {
    if (accessory.number === '008009P') {
      dispatch(REMOVE_PART_FROM_BOM('008002S'));
    }

    if (accessory.number === '008002S') {
      dispatch(REMOVE_PART_FROM_BOM('008009P'));
    }
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_KIT) {
    dispatch(REMOVE_PART_FROM_BOM(PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK));
    dispatch(REMOVE_PART_FROM_BOM(PART_RM10_EVO_MODULE_CLAMP_BULK))
  }
  
  if (!forMlpe?.selected && !forLug?.selected && !forRowToRowBonding?.selected) {
    dispatch(REMOVE_PART_FROM_BOM(NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1));
  }

  if (accessory.number === MICRO_MNT_BND_TBOLT_SS && (IS_SMFLUSH_AND_GFT.includes(products[productId]) || (adjustableTiltSystem(projectVersion) && isSMTilt(products[productId])))) {
    dispatch(REMOVE_PART_FROM_BOM(MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME));
  }

  if (accessory.number === MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME && (IS_SMFLUSH_AND_GFT.includes(products[productId]) || (adjustableTiltSystem(projectVersion) && isSMTilt(products[productId])))) {
    dispatch(REMOVE_PART_FROM_BOM(MICRO_MNT_BND_TBOLT_SS));
  }

  if (accessory.number === MLPE_TIGER_CLIP && IS_GRIDPLEX_RM10_EVO_RM5_RMDT.includes(products[productId])) {
    dispatch(REMOVE_PART_FROM_BOM(MLPE_MOUNT_ASSY));
  }

  if (accessory.number === MLPE_MOUNT_ASSY && IS_GRIDPLEX_RM10_EVO_RM5_RMDT.includes(products[productId])) {
    dispatch(REMOVE_PART_FROM_BOM(MLPE_TIGER_CLIP));
  }

  if (accessory.number === BND_T_BOLT_NUT_3_8_3_4_SS) {
    dispatch(REMOVE_PART_FROM_BOM(BND_T_BOLT_NUT_3_8_1_SS));
  }
 
  if(accessory.number === UGARD_M5 || accessory.number === UGARD_M6 || accessory.number === UGARD_M8 || accessory.number === UGARD_CLP) {
    UGARD_PARTS.forEach(
      number => number !== accessory.number && dispatch(REMOVE_PART_FROM_BOM(number))
    );
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, accessory.number===UGARD_CLP ? UGARD_M5 : UGARD_CLP)));
  }
};

export const unselectAccessory = (accessory: bomPart, partsList: bomPartsList, accessoriesList: bomPartsList, productId: string, projectId: string, continuous_trims_sections: number, rm_roof_pad: roof_pad) => {
  dispatch(UNSELECT_ACCESSORY(accessory.number));

  const { projectConfiguration : { projectVersion : projectVersion} } = state()
  
  if (accessory.type === 'Front Trim' && accessory.number === '206072D-B') {
    dispatch(REMOVE_FRONT_TRIM_END_CAP(products[productId],continuous_trims_sections, projectId, accessory.quantity));
    dispatch(REMOVE_PART_FROM_BOM('008025S'));
  }

  if (accessory.number === MICRO_MNT_BND_TBOLT_SS && (IS_SMFLUSH_AND_GFT.includes(products[productId]) || (adjustableTiltSystem(projectVersion) && isSMTilt(products[productId])))) {
    dispatch(REMOVE_PART_FROM_BOM(MICRO_MNT_BND_TBOLT_SS));
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME)));
  }

  if (accessory.number === MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME && (IS_SMFLUSH_AND_GFT.includes(products[productId]) || (adjustableTiltSystem(projectVersion) && isSMTilt(products[productId])))) {
    dispatch(REMOVE_PART_FROM_BOM(MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME));
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, MICRO_MNT_BND_TBOLT_SS)));
  }

  if (accessory.number === MLPE_TIGER_CLIP && IS_GRIDPLEX_RM10_EVO_RM5_RMDT.includes(products[productId])) {
    dispatch(REMOVE_PART_FROM_BOM(MLPE_TIGER_CLIP));
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, MLPE_MOUNT_ASSY)));
  }

  if (accessory.number === MLPE_MOUNT_ASSY && IS_GRIDPLEX_RM10_EVO_RM5_RMDT.includes(products[productId])) {
    dispatch(REMOVE_PART_FROM_BOM(MLPE_MOUNT_ASSY));
    dispatch(ADD_PART_TO_BOM(getPartFromPartsList(accessoriesList, MLPE_TIGER_CLIP)));
  }

  if (accessory.number === BND_T_BOLT_NUT_3_8_3_4_SS) {
    const qty = partsList.filter(part => part.number === BND_T_BOLT_NUT_3_8_3_4_SS)[0].quantity;
    dispatch(FETCH_T_BOLTS_009020S_FOR_SOLARHOOKS(projectId, qty));
  }

  if (accessory.type === STANDARD_CLAMPS.type) {
    if (isFrontTrimSelected(partsList)) {
      const frontTrim = partsList.filter(part => part.number === FRONT_TRIM_PART_NUMER)[0].quantity;
      let fetch_front_trim_cap_or_not = false
      if (!areStandardClampsSelected(partsList) && !stdClampsInSelections()) {
        fetch_front_trim_cap_or_not = true
      }
      dispatch(FETCH_FRONT_TRIM_END_CAP(products[productId], continuous_trims_sections, projectId, frontTrim, fetch_front_trim_cap_or_not));
    }
    const { railsSelector: { railsList } } = state();
    dispatch(FETCH_RAILS(railsList, projectId, false));
  }

  if (products[productId] !== 14 && products[productId] !== 15 && products[productId] !== 8) {
    if (accessory.number === '008009P') {
      dispatch(REMOVE_PART_FROM_BOM('008002S'));
    }

    if (accessory.number === '008002S') {
      dispatch(REMOVE_PART_FROM_BOM('008009P'));
    }
  }

  if (accessory.number === '310750') {
    dispatch(REMOVE_PART_FROM_BOM('310751'));
  }

  if (accessory.number === '310751') {
    dispatch(REMOVE_PART_FROM_BOM('310750'));
  }

  if (accessory.number === '310749') {
    const qty = partsList.filter(part => part.number === '310749')[0].quantity;
    dispatch(FETCH_RM10_MODULE_AND_HEX_BOLT(projectId, qty));
  }

  if (accessory.number === '310760' && rm_roof_pad?.is_roof_pad_required){
    return dispatch(UPDATE_QUANTITY(accessory));
  }

  if(accessory.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1  || accessory.number === 'LUGMLPE1M' || accessory.number === 'LUGMLPE1R') {
    if(( accessoriesList.find(acc => acc.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1).selected ||  accessoriesList.find(acc => acc.number === 'LUGMLPE1M').selected 
    || accessoriesList.find(acc => acc.number === 'LUGMLPE1R')?.selected)) {
      return dispatch(UPDATE_QUANTITY(accessory));
    }
    else {
      return  dispatch(REMOVE_PART_FROM_BOM(NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1));
    }
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_BULK) {
    const qty = partsList.filter(part => part.number === PART_RM10_EVO_MODULE_CLAMP_BULK)[0].quantity;
    return dispatch(FETCH_RM10_EVO_MODULE_CLAMP_KIT(projectId, qty));
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK) {
    const qty = partsList.filter(part => part.number === PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK)[0].quantity;
    return dispatch(FETCH_RM10_EVO_MODULE_CLAMP_KIT(projectId, qty));
  }

  if (accessory.number === PART_RM10_EVO_MODULE_CLAMP_KIT) {
    const qty = partsList.filter(part => part.number === PART_RM10_EVO_MODULE_CLAMP_KIT)[0].quantity;
    dispatch(FETCH_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK(projectId, qty));
    dispatch(FETCH_RM10_EVO_MODULE_CLAMP_BULK(projectId, qty));
    
  }

  if(UGARD_PARTS.includes(accessory.number)) {
    UGARD_PARTS.forEach(number => dispatch(REMOVE_PART_FROM_BOM(number)));
  }

  dispatch(REMOVE_PART_FROM_BOM(accessory.number));
};


export const selectLagBolts = (lagBolts: [bomPart, bomPart]) => {
  dispatch(SELECT_LAG_BOLTS());
  dispatch(ADD_PART_TO_BOM(lagBolts[0]));
  dispatch(ADD_PART_TO_BOM(lagBolts[1]));
};

export const unSelectLagBolts = (lagBolts: [bomPart, bomPart]) => {
  dispatch(UNSELECT_LAG_BOLTS());
  dispatch(REMOVE_PART_FROM_BOM(lagBolts[0].number));
  dispatch(REMOVE_PART_FROM_BOM(lagBolts[1].number));
};

function getPartFromPartsList(partsList: bomPartsList, partNumber: string): bomPart | null {
  const part = partsList.filter(accessory => accessory.number === partNumber);
  if (part.length === 1) {
    return part[0];
  }

  return null;
}

function isFrontTrimSelected(partsList: bomPartsList) {
  return partsList.filter(part => part.number === FRONT_TRIM_PART_NUMER).length > 0;
}

const standingSeamAttachmentsCheck = (group: string) => {
  const { bom: { attachmentsList } } = state();
  const sNonMetal = 'standing seam not verified';
  const sMetal = 'standing seam metal attachments';
  let item;
  if(group === sNonMetal){
    Object.keys(attachmentsList[sMetal]).map(key => {
      if(attachmentsList[sMetal][key].selected) {
        item = item || key;
      }
    });

    if(item){
      dispatch(REMOVE_ATTACHMENT_ITEM(sMetal, item));
    }
    dispatch(SELECT_ROOF_ATTACHMENT_TYPE(sMetal, ""));
  }
  if(group === sMetal){
    Object.keys(attachmentsList[sNonMetal]).map(key => {
      if(attachmentsList[sNonMetal][key].selected) {
        item = item || key;
      }
    });
    if(item) dispatch(REMOVE_ATTACHMENT_ITEM(sNonMetal, item));
  }
  dispatch(SELECT_ROOF_ATTACHMENT_TYPE(sNonMetal, ""));
};
