import React from 'react';
import ReactSelect from 'react-select';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import _ from 'lodash';

type Props = {
  value: string | number,
  items: any[],
  label?: JSX.Element,
  onConfirm?: Function,
  onCancel?: Function,
  autocomplete?: boolean,
  selectFieldProps?: any,
  shouldConfirm: boolean,
  shouldConfirmCb?: (val: string) => boolean
  title: string,
  content: string | ((val) => string),
  disabled?: boolean,
  optional_array_values?: any[]
};

type State = {
  value: string | number,
  tempValue: string | number,
  items: any[],
  confirmVisible: boolean,
};

export default class ConfirmationSelectField extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      tempValue: '',
      confirmVisible: false,
      items: props.items,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value, tempValue: this.state.value, confirmVisible: false });
    }
  }

  handleFieldChange = (val: any) => {
    const { shouldConfirm, onConfirm, shouldConfirmCb } = this.props;
    const confirmed = shouldConfirm && (!shouldConfirmCb || shouldConfirmCb(val));
    const value = typeof val === 'object' ? val.id : val;
    if (confirmed && !val.highlight) {
      this.setState({ value, tempValue: this.state.value, confirmVisible: true });
    } else {
      this.setState({ value, tempValue: this.state.value, confirmVisible: false });

      if (onConfirm) {
        onConfirm(value, val.highlight);
      }
    }
    
  }

  confirm = () => {
    const { onConfirm } = this.props;
    this.setState({ tempValue: this.state.value, confirmVisible: false });

    if (onConfirm) {
      onConfirm(this.state.value);
    }
  }

  cancel = () => {
    const { onCancel } = this.props;
    this.setState({ value: this.state.tempValue, confirmVisible: false });

    if (onCancel) {
      onCancel(this.state.tempValue);
    }
  }

  renderSelect = () => {
    const { selectFieldProps, items, label } = this.props;
    const { value } = this.state;
    
    return (
      <>
        {label && label}
        <SelectField
          id=""
          value={value}
          onChange={this.handleFieldChange}
          menuItems={items}
          /* listHeightRestricted={false} */ // To check why we would be doing this for any SelectField
          {...selectFieldProps}
          disabled={this.props?.disabled}
        />
      </>
    );
  }

  renderProSelect = () => {
    const { selectFieldProps, items } = this.props;
    const { value } = this.state;
    const valObj = items.find(item => item.id === value);

    if (this.props.optional_array_values && this.props.optional_array_values.length > 0 && this.props.optional_array_values[1] && valObj) {
      if (valObj.id === 185) {
        valObj.name = this.props.optional_array_values[0]
      }else {
        valObj.name = this.props.optional_array_values[1]
      }
    }
    return (
      <ReactSelect 
        value={valObj || null}
        styles={{
          valueContainer: (base) => {
            return {
              ...base,
              padding: 0,
            };
          },
          indicatorSeparator: () => {
            return {
              display: 'none',
            };
          },
          option: (base, state) => {
            const color = state.isSelected ? '#0083c2' : '#5f5f5f';
            const fontWeight = state.data.highlight ? 700 : 500;
            return {
              ...base,
              background: 'transparent !important',
              fontWeight,
              color,
              fontFamily: 'Open Sans',
            };
          }, 
          control: (base, state) => {
            const borderColor = state.isFocused ? '#0083c2' : '#bbbbbb';
            return {
              ...base,
              border: 'none !important',
              boxShadow: 'none !important',
              borderBottom: `1px solid ${borderColor} !important`,
              borderRadius: '0px',
            };
          },
        }}
        onChange={this.handleFieldChange}
        options={items}
        getOptionLabel={item => item[selectFieldProps.itemLabel]}
        getOptionValue={item => item[selectFieldProps.itemValue]}
        classNamePrefix="react_select"
        {...selectFieldProps}
        isDisabled={this.props?.disabled}
      />
    );
  }

  render() {
    const { title, content, autocomplete } = this.props;
    const { confirmVisible, value } = this.state;

    return (
      <>
        {autocomplete ? this.renderProSelect() : this.renderSelect()}
        <DialogContainer
          id="arrays-lost-modal"
          title={title}
          actions={[{
            className: 'cancel-button',
            children: 'NO, CANCEL',
            onClick: this.cancel,
          }, {
            className: 'submit-button',
            children: 'YES, CONTINUE',
            onClick: this.confirm,
          }]}
          visible={confirmVisible}
          onHide={this.cancel}
        >
          <p>{_.isFunction(content) ? content(value) : content}</p>
        </DialogContainer>
      </>
    );
  }
}
