import { asce_705, asce_710, asce_716, asce_722, isASCE716or722, isASCE722 } from "__common/constants/buildingCodes";
import { state } from "__common/store";
import { applyRMGF5SetbackChanges } from "__common/utils/versionCompare/versionCompare";
import { SOIL_CLASS } from "projectDesign/components/projectConfiguration/fields/soilClass";

const linear = require('everpolate').linear;
// step function is being used in backend calculation as np.interp
const step = require('everpolate').step

export const getSDS = (seismicSs: number, buildingCode: number, soilClass: number=SOIL_CLASS.D_DEFAULT) => {
  const SEISMIC_XP = [0.25, 0.5, 0.75, 1.0, 1.25];
  const BUILDING_CODE_SOIL_CLASS_COEFFICIENTS_FP_MAP = {
    [asce_722.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.9, 0.9, 0.9, 0.9, 0.9],
      3: [1.3, 1.3, 1.2, 1.2, 1.2],
      4: [1.6, 1.4, 1.2, 1.1, 1.0],
      5: [1.6, 1.4, 1.2, 1.1, 1.0],
      6: [2.4, 1.7, 1.3, 1.2, 1.2],
    },
    [asce_716.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.9, 0.9, 0.9, 0.9, 0.9],
      3: [1.3, 1.3, 1.2, 1.2, 1.2],
      4: [1.6, 1.4, 1.2, 1.1, 1.0],
      5: [1.6, 1.4, 1.2, 1.1, 1.0],
      6: [2.4, 1.7, 1.3, 1.2, 1.2],
    },
    [asce_710.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.9, 0.9, 0.9, 0.9, 0.9],
      3: [1.3, 1.3, 1.2, 1.2, 1.2],
      4: [1.6, 1.4, 1.2, 1.1, 1.0],
      5: [1.6, 1.4, 1.2, 1.1, 1.0],
      6: [2.4, 1.7, 1.3, 1.2, 1.2],
    },
    [asce_705.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.9, 0.9, 0.9, 0.9, 0.9],
      3: [1.3, 1.3, 1.2, 1.2, 1.2],
      4: [1.6, 1.4, 1.2, 1.1, 1.0],
      5: [1.6, 1.4, 1.2, 1.1, 1.0],
      6: [2.4, 1.7, 1.3, 1.2, 1.2],
    },
  };

  const COEFFICIENTS_FP = BUILDING_CODE_SOIL_CLASS_COEFFICIENTS_FP_MAP[buildingCode][soilClass];
  let short_period_site_coefficient_fa = step(seismicSs, SEISMIC_XP, COEFFICIENTS_FP);
  const { projectConfiguration: { projectVersion } } = state();
  if (isASCE716or722(buildingCode) && soilClass == SOIL_CLASS.D_DEFAULT && applyRMGF5SetbackChanges(projectVersion)){
    short_period_site_coefficient_fa = Math.max(short_period_site_coefficient_fa, 1.2) 
  }
  const sds = (2 / 3) * (short_period_site_coefficient_fa * seismicSs);
  return sds;
};


export const getSD1 = (seismicS1: number, buildingCode: number, soilClass: number = SOIL_CLASS.D_DEFAULT) => {
  const BUILDING_CODE_SEISMIC_S1_XP_MAP = {
    [asce_705.value]: [0.1, 0.2, 0.3, 0.4, 0.5],
    [asce_710.value]: [0.1, 0.2, 0.3, 0.4, 0.5],
    [asce_716.value]: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6],
    [asce_722.value]: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6],
  };
  const BUILDING_CODE_SOIL_CLASS_COEFFICIENTS_FP_MAP = {
    [asce_705.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [1.0, 1.0, 1.0, 1.0, 1.0],
      3: [1.7, 1.6, 1.5, 1.4, 1.3],
      4: [2.4, 2.0, 1.8, 1.6, 1.5],
      5: [2.4, 2.0, 1.8, 1.6, 1.5],
      6: [3.5, 3.2, 2.8, 2.4, 2.4], 
    },
    [asce_710.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8],
      2: [1.0, 1.0, 1.0, 1.0, 1.0],
      3: [1.7, 1.6, 1.5, 1.4, 1.3],
      4: [2.4, 2.0, 1.8, 1.6, 1.5],
      5: [2.4, 2.0, 1.8, 1.6, 1.5],
      6: [3.5, 3.2, 2.8, 2.4, 2.4], 
    },
    [asce_716.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
      3: [1.5, 1.5, 1.5, 1.5, 1.5, 1.4],
      4: [2.4, 2.2, 2.0, 1.9, 1.8, 1.7],
      5: [2.4, 2.2, 2.0, 1.9, 1.8, 1.7],
    },
    [asce_722.value]: {
      1: [0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
      2: [0.8, 0.8, 0.8, 0.8, 0.8, 0.8],
      3: [1.5, 1.5, 1.5, 1.5, 1.5, 1.4],
      4: [2.4, 2.2, 2.0, 1.9, 1.8, 1.7],
      5: [2.4, 2.2, 2.0, 1.9, 1.8, 1.7],
    },
  };

  const SEISMIC_XP = BUILDING_CODE_SEISMIC_S1_XP_MAP[buildingCode];
  const COEFFICIENTS_FP = BUILDING_CODE_SOIL_CLASS_COEFFICIENTS_FP_MAP[buildingCode][soilClass];

  const Fv = step(seismicS1, SEISMIC_XP, COEFFICIENTS_FP);
  const Sm1 = Fv * seismicS1;
  const Sd1 = 2/3 * Sm1;
  return Sd1;
};

export const getSeismicDesignCategory = (projectEnvConfig: projectEnvConfig) => {
  
  const { seismic_ss: seismicSs, seismic_s1: seismicS1, building_code: buildingCode, soil_class: soilClass, risk_category: riskCategory, seismic_sds, seismic_sd1 } = projectEnvConfig;
  let Sd1 = undefined;
  let Sds = undefined;
  if (isASCE722(buildingCode)){
    Sd1 = seismic_sd1;
    Sds = seismic_sds;
  } else {
    Sd1 = getSD1(seismicS1, buildingCode, soilClass);
    Sds = getSDS(seismicSs, buildingCode, soilClass);
  }
  let X = 4;
  let Y = 4;

  if (Sds < 0.167) {
    X = 1;
  }else if(Sds >= 0.167 && Sds < 0.33) {
    X = riskCategory === 4? 3: 2; 
  }else if(Sd1 >= 0.33 && Sd1 < 0.5){
    X = riskCategory === 4? 4: 3;
  } else if (Sd1 >= 0.5) {
    X=4;
  }

  if (Sd1 < 0.067) {
    Y = 1;
  }else if(Sd1 >= 0.067 && Sd1 < 0.133) {
    Y = riskCategory === 4? 3: 2; 
  }else if(Sd1 >= 0.133 && Sd1 < 0.2){
    Y = riskCategory === 4? 4: 3;
  } else if (Sd1 >= 0.2) {
    Y=4;
  }

  const Z = Math.max(X, Y);

  let seismicDesignCategory = 'F';
  
  if (riskCategory === 4 && seismicS1 >= 0.75) {
    seismicDesignCategory = 'F';
  }else if(seismicS1 >= 0.75) {
    seismicDesignCategory = 'E';
  }else if(Z===1){
    seismicDesignCategory = 'A';
  }else if(Z===2){
    seismicDesignCategory = 'B';
  }else if(Z===3){
    seismicDesignCategory = 'C';
  }else if(Z===4){
    seismicDesignCategory = 'D';
  }
  return { Sds, Sd1, seismicDesignCategory };
};
