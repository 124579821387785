import store, { state, dispatch } from '__common/store';
import { getBuildingHeight, isASCE716or722Selected, isFlatRoof } from '../projectConfiguration/projectConfiguration';
import { REPLACE_PANELS_ON_ROOF, SET_ROOF_ROOF_PITCH } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { isRMFamily, isCommercialProduct, isRM5, isRMDT} from '__common/constants/products';
import { isBlankMap } from '__common/constants/map';
import { checkObstructionZoneForPanels } from '__editor/panelsEditor/components/obstructions/obstructionsCollisions';
import { getEditorCenter } from '__editor/panelsEditor/components/background/background';
import { checkPanelCollisionWithRestrictedZones } from '__editor/panelsEditor/components/roofZones/utils/restrictedZoneCollisionsDetections';
import { checkCollisionWithExpZones } from '__editor/panelsEditor/components/roofZones/utils/expZonesCollisionsDetection';
import { checkCollisionWithWindZones } from '__editor/panelsEditor/components/roofZones/utils/windZonesCollisionsDetection';
import { removePanelsWithNoSiblings, setExposureForSibligns } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import { setPanelRoofZone, isPanelInsideOfRoofEdgesMemoized as isPanelInsideOfRoofEdges, getPanelCollisionBounds } from '__editor/panelsEditor/components/panels/panels';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { recheckExposureForNewPanelsNeighbours } from '__common/components/exposure/multiPanelsExposureStateOutsideOfEditor';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { getProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { insertPanelIntoRtree, clearRTree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { recalculateRmZones } from '__editor/panelsEditor/components/panels/utils/recalculateZones';
import { Store } from 'redux';
import { checkPanelCollisionWithSetBackDistance } from '__editor/panelsEditor/components/roofZones/utils/setBackDistanceCollisionDetections';
import { shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { getPanelBounds, getRoofBoundsMemoized as getRoofBounds } from '__editor/panelsEditor/components/roofEdges/roofEdgesCollisions';
import { filterOutPanelsNotInsideRoofEdges } from 'projectDesign/rmGridflexBlankMapUtils';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { cmsToMeters } from '__common/calculations/unitConversions';
 
interface GET_ASCE716_STATUS {
  panel: panelInState;
  relativeToCenter: boolean;
  roofEdges: google.maps.LatLngLiteral[];
  roofCenter: cordPoint;
  zoom: number;
  buildingHeightFt: number;
  metersPerPixel: number;
  bgRotationDegrees: number;
  bgXOffset: number;
  bgYOffset: number;
  panelWidth: number;
  panelHeight: number;
  productId: number;
  buildingType: number;
  roofPitch: string;
  roofId: number;
  roofEdgesPixiCords,
  mapType: string,
  tilt: number,
  projectVersion: string,
}

export const switchProjectToASCE716 = (productId: number, buildingCodeHasChangedToAsce716: boolean = false) => {
  const { roofsSelector: { mapType }, projectConfiguration: { projectEnvConfig: { building_type }, projectVersion } } = state();
  const roofs = getProjectRoofs();
  if (roofs) {
    const editorCenter = getEditorCenter();
    const buildingHeightFt = getBuildingHeight();

    Object.keys(roofs).forEach(roofKey => {
      const roofId = Number(roofKey);
      const roof: drawingManagerRoof = roofs[roofId];
      const panels = roof.panels;
      const rowSpacing = roof.rowSpacing;
      const { columnSpacing } = getSpacings(productId, projectVersion);

      if (isFlatRoof(building_type)) {
        dispatch(SET_ROOF_ROOF_PITCH(roof.id, null));
      }

      if (roof.roofPitch === '1/12' && !isFlatRoof(building_type)) {
        dispatch(SET_ROOF_ROOF_PITCH(roof.id, '2/12'));
      }
      if (!panels || panels.length === 0) {
        return;
      }

      const bgRotationDegrees = roof.bgRotation;
      const roofEdges = roof.coords;
      const roofCenter = roof.marker;
      const zoom = roof.zoom;
      const metersPerPixel = roof.metersPerPixel;

      recalculateRoofZonesForPanels(
        store,
        panels,
        roof,
        mapType,
        productId,
        editorCenter,
        buildingHeightFt,
        building_type,
        projectVersion
      ).then((panelsWithReassignedZones) => {
        let updatedPanels = removePanelsWithNoSiblings(panelsWithReassignedZones);
        updatedPanels = checkObstructionZoneForPanels(updatedPanels, roofId, metersPerPixel, editorCenter.x, editorCenter.y);
        clearRTree();
  
        updatedPanels.map(panel => {
          const bounds = getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
          insertPanelIntoRtree(bounds);
        });

        const exposeData = recheckExposureForNewPanelsNeighbours(
          updatedPanels,
          updatedPanels.map(getId),
          roofEdges,
          roofId,
          roofCenter,
          zoom,
          bgRotationDegrees,
          metersPerPixel,
          rowSpacing,
          columnSpacing,
          editorCenter,
          roof.roofPitch,
          productId,
          mapType,
        );

        updatedPanels = exposeData.panels.map(panel => {
          if (exposeData.forceExposureChanged[panel.id] !== undefined) {
            panel.exposedNeighbour = exposeData.forceExposureChanged[panel.id];
          }
          return panel;
        });

        setExposureForSibligns(updatedPanels, productId);
        clearRTree();
        dispatch(REPLACE_PANELS_ON_ROOF(updatedPanels, roofId));
      });

    });
  }
};

const getId = (panel: panelInState): number => {
  return panel.id;
};

export const recalculateRoofZonesForPanels = (
  store: Store<appState>,
  panels: panelInState[],
  roof: drawingManagerRoof,
  mapType: string, 
  productId: number,
  editorCenter: pixelPoint,
  buildingHeightFt: number,
  buildingType: number,
  projectVersion: string,
) => {
  if (isCommercialProduct(productId)) {
    const {
      projectConfiguration: {
        productId,
      },
      settings: {
        rowSpacing, 
        columnSpacing,
      },
      background: {
        metersPerPixel,
      },
    } = store.getState();
    if(shouldUseSetBackDistance(mapType, productId, projectVersion)){
      const filteredPanels = removePanelsInSetbackDistance( panels,roof, editorCenter);
      return recalculateRmZones(productId, rowSpacing, columnSpacing, metersPerPixel, filteredPanels, roof.id);
    }
    return recalculateRmZones(productId, rowSpacing, columnSpacing, metersPerPixel, panels, roof.id);
  }

  return Promise.resolve(panels.map(recalculateRoofZones(
    roof,
    mapType,
    productId,
    editorCenter,
    buildingHeightFt,
    buildingType,
  )).filter(panel => panel));
};

const recalculateRoofZones = (
  roof: drawingManagerRoof,
  mapType: string, 
  productId: number,
  editorCenter: pixelPoint,
  buildingHeightFt: number,
  buildingType: number,
) => (panel: panelInState): panelInState => {

  const { 
    bgRotation, 
    roofPitch, 
    zoom,
    metersPerPixel,
    marker: roofCenter,
    coords: roofEdges,
    id: roofId,
    roofEdgesPixiCords,
  } = roof;
  

  const {projectConfiguration: {projectEnvConfig: {tilt}}, roofsSelector: {mapType}, projectVersion} = store.getState();

  if (isBlankMap(mapType)) {
    // TODO - this logic is too hidden, but I have no better idea how to expose it.
    return panel;
  }

  const panelBound = getPanelBounds(panel, true, editorCenter.x, editorCenter.y);
  const roofBound = getRoofBounds({roofEdges, roofCenter, roofEdgesPixiCords, bgOffSet: {x: editorCenter.x, y: editorCenter.y}, zoom, rotationRadians:degreesToRadians(bgRotation), roofPitch, tilt, productId, mapType});
    
  const isInsideRoof = isPanelInsideOfRoofEdges(
    {panelBound,
    roofBound,
    mapType, productId}
  );

  if (!isInsideRoof) {
    return undefined;
  }

  const { isInRestictedZone, windZone, edgeType } = getASCE716Status({
    panel,
    relativeToCenter: true,
    roofEdges,
    roofCenter,
    zoom,
    buildingHeightFt,
    metersPerPixel,
    bgRotationDegrees: bgRotation,
    bgXOffset: editorCenter.x,
    bgYOffset: editorCenter.y,
    panelWidth: panel.width,
    panelHeight: panel.height,
    productId,
    buildingType,
    roofPitch,
    roofId,
    roofEdgesPixiCords,
    mapType,
    tilt,
    projectVersion
  });

  if (isInRestictedZone) {
    return undefined;
  }

  if (!isASCE716or722Selected()){
    panel.roofZone = (windZone-1) as roofZoneNumber;
    return panel;
  }
  panel.roofZone = windZone;
  panel.edgeType = edgeType;
  return panel;
};

export const switchProjectToNonASCE716 = (productId: number) => {
  const { projectConfiguration: { projectEnvConfig: { building_type }, projectVersion }, roofsSelector: { mapType } } = state();
  const roofs = getProjectRoofs();
  
  if (roofs) {
    const center = getEditorCenter();
    const buildingHeightFt = getBuildingHeight();

    Object.keys(roofs).map(roofId => {
      const roof = roofs[roofId];
      let panels: panelInState[] = roof.panels;

      if (!panels || !Array.isArray(panels) || !panels.length) {
        return;
      }

      const bgRotationDegrees = roof.bgRotation;
      const roofEdges = roof.coords;
      const roofCenter = roof.marker;
      const zoom = roof.zoom;
      const metersPerPixel = roof.metersPerPixel;
      if(shouldUseSetBackDistance(mapType, productId, projectVersion)){
        panels = removePanelsInSetbackDistance(panels, roof, center );
      }
      const newPanels = panels.map(panel => {

        if (!isRM5(productId) && !isRMDT(productId)) {
          delete panel.roofZone;
          setPanelRoofZone(
            panel,
            roofEdges,
            roofCenter, zoom,
            buildingHeightFt,
            metersPerPixel,
            bgRotationDegrees,
            center.x,
            center.y,
            panel.width,
            panel.height,
            productId,
            building_type,
            roof.roofPitch,
            parseInt(roofId, 10),
          );
        }

        delete panel.exposed;
        delete panel.exposedNeighbour;
        
        return panel;
      });

      dispatch(REPLACE_PANELS_ON_ROOF(newPanels, Number(roofId)));
    });
  }
};

export const getASCE716Status = ({
  panel,
  relativeToCenter,
  roofEdges,
  roofCenter,
  zoom,
  buildingHeightFt,
  metersPerPixel,
  bgRotationDegrees,
  bgXOffset,
  bgYOffset,
  panelWidth,
  panelHeight,
  productId,
  buildingType,
  roofPitch,
  roofId,
  roofEdgesPixiCords,
  mapType,
  tilt,
  projectVersion
}: GET_ASCE716_STATUS): { isInRestictedZone: boolean, isInExposureZone: boolean, windZone: roofZoneNumber, edgeType: number } => {
  let isInExposureZone: boolean;
  const insideOfPolygon = true;
  const isInRestictedZone = !shouldUseSetBackDistance(mapType, productId, projectVersion) && isASCE716or722Selected() && !isBlankMap(mapType) ?  checkPanelCollisionWithRestrictedZones(
    panel,
    relativeToCenter,
    roofEdges,
    roofId,
    roofCenter,
    zoom,
    metersPerPixel,
    bgRotationDegrees,
    bgXOffset,
    bgYOffset,
    panelWidth,
    panelHeight,
    productId,
    insideOfPolygon,
    roofPitch,
    roofEdgesPixiCords,
    mapType,
    tilt,
  ) : false;

  if (isRMFamily(productId)) {
    isInExposureZone = checkCollisionWithExpZones(
      panel,
      relativeToCenter,
      roofEdges,
      roofId,
      roofCenter,
      zoom,
      bgRotationDegrees,
      bgXOffset,
      bgYOffset,
      panelWidth,
      panelHeight,
      insideOfPolygon,
      roofPitch,
      metersPerPixel,
      roofEdgesPixiCords,
      mapType,
      productId,
      tilt,
    );
  } else {
    isInExposureZone = false;
  }

  const windZone = checkCollisionWithWindZones({
    panel,
    relativeToCenter,
    roofEdges,
    roofCenter,
    zoom,
    buildingHeightFt,
    metersPerPixel,
    bgRotationDegrees,
    bgXOffset,
    bgYOffset,
    panelWidth,
    panelHeight,
    insideOfPolygon,
    buildingType,
    roofPitch,
    roofId,
  });


  return {
    isInRestictedZone,
    isInExposureZone,
    windZone: windZone.zone,
    edgeType: windZone.edgeType,
  };
};

export const getProjectRoofs = () => {
  const { drawingManager: { roofs } } = state();

  if (roofs && Object.keys(roofs).length) {
    return roofs;
  }

  return null;
};

export const getSpacings = (productId: number, version: string) => {
  const { columnSpacing, rowSpacing } = getProductSettings(productId, version);

  return {
    rowSpacing: inchesToMeters(rowSpacing),
    columnSpacing: inchesToMeters(columnSpacing),
  };
};

const removePanelsInSetbackDistance = (panels : panelInState[], roof, editorCenter) => {
  const {
    projectConfiguration: {
      productId,
      projectEnvConfig: {
        setback_distance,
        tilt
      },
      inputUnit
    },
    settings: {
      panelWidth,
      panelHeight,
    },
    background: {roofEdgesPixiCords},
    roofsSelector: {mapType},
  } = state();

  const insideOfPolygon = true;

  const { 
    bgRotation, 
    roofPitch, 
    zoom,
    metersPerPixel,
    marker: roofCenter,
    coords: roofEdges,
    id: roofId,
    bgScale,
    blank_map_building_length,
    blank_map_building_width
  } = roof;

  const newPanels = (panel)=> {
    return !(checkPanelCollisionWithSetBackDistance(
      panel,
      true,
      roofEdges,
      roofId,
      roofCenter,
      zoom,
      metersPerPixel,
      bgRotation,
      editorCenter.x,
      editorCenter.y,
      panelWidth,
      panelHeight,
      productId,
      insideOfPolygon,
      roofPitch,
      roofEdgesPixiCords,
      mapType,
      tilt)); 
  }; 

  const setbackInMeters = isMetricUnit(inputUnit) ? cmsToMeters(setback_distance) : feetsToMeters(setback_distance);
  const filteredPanels = isBlankMap(mapType) ? filterOutPanelsNotInsideRoofEdges({ 
    length : blank_map_building_length, 
    width : blank_map_building_width,
    setbackDistance: setbackInMeters, 
    metersPerPixel, 
    bgScale, 
    panelWidth, 
    panelHeight, 
    panels 
  }) : panels.filter(newPanels);

  return filteredPanels;
};


