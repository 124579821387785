import { state } from "__common/store";
import { applyMlpeComboClampRailClampChanges } from "__common/utils/versionCompare/versionCompare";

export const STANDARD_CLAMPS = {
  type: 'Standard Clamps',
  number: 'STDCLAMPS',
};

export const MID_CLAMP = {
  type: 'Mid Clamp',
};

export const END_CLAMP = {
  type: 'End Clamp',
};

export const NXT_CLAMP_TYPE = {
  type: 'Universal Clamp',
}

export const NXT_HIDDEN_END_CLAMP_TYPE = {
  type : 'Hidden End Clamp'
}

export const PART_RM10_EVO_MODULE_CLAMP_KIT = '370020'
export const PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK = '370022'
export const PART_RM10_EVO_MODULE_CLAMP_BULK = '370023'
export const MICRO_INVERTER_MOUNTING_TO_MODULE_FRAME = "008114M"
export const MICRO_MNT_BND_TBOLT_SS = '008013S'
export const MLPE_MOUNT_ASSY = "008114M"
export const MLPE_TIGER_CLIP = "008115M"
export const BND_T_BOLT_NUT_3_8_3_4_SS = '009021S'
export const BND_T_BOLT_NUT_3_8_1_SS = '009020S'
const NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG = 'LUGMLPE2'
const NXT_MLPE_LUG_CLAMP_FOR_LUG = 'LUGMLPE1'
export const NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 = 'NULGMLP1'
export const RM_ROOF_PAD = '310760'
export const UGARD_M5 = 'UGARD-M5'
export const UGARD_M6 = 'UGARD-M6'
export const UGARD_M8 = 'UGARD-M8'
export const UGARD_CLP = 'UGARD-CLP'

export const mlpePartNumber = () => {
  const { projectConfiguration: { projectVersion } } = state();
  return applyMlpeComboClampRailClampChanges(projectVersion) ? NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG: NXT_MLPE_LUG_CLAMP_FOR_LUG;
}
