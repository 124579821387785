import { dispatch, state } from "__common/store";
import Polygon from 'polygon';
import { getSetBackDistance, getSetbackPoints } from "../roofZones/drawRoofZonesOnStage";
import { getStage } from "../stage/stage";
import { getRoofBoundsMemoized, panelPolygon } from "../roofEdges/roofEdgesCollisions";
import polygonsIntersect from 'polygons-intersect';
import { REPLACE_PANELS_ON_ROOF, SET_SKEW_ZONE_COORDINATES } from "actions";
import { feetsToMeters } from "__common/calculations/feetsToMeters";
import { degreesToRadians } from "maths";
import { applyNewSkewVersion } from "__common/utils/versionCompare/versionCompare";

const SKEW_ZONE = 'skew zone', TOLERANCE = 0.5; // tolerance - used to check if a point exists in skew zone or not
const { abs, atan2, PI, sqrt, sin, cos, floor } = Math;

const calculateNormal = (p1, p2) => {
  const dx = p2.y - p1.y;
  const dy = p1.x - p2.x;
  const length = sqrt(dx * dx + dy * dy);
  return { x: dx / length, y: dy / length };
};

const offsetPoint = (p, normal, distance) => ({
  x: p.x + normal.x * distance,
  y: p.y + normal.y * distance
});

const calculateSkewZoneCorners = (p, pn, normal, d) => {
  const off1 = offsetPoint(p, normal, d);
  const off2 = offsetPoint(pn, normal, d);

  return [off1, off2];
}

let d; // radial distance
const calculateSkewZoneCoordsNew = (roofId?) => {
  const {
    background, tiltedRoof: { roofPitch }, roofsSelector: { mapType }, drawingManager: { roofs },
    projectConfiguration: { productId, projectEnvConfig: { tilt, building_height } },
  } = state();

  let input = { productId, tilt, mapType, roofEdges: [], roofCenter: { lat: 0, lng: 0 }, roofEdgesPixiCords: {}, bgOffSet: { x: 0, y: 0 }, zoom: 0, rotationRadians: 0, roofPitch: '0' };
  let metersPerPixelVal;

  if (!background?.selectedRoofId && roofId) {
    const { bgRotation, roofPitch, zoom, metersPerPixel, marker, coords, bgOffset, roofEdgesPixiCords } = roofs[roofId];
    metersPerPixelVal = metersPerPixel;
    d = feetsToMeters(5 * building_height) / metersPerPixel;

    input = { ...input, roofEdges: coords, roofCenter: marker, roofEdgesPixiCords, bgOffSet: bgOffset, zoom, rotationRadians: degreesToRadians(bgRotation), roofPitch };
  } else {
    const { roofEdges, cords, zoom, rotationDegrees, bgXY, metersPerPixel, roofEdgesPixiCords } = background;
    metersPerPixelVal = metersPerPixel;
    d = feetsToMeters(5 * building_height) / metersPerPixel;

    input = { ...input, roofEdges, roofCenter: cords, roofEdgesPixiCords, bgOffSet: bgXY, zoom, rotationRadians: degreesToRadians(rotationDegrees), roofPitch };
  }

  const setbackDistance = getSetBackDistance(metersPerPixelVal);
  const c = getSetbackPoints(background.selectedRoofId ?? roofId), l = c.length;
  let skewCoords = [];

  const roofEdgesPolygon = getRoofBoundsMemoized(input);

  for (let i = 0; i < l; i++) {
    const n = (i + 1) % l;

    const angle = atan2(abs(c[n].y - c[i].y), abs(c[n].x - c[i].x)) * (180 / PI);

    if (angle > 12.5 && angle < 77.5) {
      const normal = calculateNormal(c[i], c[n]);
      const offset = offsetPoint({ x: (c[i].x + c[n].x) / 2, y: (c[i].y + c[n].y) / 2 }, normal, setbackDistance + feetsToMeters(TOLERANCE) / metersPerPixelVal);

      if (roofEdgesPolygon.containsPoint(offset)) {
        const corners = calculateSkewZoneCorners(c[i], c[n], normal, d);
        skewCoords.push({ p: [...corners, c[n], c[i]], c: [c[i], c[n]] });
      }
      else {
        const corners = calculateSkewZoneCorners(c[i], c[n], normal, -d);
        skewCoords.push({ p: [...corners, c[n], c[i]], c: [c[i], c[n]] });
      }
    }
  }
  dispatch(SET_SKEW_ZONE_COORDINATES(background.selectedRoofId ?? roofId, skewCoords));
}

export const drawSkewZonesOnStage = (cords) => {
  const { projectConfiguration: { projectVersion } } = state();
  const stage = getStage();
  const skewZonesContainer = new PIXI.Container();
  skewZonesContainer.id = SKEW_ZONE;
  cords?.forEach(coords => {
    let skewZones;
    if (applyNewSkewVersion(projectVersion)) {
      skewZones = skewZonesModelNew(coords);
    } else {
      const polygon = new Polygon(coords);
      skewZones = skewZonesModelOld(polygon.clean(true).dedupe(true).points);
    }
    skewZonesContainer.addChild(skewZones);
  });
  stage.addChild(skewZonesContainer);
}

const skewZonesModelNew = ({ p, c }) => {
  const {
    background: { roofEdges, cords, zoom, rotationDegrees, bgXY, roofEdgesPixiCords, },
    tiltedRoof: { roofPitch }, roofsSelector: { mapType },
    projectConfiguration: { productId, projectEnvConfig: { tilt } },
  } = state();

  const roofEdgesPolygon = getRoofBoundsMemoized({ roofEdges, roofCenter: cords, roofEdgesPixiCords, bgOffSet: bgXY, zoom, rotationRadians: rotationDegrees, roofPitch, tilt, productId, mapType });

  const e = new PIXI.Graphics();
  e.interactive = true;
  e.lineStyle(0.5);

  const drawDashedArc = (centerX, centerY, radius, startAngle, endAngle, dashLength, gapLength) => {
    const angleStep = dashLength / radius;
    let angle = startAngle;

    e.moveTo(centerX + cos(angle) * radius, centerY + sin(angle) * radius);

    const offset = () => ({ x: centerX + cos(angle) * radius, y: centerY + sin(angle) * radius });

    while (angle < endAngle) {
      angle += angleStep;
      if (angle > endAngle) angle = endAngle;

      let point = offset();
      e.lineTo(point.x, point.y);

      angle += gapLength / radius;
      point = offset();
      e.moveTo(point.x, point.y);

      // if(!roofEdgesPolygon.containsPoint(point)) break;
    }
  }

  const drawDash = (x1, y1, x2, y2, dashLength = 10, gapLength = 1) => {
    const totalLength = sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    const dashCount = floor(totalLength / (dashLength + gapLength));
    const dx = (x2 - x1) / dashCount;
    const dy = (y2 - y1) / dashCount;

    for (let i = 0; i < dashCount; i++) {
      const startX = x1 + i * dx;
      const startY = y1 + i * dy;

      const endX = startX + dx * (dashLength / (dashLength + gapLength));
      const endY = startY + dy * (dashLength / (dashLength + gapLength));

      // if(roofEdgesPolygon.containsPoint({ x: startX, y: startY }) || roofEdgesPolygon.containsPoint({ x: endX, y: endY })) {
      e.moveTo(startX, startY);
      e.lineTo(endX, endY);
      // }
    }
  }

  e.moveTo(p[0].x, p[0].y);
  drawDash(p[0].x, p[0].y, p[1].x, p[1].y);

  // if (roofEdgesPolygon.containsPoint({ x: p[0].x, y: p[0].y }))
  drawDashedArc(c[0].x, c[0].y, d, 0, PI * 2, 10, 1);
  // if (roofEdgesPolygon.containsPoint({ x: p[1].x, y: p[1].y }))
  drawDashedArc(c[1].x, c[1].y, d, 0, PI * 2, 10, 1);

  return e;
}

const isPanelinSkewZoneNew = (panel, roofId?) => {
  let { drawingManager: { roofs }, background: { selectedRoofId, bgXY } } = state();

  if (!selectedRoofId && roofId) {
    selectedRoofId = roofId;
    bgXY = roofs[selectedRoofId].bgOffset;
  }

  let panelPoly = panelPolygon(panel, true, bgXY.x, bgXY.y, panel.width, panel.height);
  let { skewEdgesPixiCords } = roofs[selectedRoofId];

  const intersection = skewEdgesPixiCords?.some(({ p, c }) => {
    const skewZone = new Polygon(p);
    const intersect = polygonsIntersect(skewZone.clean(true).dedupe(true).points, panelPoly.points);
    if (intersect.length) return true;

    const inCircle = c.some(c => panelPoly.points.some(({ x, y }) => sqrt((c.x - x) ** 2 + (c.y - y) ** 2) <= d));
    return inCircle;
  });
  return intersection ? 1 : 0;
};

// when building height is changed
export const reCalculateSkewZoneAndPanelsForAllRoofs = () => {
  const { projectConfiguration: { projectEnvConfig: { building_height } }, drawingManager: { roofs } } = state();

  for (const selectedRoofId in roofs) {
    const { panels, metersPerPixel } = roofs[selectedRoofId];
    d = feetsToMeters(5 * building_height) / metersPerPixel;

    calculateSkewZoneCoords(selectedRoofId);

    const newPanels = panels.map(panel => ({ ...panel, skewAffected: isPanelinSkewZone(panel, selectedRoofId) }));
    dispatch(REPLACE_PANELS_ON_ROOF(newPanels, +selectedRoofId));
  }
};

// OLD
const calculateSkewZoneCoordsOld = (roofId?) => {
  const { settings: { rowSpacing, columnSpacing, panelWidth, panelHeight }, background: { selectedRoofId } } = state();

  const r = 3 * (columnSpacing + panelWidth);
  const c = 3 * (rowSpacing + panelHeight);

  const cordsInPx = getSetbackPoints(selectedRoofId ?? roofId);
  let cords = [];

  for (let index = 0; index < cordsInPx.length; index++) {
    const { x, y } = cordsInPx[index];
    const { x: xn, y: yn } = cordsInPx[index === cordsInPx.length - 1 ? 0 : index + 1];
    const angle = atan2(abs(yn - y), abs(xn - x)) * (180 / PI);

    if (angle > 12.5 && angle < 77.5) {
      const x0 = x + r, y0 = y + c, x1 = xn + r, y1 = yn + c;
      const x2 = x - r, y2 = y - c, x3 = xn - r, y3 = yn - c;

      if (y < yn) {
        if (x < xn)
          cords[index] = [{ x, y }, { x: x2, y: y }, { x: x2, y: y1 }, { x: xn, y: y1 }, { x: xn, y: yn }, { x: x1, y: yn }, { x: x1, y: y2 }, { x: x, y: y2 }];
        else
          cords[index] = [{ x, y }, { x: x0, y: y }, { x: x0, y: y1 }, { x: xn, y: y1 }, { x: xn, y: yn }, { x: x3, y: yn }, { x: x3, y: y2 }, { x: x, y: y2 }];
      }
      else {
        if (x < xn)
          cords[index] = [{ x, y }, { x: x, y: y0 }, { x: x1, y: y0 }, { x: x1, y: yn }, { x: xn, y: yn }, { x: xn, y: y3 }, { x: x2, y: y3 }, { x: x2, y: y }];
        else
          cords[index] = [{ x, y }, { x: x0, y: y }, { x: x0, y: y3 }, { x: xn, y: y3 }, { x: xn, y: yn }, { x: x3, y: yn }, { x: x3, y: y0 }, { x: x, y: y0 }];
      }
    }
  }
  dispatch(SET_SKEW_ZONE_COORDINATES(selectedRoofId ?? roofId, cords));
}

const skewZonesModelOld = (coordsInPx: pixelPoint[]): PIXI.Graphics => {
  const edges: PIXI.Graphics = new PIXI.Graphics();
  const newCoords = coordsInPx.reduce<number[]>((acc, { x, y }) => [...acc, x, y], []);
  const poly = new PIXI.Polygon(newCoords);
  edges.interactive = true;
  edges.hitArea = poly;
  edges.lineStyle(1);
  edges.drawPolygon(newCoords.concat(newCoords[0]).concat(newCoords[1]));
  edges.zIndex = 99999;
  return edges;
}

const isPanelinSkewZoneOld = (panel, roofId?) => {
  let { drawingManager: { roofs }, background: { selectedRoofId, bgXY } } = state();
  if(!selectedRoofId && roofId) {
    bgXY = roofs[roofId].bgOffset;
  }
  const panelPoly = panelPolygon(panel, true, bgXY.x, bgXY.y, panel.width, panel.height);

  const intersection = roofs[selectedRoofId ?? roofId].skewEdgesPixiCords?.some(cords => {
    const skewZone = new Polygon(cords);

    const intersect = polygonsIntersect(skewZone.clean(true).dedupe(true).points, panelPoly.points);
    return intersect.length > 0;
  });
  return intersection ? 1 : 0;
};

export const calculateSkewZoneCoords = (roofId?) => {
  const { projectConfiguration: { projectVersion } } = state();
  applyNewSkewVersion(projectVersion) ? calculateSkewZoneCoordsNew(roofId) : calculateSkewZoneCoordsOld(roofId);
}

export const isPanelinSkewZone = (panel, roofId?) => {
  const { projectConfiguration: { projectVersion } } = state();
  return applyNewSkewVersion(projectVersion) ? isPanelinSkewZoneNew(panel, roofId) : isPanelinSkewZoneOld(panel, roofId);
}