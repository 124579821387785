import { getColorByRoofZone } from '__editor/panelsEditor/models/panel';
import { insertPanelIntoRtree } from '__editor/panelsEditor/components/panels/panelsCollisions';
import { dispatch, state } from '__common/store';
import { getNearestGroup, _isAscenderFamily } from '../cursor/utils/snapToGridHelper';
import {getPanelPositionInfo, isPanelOnCorner } from '__editor/panelsEditor/components/roofEdges/roofEdgesCollisions';
import { getTiltedModuleSizes } from '__editor/panelsEditor/components/tiltedModule/tiltedModule';
import { updateRoofCoordsForClassicDesigner } from '__editor/components/roofsSelector/components/roofsSelectorClassicDesigner/roofsSelectorClassicDesigner';
import {
  getPanelSize,
} from '__editor/panelsEditor/components/panels/panelsHelper';
import {
  RESET_HISTORY,
  SET_PANELS_WIDTH_AND_HEIGHT,
  SAVE_PANELS_TO_DRAWING_MANAGER,
  CHANGE_SINGLE_PANEL_ROOF_ZONE,
  SET_PANELS_ARRAY_FOR_MOVEMENT,
} from 'actions';
import { isASCE716or722Selected } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import {  isCloserFromEdgeThen, } from '../roofZones/roofZones';
import { checkCollisionWithWindZones, DEFAULT_CLASSIC_DESIGNER_ZONE, checkCollisionWithVirtualWindZones } from '../roofZones/utils/windZonesCollisionsDetection';
import { drawDoublePanels, drawSinglePanel, drawDesiredTableLengthPanelsPortrait, drawDesiredTableLengthAndWidthPanels, drawPanelsArrayForMovement, drawAscenderPanel,  } from './utils/panelsManagment';
import { getPanelsContainer } from './utils/panelsContainer';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { isRMDT, isGFT, isGroundProduct, isULA, isRMIFIProduct, isEcoFoot2Plus, isRM10Evolution, isRM10,  isResidentialProduct, isAscender, isRMAndEcofootFamily, isRmGridflex10, isRM5 } from '__common/constants/products';
import { isBlankMap } from '__common/constants/map';
import nextUid from '__common/calculations/nextUid';
import { needNewZoneClassification } from '__common/utils/versionCompare/versionCompare';
import { REMOVE_PANELS, } from './panelsActions';
import { shouldUpdateRoofEdgesOnPanelChange, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import moize from 'moize';

export const addPanelsWithoutSnapingToGrid = () => {
  const { projectConfiguration: { productId, projectEnvConfig:{is_aurora_project} }, panels: {panelsToBeMoved,  panelsArrangementToBeMoved} } = state();
  const group = nextUid();
 if(!is_aurora_project){
    dispatch(RESET_HISTORY());
    if(panelsToBeMoved.length > 0  && panelsArrangementToBeMoved.panelsToBeMovedGrid) {
      drawPanelsArrayForMovement(group);
    }
    else if (isRMDT(productId)) {
      drawDoublePanels(group);
    } else if (isGFT(productId) || isAscender(productId)) {
      // draw whole tables with a cursor click
      drawDesiredTableLengthPanelsPortrait(group);
    } else if (isULA(productId)) {
      drawDesiredTableLengthAndWidthPanels(group);
    } else {
      drawSinglePanel(group);
    }
 }
  
};

export const addPanelsWithSnapingToGrid = () => {
  const { projectConfiguration: { productId, projectEnvConfig:{is_aurora_project} }, } = state();
  if(!is_aurora_project){
    const group = getNearestGroup() || nextUid();
    dispatch(RESET_HISTORY());
    if (isRMDT(productId)) {
      drawDoublePanels(group);
    } else if (isGFT(productId)) {
      // draw whole tables with a cursor click
      drawDesiredTableLengthPanelsPortrait(group);
    } else if (isAscender(productId)) {
      drawAscenderPanel(group);
    } 
    else if (isULA(productId)) {
      drawDesiredTableLengthAndWidthPanels(group);
    } else {
      drawSinglePanel(group);
    }
  }
};

export const setPanelsDimensions = () => {
  const { background: { metersPerPixel }, projectConfiguration: { projectEnvConfig: { tilt } }, editorCursor: { landscape }, tiltedRoof : { structureTilt } } = state();
  let panelWidth;
  let panelHeight;

  if (metersPerPixel) {
    if (tilt || structureTilt) {
      const { width, height } = getTiltedModuleSizes();
      panelWidth = width;
      panelHeight = height;

      if (landscape && panelWidth < panelHeight) {
        panelWidth = height;
        panelHeight = width;
      }

    } else {
      const { width, height } = getPanelSize();
      panelWidth = width;
      panelHeight = height;

      if (landscape) {
        panelWidth = height;
        panelHeight = width;
      }
    }
  }
  if (panelWidth && panelHeight) dispatch(SET_PANELS_WIDTH_AND_HEIGHT(panelWidth, panelHeight));
};

const getRoofRowSpacing = (selectedRoofId: number) => {
  const { settings: { rowSpacing }, } = state();
  return rowSpacing;
};

const getRoofTableRowSpacing = (selectedRoofId: number) => {
  const { settings: { tableRowSpacing }, drawingManager: { roofs } } = state();
  // to store tablrowspacing value from settings to drawing manager
  // roofs[selectedRoofId].tableRowSpacing value = undefined for new projects, 0 for older porjects and positive value for reopening of new projects 
  if (roofs[selectedRoofId].tableRowSpacing != 0) return tableRowSpacing;
  return roofs[selectedRoofId].tableRowSpacing;
};

export const savePanelsEditor = () => {
  const {
    background: {
      selectedRoofId,
      rotationDegrees,
      metersPerPixel,
      zoom,
      bgXY: bgOffset,
      bgScale,
      roofEdgesPixiCords,
      blank_map_building_length,
      blank_map_building_width,
      panelsRotationDegrees,
      lowEdgeToRoof,
    },
    panels: {
      panels,
      panelsToBeMoved,
      exposureRecaluclationHash,
      bays: {
        panelBayMapping
      }
    },
    tiltedRoof: {
      roofPitch,
      structureTilt,
    },
    roofsSelector: {
      mapType,
    },
    projectConfiguration: {
      productId,
      projectEnvConfig:{
        allow_manual_attachments
      }
    },
  } = state();

  let moveArrayOriginalGroupIncluded = false;
  let allPanels: panelInState[] = []
  if(panelsToBeMoved.length) {
    moveArrayOriginalGroupIncluded = panels.map(p => p.groupId).includes(panelsToBeMoved[0].groupId);
  }
  if(moveArrayOriginalGroupIncluded) {
    allPanels = panels;
  }
  else allPanels = [...panels, ...panelsToBeMoved];

  if((isRM10Evolution(productId) || isRM5(productId)) && allow_manual_attachments){
    allPanels.forEach((panel: panelInState)=>{
      if (panelBayMapping.has(panel.id)){
        panel.manualAttachments = panelBayMapping.get(panel.id).map(({attached})=> attached)
      }
    })
  }
  
  if (allPanels && Array.isArray(allPanels)) {
    dispatch(SAVE_PANELS_TO_DRAWING_MANAGER(
      selectedRoofId,
      allPanels,
      rotationDegrees,
      bgOffset,
      bgScale,
      roofPitch,
      structureTilt,
      metersPerPixel,
      Number(zoom),
      getRoofRowSpacing(selectedRoofId),
      getRoofTableRowSpacing(selectedRoofId),
      roofEdgesPixiCords,
      exposureRecaluclationHash,
      blank_map_building_length,
      blank_map_building_width,
      panelsRotationDegrees,
      lowEdgeToRoof,
      panelBayMapping,
      ));

    if (isBlankMap(mapType)) {
      updateRoofCoordsForClassicDesigner();
    }
  }
};

export function getPanelCollisionBounds(panel: panelInState, rowSpacing: number, columnSpacing: number, metersPerPixel: number) {
  const panelWidth = panel.width;
  const panelHeight = panel.height;
  const columnsSpacingInPixels = columnSpacing / metersPerPixel;
  const rowSpacingInPixels = rowSpacing / metersPerPixel;

  const offset = 0.001;
  const x = panel.x - panelWidth / 2;
  const y = panel.y - panelHeight / 2;

  return {
    minX: x + offset - columnsSpacingInPixels,
    minY: y + offset - rowSpacingInPixels,
    maxX: x + panelWidth - offset + columnsSpacingInPixels,
    maxY: y + panelHeight - offset + rowSpacingInPixels,
    id: panel.id,
    siblingId: panel.siblingId,
  };
}

export * from '__editor/panelsEditor/components/panels/panelsHelper';

export function changePanelRoofZone(panelId: number) {
  const { panels: { panels } }: { panels: { panels: panelInState[] } } = state();
  const panelsGraphics = getPanelsContainer().children;

  const panelGraphicIndex = panelsGraphics.findIndex(pan => pan.id === panelId);
  const panelStoreIndex = panels.findIndex(pan => pan.id === panelId);

  const currentRoofZone = panels[panelStoreIndex].roofZone;
  const newRoofZone = currentRoofZone === 0 ? 2 : (currentRoofZone - 1) as roofZoneNumber;
  const panel = panelsGraphics[panelGraphicIndex];
  
  if (panel) {
    const panelGraphicData = panel.graphicsData;

    if (panelGraphicData && panelGraphicData[0]) {
      const isNearObstruction = false;
      panelGraphicData[0].fillColor = getColorByRoofZone(newRoofZone, isNearObstruction);
      
      panelGraphicData[0].fill = true;
    }
  }

  panelsGraphics[panelGraphicIndex].dirty++;
  panelsGraphics[panelGraphicIndex].clearDirty++;

  dispatch(CHANGE_SINGLE_PANEL_ROOF_ZONE(panelId, newRoofZone));
}

export function 
isPanelInsideOfRoofEdges(
  input:  {panelBound,
  roofBound,
  mapType: string,
  productId: number,
  }
): boolean {

  const {panelBound, roofBound, mapType, productId,} = input;
  const {projectConfiguration: {projectVersion}} = state();
  
  if (shouldUpdateRoofEdgesOnPanelChange(mapType, productId, projectVersion)  || (isBlankMap(mapType) && !shouldUseSetBackDistance(mapType, productId, projectVersion))){
    return true;
  }

  if (!roofBound) {
  return roofBound;
  }
  
  return roofBound.contains(panelBound);
}

export const isPanelInsideOfRoofEdgesMemoized = moize(isPanelInsideOfRoofEdges, {isSerialized: true});

export function setPanelRoofZone(
  panel: panelInState,
  roofEdges: google.maps.LatLngLiteral[],
  roofCenter: cordPoint,
  zoom: number,
  buildingHeightFt: number,
  metersPerPixel: number,
  bgRotationDegrees: number,
  bgXOffset: number,
  bgYOffset: number,
  panelWidth: number,
  panelHeight: number,
  productId: number,
  buildingType: number,
  roofPitch: string,
  roofId: number,
) {
  const { roofsSelector: { mapType } } = state();

  // for RM we will calculate zones later
  if (isGroundProduct(productId)) {
    return panel.roofZone = 0;
  }

  if (isBlankMap(mapType) && !isASCE716or722Selected() && !isRMAndEcofootFamily(productId)) {
    return panel.roofZone = 0;
  }

  if (isBlankMap(mapType) && (isASCE716or722Selected() || isRMAndEcofootFamily(productId))) {
    return panel.roofZone = DEFAULT_CLASSIC_DESIGNER_ZONE(productId);
  }

  const { projectConfiguration: { projectVersion } } = state();
  let useNewZoneClassification = needNewZoneClassification(projectVersion);

  if ((isASCE716or722Selected() && !isRMIFIProduct(productId)) || isRM10Evolution(productId)  || isRM10(productId) || isEcoFoot2Plus(productId) || (isResidentialProduct(productId) && useNewZoneClassification) || isRmGridflex10(productId)) {
    const insideOfPolygon = true;
    const roofZone = checkCollisionWithWindZones({
      panel,
      relativeToCenter: true,
      roofEdges,
      roofCenter,
      zoom,
      buildingHeightFt,
      metersPerPixel,
      bgRotationDegrees,
      bgXOffset,
      bgYOffset,
      panelWidth,
      panelHeight,
      insideOfPolygon,
      buildingType,
      roofPitch,
      roofId,
    });
    if (isResidentialProduct(productId)) {
      if (isResidentialProduct(productId) && useNewZoneClassification) {
        if (!isASCE716or722Selected()){
          panel.roofZone = (roofZone.zone-1) as roofZoneNumber;
          panel.edgeType = undefined;
        }
        else{
          panel.roofZone = roofZone.zone;
          if(isAscender(productId)) {
            panel.edgeType = roofZone.edgeType;
            panel.roofZoneId = panel.roofZone; 
          }
          else {
            if (roofZone.zone != 1){
              panel.edgeType = roofZone.edgeType;
            }
          }
          } 
        }
      } else {
        panel.roofZone = roofZone.zone;
        panel.edgeType = roofZone.edgeType;
      }
  } else {
    const panelPositionInfo = getPanelPositionInfo(panel, true, bgXOffset, bgYOffset, roofEdges, roofCenter, zoom, degreesToRadians(bgRotationDegrees), roofPitch);
    if (!panel.roofZone) {
      if (isPanelOnCorner(panelPositionInfo.closestDistancesFromEdges, productId, metersPerPixel, useNewZoneClassification)) {
        panel.roofZone = 2;
      } else if (isCloserFromEdgeThen(panelPositionInfo.closestDistancesFromEdges[0].dist, productId, metersPerPixel, useNewZoneClassification)) {
        panel.roofZone = 1;
      } else {
        panel.roofZone = 0;
      }
    }
  }
}

export function getPanelRoofZoneForVirtualEdges(panel: panelInState) {
  const {
    background: {
      bgXY: {
        x: bgXOffset,
        y: bgYOffset,
      },
      metersPerPixel,
    },
  } = state();
  return checkCollisionWithVirtualWindZones({
    panel,
    bgXOffset,
    bgYOffset,
    insideOfPolygon: true,
    metersPerPixel,
    relativeToCenter: true,
  });
}

export function setPanelRestrictedArea(panel: panelInState, rowSpacing: number, columnSpacing: number, metersPerPixel: number) {
  panel.rTreeBounds = getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
  insertPanelIntoRtree(panel.rTreeBounds);
}

export function setPanelsToBeSelectedForMovement(groupId: number) {
  const { panels: { panels } }: { panels: { panels: panelInState[] } } = state();
  const panelsToBeSelectedForMovement = panels.filter(p => p.groupId === groupId);
  dispatch(SET_PANELS_ARRAY_FOR_MOVEMENT(panelsToBeSelectedForMovement));
}

export function removePanelsOnMovement(groupId: number) {
  const { panels: { panels } }: { panels: { panels: panelInState[] } } = state();
  const panelsToHide = panels.filter(p => p.groupId === groupId);
  const panelsToHideIds = panelsToHide.map(p => p.id);
   dispatch(REMOVE_PANELS(panelsToHideIds));
}

