import * as React from 'react';
import TextField from 'react-md/lib/TextFields/TextField';
import classNames from 'classnames';
import { CircularProgress } from 'react-md';
import { onZipCodeChange } from 'projectDesign/components/projectConfiguration/utils/updateProjectOption';
import { isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';
import { state } from '__common/store';

type Props = {
  initData: projectEnvConfig;
  area: string;
  envFactorsLoading: boolean;
  productId: number;
  mapType: string;
};

type State = {
  zipCodeMessage: string;
};

export default class AreaComponent extends React.Component<Props, State> {
  state = {
    zipCodeMessage: '',
  };

  CANADIAN_ZIP_CODE_WARNING = 'This is Canadian zip code';
  NON_US_ZIP_CODE_WARNING = 'This is zipcode outside of USA and Canada';

  zipCodeMessage = () => {
    const zipcode = this.props.initData.zipcode ? this.props.initData.zipcode.toString().trim() : '';

    const us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
    
    if (isCanadianZipcode(zipcode)) {
      return this.CANADIAN_ZIP_CODE_WARNING;
    } 
    if (us.test(zipcode)) {
      return '';
    }

    return this.NON_US_ZIP_CODE_WARNING;
  }

  RenderLoadingCircle = () => {
    const { envFactorsLoading } = this.props;
    if (envFactorsLoading) {
      return (
        <div className="loading-circle">
        <CircularProgress id="env-factors" scale={0.75} />
      </div>
      );
    }

    return null;
  }


  loadLink = () => {
    return <a href={"https://asce7hazardtool.online/"} target="_blank">click here</a>;
  }

  render() {
    const {
      initData,
      area,
      productId,
      mapType,
    } = this.props;

    const message = this.zipCodeMessage();
    const hasError = message.length > 0;
    const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state()
    return (
      <div className="project-configuration-area">
        <div className="fields">
          <div>
            <div className={classNames('input-label', { 'input-warning': hasError })}>
              Zip code
            </div>
            <TextField
              id="zipcode"
              value={initData.zipcode || ''}
              lineDirection="center"
              inputClassName="no-top-margin md-text-field"
              fullWidth={false}
              onChange={(value) => onZipCodeChange(value.toString(), productId, initData, mapType)}
              className="area-input"
              disabled={is_aurora_project}
            />
          </div>
          <div className="area-info">
            <div className={classNames('input-label', { 'input-warning': hasError })}>
              CITY, STATE
            </div>
            <div>{area}</div>
          </div>
        </div>
        <div>
        {hasError ? <div className="input-warning zipcode-warning">{message}</div> : null}
        </div>
      </div>
    );
  }
}
