import cursorModel from '__editor/panelsEditor/models/cursor';
import scaleCursorModel from '__editor/panelsEditor/models/scaleCursorModel';
import { dispatch, state } from '__common/store';
import { removeChild } from '__editor/panelsEditor/components/stage/stage';
import { SET_CURSOR_OVERLAP_PANEL, UNSET_CURSOR_OVERLAP_PANEL } from 'actions';
import { snapToIfClosestPanel } from '__editor/panelsEditor/components/cursor/snapToGrid';
import { isPanelCollideWithObstacles } from '../obstructions/obstructionsCollisions';
import { products, isCommercialProduct, isResidentialProduct, isGroundProduct, isRMIFIProduct, isEcoFoot2Plus, isRMAndEcofootFamily, isRM10} from '__common/constants/products';
import { isPanelOnCorner } from '../roofEdges/roofEdgesCollisions';
import { getBuildingHeight, isASCE716or722Selected } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { isCloserFromEdgeThen } from '../roofZones/roofZones';
import { checkCursorCollisionWithRestrictedZones } from '../roofZones/utils/restrictedZoneCollisionsDetections';
import { checkCursorCollisionWithSetBackDistance } from '../roofZones/utils/setBackDistanceCollisionDetections';
import { checkCollisionWithWindZones } from '../roofZones/utils/windZonesCollisionsDetection';
import { getLastSnapGroup } from '../panels/utils/panelsSnappingGroup';
import { asce705and710MapColors } from './colors/asce705and710MapColors';
import { asce705and710BlankColors } from './colors/asce705and710BlankColors';
import { asce716CommercialsMapColors } from './colors/asce716CommercialsMapColors';
import { asce716CommercialsBlankColors } from './colors/asce716CommercialsBlankColors';
import { asce716ResidentialsMapColors } from './colors/asce716ResidentialsMapColors';
import { asce716ResidentialsBlankColors } from './colors/asce716ResidentialsBlankColors';
import { asce710and716GroundColors } from './colors/asce710and716GroundColors';
import { shouldUseDesiredBuildingLengthWidth, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { isBlankMap } from '__common/constants/map';
import { needNewZoneClassification } from '__common/utils/versionCompare/versionCompare';

let cursor;

export const removeCursor = (children) => {
  cursor = undefined;
  return removeChild(children, 'cursor');
};

export const drawCursor = (coords: { x: number, y: number }, productId: number) => {
  const { scale: { enabled } } = state();
  if (enabled) {
    cursor = scaleCursorModel(coords);
  } else {
    cursor = cursorModel(coords, productId);
  }
  return cursor;
};

export const getCursor = () => cursor;

export const followSystemCursor = (mousePositionX: number, mousePositionY: number, productId) => {
  return snapToIfClosestPanel({ x: mousePositionX, y: mousePositionY });
};

export const mousePosition = () => state().editorCursor.position;

export const cursorPosition = () => {
  const { x, y } = mousePosition();
  return snapToIfClosestPanel({ x, y });
};

export const isCursorMoving = (startX: number, startY: number, currentX: number, currentY: number) => {
  const modificator = state().settings.startSelectingAfterPx;
  const result = (startX > currentX + modificator || startX < currentX - modificator) ||
    (startY > currentY + modificator || startY < currentY - modificator);
  return result;
};

export function cannotDrawCursor() {
  dispatch(SET_CURSOR_OVERLAP_PANEL());
}

export function canDrawCursor() {
  dispatch(UNSET_CURSOR_OVERLAP_PANEL());
}

export function setCursorStatus(cursor: PIXI.Graphics, cursorCollisionData, cursorColide: boolean, productId: string) {
  const { 
    background: { 
      roofEdges, 
      cords, 
      zoom, 
      metersPerPixel, 
      rotationDegrees, 
      selectedRoofId,
      bgXY: { x, y },
    }, 
    settings: { 
      panelWidth, 
      panelHeight,
    }, 
    roofsSelector: { mapType }, 
    projectConfiguration: { 
      projectVersion,
      projectEnvConfig: { building_type },
    }, 
    tiltedRoof: { roofPitch },
  } = state();
  const buildingHeight = getBuildingHeight();

  if (isGroundProduct(products[productId])) {
    return asce710and716GroundColors(cursor, cursorColide, mapType, productId);
  }

  if ((isASCE716or722Selected() && !isRMIFIProduct(products[productId]) || isEcoFoot2Plus(products[productId])) || isRM10(products[productId]) || isResidentialProduct(products[productId])) {
    const insideOfPolygon = true;
    let roofZoneInfo;
    if(!cursorColide) {
      roofZoneInfo = checkCollisionWithWindZones({
        panel: cursor, 
        relativeToCenter: false, 
        roofEdges, 
        roofCenter :cords, 
        zoom, 
        buildingHeightFt: buildingHeight, 
        metersPerPixel, 
        bgRotationDegrees: rotationDegrees, 
        bgXOffset: x, 
        bgYOffset: y, 
        panelWidth, 
        panelHeight, 
        insideOfPolygon, 
        buildingType :building_type, 
        roofPitch,
        roofId: selectedRoofId,
      });
    }
    const zoneInfo = !cursorColide ? roofZoneInfo.zone : undefined;
    if (isAsce716CommercialsMap(mapType, products[productId]) || isEcoFoot2Plus(products[productId])) {
      return asce716CommercialsMapColors(cursor, cursorColide, mapType, productId, zoneInfo);
    }
  
    if (isAsce716CommercialsBlank(mapType, products[productId])) {
      return asce716CommercialsBlankColors(cursor, cursorColide, mapType, productId, zoneInfo);
    }

    if (isAsce716ResidentialsBlank(mapType, products[productId])) {
      return asce716ResidentialsBlankColors(cursor, cursorColide, mapType, productId, zoneInfo);
    }

    if (isAsce716ResidentialsMap(mapType, products[productId])) {
      return asce716ResidentialsMapColors(cursor, cursorColide, mapType, productId, zoneInfo);
    }
    if (isResidentialProduct(products[productId])) {
      let isRed = roofZoneInfo?.zone==3 ;
      let isYellow = roofZoneInfo?.zone==2;
      return asce705and710MapColors(cursor, cursorColide, mapType, productId, isYellow, isRed);
    }
  }
  
  const shouldUseAsce705or710Map = isAsce705or710Map(mapType, cursorCollisionData) || (!isBlankMap(mapType) && isRMIFIProduct(products[productId]));
  if (cursorCollisionData && shouldUseAsce705or710Map) {
    let useNewZoneClassification = needNewZoneClassification(projectVersion);
    const isInYellowWindZone = isCloserFromEdgeThen(cursorCollisionData.closestDistancesFromEdges[0].dist, products[productId], metersPerPixel, useNewZoneClassification);
    const isInRedWindZone = isPanelOnCorner(cursorCollisionData.closestDistancesFromEdges, products[productId], metersPerPixel, useNewZoneClassification);
  
    if (shouldUseAsce705or710Map) {
      return asce705and710MapColors(cursor, cursorColide, mapType, productId, isInYellowWindZone, isInRedWindZone);
    }
  }

  if (isAsce705or710Blank(mapType) || shouldUseDesiredBuildingLengthWidth(mapType, products[productId], projectVersion)) {
    return asce705and710BlankColors(cursor, cursorColide, mapType, productId, false, false);
  }
}

const isAsce705or710Map = (mapType: string, cursorCollision: any) => !isASCE716or722Selected() && mapType !== 'white' && cursorCollision && cursorCollision.closestDistancesFromEdges.length;

const isAsce705or710Blank = (mapType: string) => !isASCE716or722Selected() && mapType === 'white';

const isAsce716CommercialsMap = (mapType: string, productId: number) => isASCE716or722Selected() && mapType !== 'white' && isCommercialProduct(productId);

const isAsce716CommercialsBlank = (mapType: string, productId: number) => isASCE716or722Selected() && mapType === 'white' && isCommercialProduct(productId);

export const isAsce716ResidentialsMap = (mapType: string, productId: number) => isASCE716or722Selected() && mapType !== 'white' && isResidentialProduct(productId);

export const isAsce716ResidentialsBlank = (mapType: string, productId: number) => isASCE716or722Selected() && mapType === 'white' && isResidentialProduct(productId);

export const isCursorInForbidenArea = (
  cursor: PIXI.Graphics,
  cursorColide: boolean,
  currentCursorColor: number,
  x: number,
  y: number,
  panelWidth: number,
  panelHeight: number,
  selectedRoofId: number,
  mapType: string,
  ) => {
  const { 
    background: { 
      roofEdges, 
      cords, 
      zoom, 
      metersPerPixel, 
      rotationDegrees, 
      bgXY,
      roofEdgesPixiCords,
    }, 
    projectConfiguration: { 
      productId,
      projectVersion,
      projectEnvConfig: {tilt}
    }, 
    tiltedRoof: { 
      roofPitch,
    },
   } = state();
  const insideOfPolygon = true;
  return (cursorColide
    || (
      (
        ((isRMAndEcofootFamily(productId) && !isASCE716or722Selected()) || isASCE716or722Selected())
        && !shouldUseSetBackDistance(mapType, productId, projectVersion)
      )
      && !isBlankMap(mapType)
      && checkCursorCollisionWithRestrictedZones(cursor, roofEdges, selectedRoofId, cords, zoom, metersPerPixel, rotationDegrees, bgXY.x, bgXY.y, panelWidth, panelHeight, productId, insideOfPolygon, roofPitch, roofEdgesPixiCords, mapType, tilt)
    )
    || isPanelCollideWithObstacles({ x, y, width: panelWidth, height: panelHeight }, selectedRoofId, bgXY.x, bgXY.y, panelWidth, panelHeight)
    || (
      shouldUseSetBackDistance(mapType, productId, projectVersion)
      && checkCursorCollisionWithSetBackDistance(cursor, roofEdges, selectedRoofId, cords, zoom, metersPerPixel, rotationDegrees, bgXY.x, bgXY.y, panelWidth, panelHeight, productId, insideOfPolygon, roofPitch, roofEdgesPixiCords, mapType, tilt)
    )
  );
};

export const isCursorInZone = (
  cursorColide: boolean,
  isInForbbidenZone: boolean,
  currentCursorColor: number,
  currentZone: number,
  lookingZone: number,
  snappingId: number,
) => {
  return (currentCursorColor !== snappingId || getLastSnapGroup() === null) &&
  currentCursorColor !== currentZone &&
  currentZone === lookingZone &&
  !cursorColide &&
  !isInForbbidenZone;
};

export const isCursorSnapping = (
  cursorColide: boolean,
  isInForbbidenZone: boolean,
  currentCursorColor: number,
  productId: string,
  snappingId: number,
) => {
  return !cursorColide &&
    getLastSnapGroup() !== null &&
    currentCursorColor !== snappingId &&
    // !isSFMFamily(products[productId]) && 
    !isInForbbidenZone;
};

// const isCursorInObstructionArea = (
//   cursorColide: boolean,
//   currentCursorColor: number,
//   snappingId: number,
//   cursor: any, 
// ) => {
//   return !cursorColide &&
//     isPanelCollideWithObstructionsZonesInPanelEditor(cursor) &&
//     getLastSnapGroup() !== null &&
//     currentCursorColor !== snappingId &&
//     (currentCursorColor !== snappingId || getLastSnapGroup() === null) &&
//     currentCursorColor !== OBSTRUCTION_ZONE;
// };

export const isCursorInRedZone = (
  cursorColide: boolean,
  isInRedWindZone: boolean,
  currentCursorColor: number,
) => {
  return !cursorColide &&
    isInRedWindZone &&
    currentCursorColor !== 2 &&
    (currentCursorColor !== 4 || getLastSnapGroup() === null);
};

export const isCursorInYellowZone = (
  cursorColide: boolean,
  isInYellowWindZone: boolean,
  currentCursorColor: number,
) => {
  return !cursorColide &&
    isInYellowWindZone &&
    currentCursorColor !== 1 &&
    (currentCursorColor !== 4 || getLastSnapGroup() === null);
};

export const isCursorInBlueZone = (
  cursorColide: boolean,
  isInYellowWindZone: boolean,
  isInRedWindZone: boolean,
  currentCursorColor: number,
  productId: string,
) => {
  return !cursorColide &&
    !isInYellowWindZone &&
    !isInRedWindZone &&
    (getLastSnapGroup() === null) &&
    currentCursorColor !== 0;
};
