import * as React from 'react';
import autobind from 'autobind-decorator';
import AppHistory from '__common/modules/history';
import BackButton from 'app/components/FooterComponent/BackButton';
import BomAssumptions from 'bom/components/bomAssumptions';
import Drawer from '__common/components/Drawer/Drawer';
import EngineeringProductDocuments from 'engineering/components/engineeringProductDocuments';
import EngineeringProjectReports from 'engineering/components/engineeringProjectReports';
import EngineeringProjectReportsMenu from 'engineering/components/engineeringProjectReportsMenu';
import Footer from 'app/components/FooterComponent';
import Header from 'app/components/HeaderComponent';
import HeaderMiddleBar from 'app/components/HeaderComponent/HeaderMiddleBar';
import Loader from '__common/components/LoaderComponent';
import OrderItButton from 'engineering/components/orderItButton';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { FETCH_STATIC } from '__common/components/FetchStatics/fetchStaticsActions';
import { footerHeight, headerHeight } from '__common/constants/layout_css_dims';
import { getEngineeringRMBallastMaps } from 'engineering/utils/engineeringRMLayouts';
import { getProductsDocuments } from 'engineering/utils/productsDocumentsMenu';
import { getProductTitle } from '__common/constants/products_titles';
import { isRMFamily, products, isSFMFamily, isSMFamily, isEcoFoot2Plus, isAscender, isRM10orRM10Evo, getProductName, isCanadianChangesProduct } from '__common/constants/products';
import { OPEN_FULL_REPORT, PROJECT_DOCUMENTS_RESET, SET_UNITS, TOGGLE_UNITS } from 'engineering/components/engineeringProjectDocuments/engineeringProjectDocumentsActions';
import {
  CLEAR_BOM_PK,
  FETCH_ENGINEERING_RAPORT,
  SET_PRODUCT_TITLE,
  SET_PROJECT_NAME,
  REFRESH_PDFS,
  DONT_GO_TO_ENGINEERING_FROM_BOM,
  COPY_PROJECT,
  DOWNLOAD_FULL_REPORT,
  SET_PRODUCT_ID,
} from 'actions';
import { getBomCSVName } from 'bom/utils/bomCSVNames';
import config from 'config';
import { doesPromotionApply, message as promotionMessage } from 'engineering/utils/promotion';
import EngineeringPromotionNotification from 'engineering/components/engineeringPromotionNotification';
import { getEngineeringArrayListMap } from 'engineering/utils/engineeringLayoutsSourceCode';
import { PSEUDO_STAFF_USER_PERMISSIONS } from 'user/userGroups';
import { isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';
import { check_sm_url_conversion_criteria } from './ProjectDesign';

type Props = {
  dispatch: any,
  match: any,
  engineering: engineeringState,
  projectName: string,
  user: { isStaff: boolean, permissions: string[] },
  railArrangementType: number,
  unitsType: unitsType,
  allowMechanicalAttachments: number,
  productId: number,
  zipcode: string,
  projectConfiguration : projectConfigurationState,
};
export enum SectionTitles {
  projectReports = 'Project Reports',
  engBallastMaps = 'Engineering Ballast Maps',
  moduleTestMaps = 'Module Test maps',
  engSourceCode = 'Engineering source code'
}

let default_unit_set = false;

class Engineering extends React.Component<Props, {}> {
  refreshPdfTimout: ReturnType<typeof setTimeout>;

  UNSAFE_componentWillMount() {
    const { dispatch, match: { params: { projectId } } } = this.props;
    default_unit_set = false;
    dispatch(FETCH_ENGINEERING_RAPORT(projectId));
    dispatch(CLEAR_BOM_PK());
    dispatch(DONT_GO_TO_ENGINEERING_FROM_BOM());
  }

  shouldShowPromotion() {
    if (this.props.engineering) {
      const { engineering: { projectInfo: { total_watts } } } = this.props;
      return doesPromotionApply(total_watts);
    }

    return false;
  }

  componentDidUpdate(oldProps: Props) {
    const { dispatch, match: { params: { productId } }, projectName, zipcode } = this.props;
    if (this.props.engineering?.product && !(check_sm_url_conversion_criteria(productId,this.props.engineering.product) )
      && getProductName(this.props.engineering.product) != productId) {
      dispatch(SET_PRODUCT_ID(this.props.engineering.product));
      AppHistory.push('/projectNotFound');
    }

    if(!default_unit_set && zipcode !== undefined) {
      default_unit_set = true;
      if(isCanadianChangesProduct(products[productId]) && isCanadianZipcode(zipcode)) {
        dispatch(SET_UNITS('metric'));
      }
    }

    if (oldProps.engineering === null && this.props.engineering !== null && !this.props.engineering.projectInfo && this.props.engineering.projectInfo.custom_title) {
      dispatch(SET_PROJECT_NAME(this.props.engineering.projectInfo.custom_title));
    }

    if (oldProps.engineering === null && this.props.engineering !== null) {
      const id = this.props.engineering.is_sm_tilt ? 99 : products[productId];
      dispatch(SET_PRODUCT_ID(id));
      dispatch(SET_PRODUCT_TITLE(getProductTitle(id)));
    }

    if (projectName !== oldProps.projectName) {
      clearTimeout(this.refreshPdfTimout);
      this.refreshPdfTimout = setTimeout(() => {
        this.refreshPdfs();
      }, 2000);
    }
  }

  isSfmAsce716 = () => {
    const { match: { params: { productId } }, engineering: { is_ASCE716or722 } } = this.props;

    return isSFMFamily(products[productId]) && is_ASCE716or722;
  }

  isSMAsce716 = () => {
    const { match: { params: { productId } }, engineering: { is_ASCE716or722 } } = this.props;

    return isSMFamily(products[productId]) && is_ASCE716or722;
  }

  refreshPdfs = () => {
    const { dispatch } = this.props;
    dispatch(REFRESH_PDFS());
  }

  renderRightSiteInfoBar() {
    if (!this.props.engineering?.projectInfo) {
      return null;
    }
    const { match: { params: { projectId } }, engineering: { projectInfo: { zipcode, total_modules, total_watts } } } = this.props;
    return (
      <div className="project-info">
        <div className="info-item">
          <div className="title">project id</div>
          <div className="value">{projectId}</div>
        </div>
        <div className="info-item">
          <div className="title">zipcode</div>
          <div className="value">{zipcode}</div>
        </div>
        <div className="info-item">
          <div className="title">{total_modules} modules</div>
          <div className="value"> {(total_watts / 1000).toFixed(2)} KW</div>
        </div>
      </div>
    );
  }

  renderInfoBar() {
    const { match: { params: { projectId } } } = this.props;
    return (<HeaderMiddleBar projectId={projectId} rightSiteComponent={this.renderRightSiteInfoBar()} />);
  }

  @autobind
  renderFooter() {
    const { dispatch, match: { params: { projectId, productId } } } = this.props;
    const url = `/project/bom/${productId}/${projectId}`;
    return (
      <div style={{ display: 'flex' }}>
        <BackButton url={url} dispatch={dispatch} action={CLEAR_BOM_PK} />
        <OrderItButton projectId={projectId} />
      </div>
    );
  }

  renderProjectDocuments = () => {
    const { dispatch, match: { params: { projectId, productId } }, unitsType, engineering: { arrays } } = this.props;
    const ascender_structure_types = isAscender(products[productId]) ? Array.from(new Set(arrays.map(array => array.structure_type))) : [];
    return (
      <div className="project-documents">
        <EngineeringProjectReports dispatch={dispatch} projectId={projectId} productId={productId} unitsType={unitsType} />

        <EngineeringProductDocuments productId={products[productId]} ascender_structure_types={ascender_structure_types} projectConfiguration={this.props.projectConfiguration} />
        <BomAssumptions />
      </div>
    );
  }

  fetchBom = () => {
    const { dispatch, match: { params: { projectId, productId } } } = this.props;
    dispatch(
      FETCH_STATIC(`${productId.toLocaleUpperCase()}_${getBomCSVName(projectId)}.csv`, 'text/csv', `download/bom/`, { projectId }),
    );
  }

  printAllReports = () => {
    const { dispatch, match: { params: { projectId, productId } }, unitsType } = this.props;
    dispatch(PROJECT_DOCUMENTS_RESET());
    dispatch(
      // OPEN_STATIC
      OPEN_FULL_REPORT(projectId, productId, unitsType),
    );
  }

  fetchFullRaport = () => {
    const { dispatch, match: { params: { projectId, productId } }, unitsType } = this.props;
    dispatch(PROJECT_DOCUMENTS_RESET());
    dispatch(
      DOWNLOAD_FULL_REPORT(projectId, productId, unitsType),
    );
  }

  openASCE716CalculationsOutput = () => {
    const { match: { params: { projectId } } } = this.props;
    window.open(`${location.protocol}//${config.apiUrl}api/v1/asce716_extra_outputs/${projectId}`, '_blank');

  }

  copyProject = () => {
    const { dispatch, match: { params: { projectId } } } = this.props;
    dispatch(COPY_PROJECT(projectId));
  }

  toggleUnits = () => {
    const { dispatch } = this.props;
    dispatch(TOGGLE_UNITS());
  }

  getConvertUnitsTooltip() {
    const { unitsType, productId } = this.props;
    if (isAscender(productId)) {
      return (
        unitsType === 'customary' ?
          'Convert units to U.S. customary units' :
          'Convert units to metric'
      );
    }
    return (
      unitsType === 'customary' ?
        'Convert units to metric' :
        'Convert units to U.S. customary units'
    );
  }

  shouldShowAdditionalBallastMaps = () => {
    const { match: { params: { productId } }, user: { isStaff, permissions } } = this.props;

    return (isRMFamily(products[productId]) || isEcoFoot2Plus(products[productId])) && (isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.engineering_layouts));
  }

  shouldShowModuleTestMaps = () => {
    const { match: { params: { productId } }, user: { isStaff, permissions } } = this.props;

    return (isEcoFoot2Plus(products[productId]) || isRM10orRM10Evo(products[productId])) && (isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.engineering_layouts));
  }

  shouldShowSourceCodeSection = () => {
    const { match: { params: { productId } }, user: { isStaff, permissions } } = this.props;
    return isSMFamily(products[productId]) && (isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.source_code));
  }

  shouldShowSourceCodeLinkInBallastMaps = () => {
    const { match: { params: { productId } }, user: { isStaff, permissions } } = this.props;
    return (isRMFamily(products[productId]) || isEcoFoot2Plus(products[productId])) && (isStaff || permissions.includes(PSEUDO_STAFF_USER_PERMISSIONS.source_code));
  }

  render() {
    const { engineering, match: { params: { projectId, productId } }, user: { isStaff }, dispatch, railArrangementType, allowMechanicalAttachments, projectConfiguration} = this.props;

    if (!engineering || (engineering && Object.keys(engineering).length === 0) || !engineering.compilantLetter) {
      return (
        <>
          <Header navigation={true} Infobar={this.renderInfoBar()} projectId={projectId} productId={productId} />
          <Loader />
          <Footer ContainerFooter={this.renderFooter} />
        </>
      );
    }
    const ascender_structure_types = isAscender(products[productId]) ? Array.from(new Set(engineering.arrays.map(array => array.structure_type))) : [];
    const documentsPage = getProductsDocuments(productId, projectId, isStaff, railArrangementType, ascender_structure_types, projectConfiguration);
    const engineering_layout_flags = engineering?.engineering_layout_flags ? engineering.engineering_layout_flags : []
    const engineeringLayouts = getEngineeringRMBallastMaps(engineering.arrays, products[productId], projectId, engineering.is_ASCE716or722, allowMechanicalAttachments, engineering_layout_flags);
    const moduleTestMaps = getEngineeringRMBallastMaps(engineering.arrays, products[productId], projectId, engineering.is_ASCE716or722, allowMechanicalAttachments, engineering_layout_flags, true);
    const engineeringArrayList = getEngineeringArrayListMap(engineering.arrays, products[productId], projectId);

    const drawerPages = [
      {
        page: 'Project reports',
        icon: <SVG src={require('assets/media/icons/sidebar_documents.svg')} />,
        content: (
          <EngineeringProjectReportsMenu
            projectId={projectId}
            productId={productId}
            projectArrays={engineering.arrays}
            dispatch={dispatch}
            sectionTitle={SectionTitles.projectReports}
            pages={documentsPage}
            is_ASCE716or722={engineering.is_ASCE716or722}
            projectRoofs={engineering.roofs}
          />
        ),
        postRender: undefined,
        active: true,
        tooltip: undefined,
      },
      {
        page: 'Engineering layouts',
        icon: <SVG src={require('assets/media/icons/icon_blocks-1.svg')} />,
        content: (
          <EngineeringProjectReportsMenu
            projectId={projectId}
            productId={productId}
            projectArrays={engineering.arrays}
            dispatch={dispatch}
            sectionTitle={SectionTitles.engBallastMaps}
            pages={engineeringLayouts}
            is_ASCE716or722={engineering.is_ASCE716or722}
            projectRoofs={engineering.roofs}
            shouldShowSourceCodeLink={this.shouldShowSourceCodeLinkInBallastMaps()}
          />
        ),
        postRender: undefined,
        active: false,
        disabled: !this.shouldShowAdditionalBallastMaps(),
        tooltip: 'ENGINEERING LAYOUTS',
      },
      {
        page: 'Module Test Maps',
        icon: <SVG src={require('assets/media/icons/module-test-map.svg')} />,
        content: (
          <EngineeringProjectReportsMenu
            projectId={projectId}
            productId={productId}
            projectArrays={engineering.arrays}
            dispatch={dispatch}
            sectionTitle={SectionTitles.moduleTestMaps}
            pages={moduleTestMaps}
            is_ASCE716or722={engineering.is_ASCE716or722}
            projectRoofs={engineering.roofs}
          />
        ),
        postRender: undefined,
        active: false,
        disabled: !this.shouldShowModuleTestMaps(),
        tooltip: 'MODULE TEST MAPS',
      },
      {
        page: 'Engineering Source Code',
        icon: <SVG src={require('assets/media/icons/icon_blocks-1.svg')} />,
        content: (
          <EngineeringProjectReportsMenu
            projectId={projectId}
            productId={productId}
            projectArrays={engineering.arrays}
            dispatch={dispatch}
            sectionTitle={SectionTitles.engSourceCode}
            pages={engineeringArrayList}
            is_ASCE716or722={engineering.is_ASCE716or722}
            projectRoofs={engineering.roofs}
          />
        ),
        postRender: undefined,
        active: false,
        disabled: !this.shouldShowSourceCodeSection(),
        tooltip: 'ENGINEERING LAYOUT SOURCE CODE',
      },
      {
        page: 'Convert units',
        icon: <SVG src={require('assets/media/icons/icon_metrics.svg')} />,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: this.getConvertUnitsTooltip(),
        onClick: this.toggleUnits,
      },
      {
        page: 'Copy project',
        icon: <div className="material-icons" style={{ color: 'white' }}>content_copy</div>,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: 'COPY PROJECT',
        onClick: this.copyProject,
      },
      {
        page: 'Download bom pdf',
        icon: <SVG src={require('assets/media/icons/sidebar_download_bom.svg')} />,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: 'DOWNLOAD BOM',
        onClick: this.fetchBom,
      },
      {
        page: 'Download all pdfs',
        icon: <SVG src={require('assets/media/icons/icon_download.svg')} />,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: 'DOWNLOAD ALL',
        onClick: this.fetchFullRaport,
      },
      {
        page: 'Print all pdfs',
        icon: <SVG src={require('assets/media/icons/icon_print.svg')} />,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: 'PRINT ALL',
        onClick: this.printAllReports,
      },
    ];

    if (this.isSfmAsce716() || this.isSMAsce716()) {
      drawerPages.push({
        page: 'ASCE716 Calculations Output',
        icon: <div className="material-icons" style={{ color: 'white' }}>help_outline</div>,
        content: null,
        postRender: undefined,
        active: false,
        tooltip: 'SHOW ASCE716 CALCULATIONS OUTPUT',
        onClick: this.openASCE716CalculationsOutput,
      });
    }

    const offsetHeight = document.body ? document.body.offsetHeight : 0;
    const drawerHeight = offsetHeight - headerHeight - footerHeight;
    
    return (
      <div className="engineering-page">
        <Header navigation={true} Infobar={this.renderInfoBar()} projectId={projectId} productId={productId} />
        <div className="engineering-page-content">
          <Drawer keepOpen={true} productId={products[productId]} pages={drawerPages} drawerHeight={drawerHeight} />
          <div className={'engineering-promotion-container'}>
            <EngineeringPromotionNotification
              canBeApplied={this.shouldShowPromotion()}
              message={promotionMessage}
            />
          </div>
        </div>
        <Footer ContainerFooter={this.renderFooter} />
      </div>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    engineering: state.engineering,
    projectName: state.projectConfiguration.projectName,
    railArrangementType: state.projectConfiguration.projectEnvConfig.rail_arrangement_type,
    user: state.user,
    unitsType: state.engineeringProjectDocuments.units,
    allowMechanicalAttachments: state.projectConfiguration.projectEnvConfig.allow_mechanical_attachments ?? state.engineering?.projectInfo?.allow_mechanical_attachments,
    productId: state.projectConfiguration.productId,
    zipcode: state.projectConfiguration.projectEnvConfig.zipcode ?? state.engineering?.projectInfo?.zipcode,
    projectConfiguration :  state.projectConfiguration,
  };
}

export default connect(mapStateToProps)(Engineering);

