import { Action } from '__common/store/action';
import { ActionsObservable } from 'redux-observable';
import { AnyAction, Store } from 'redux';
import { PanelsActionTypes } from '__editor/panelsEditor/components/panels/panelsActionsConstants';
import { CursorActionTypes } from 'actionsConstants';
import { 
  ADD_PANELS_ACTION, 
  ADD_PANELS, 
  UPDATE_PANELS, 
  REMOVE_PANELS_ACTION, 
  LOAD_PANELS_ACTION, 
  REQUEST_ADD_PANELS_ACTION,
  CHANGE_DESIRED_TABLE_WIDTH,
  SET_PANELS_ARRAY_GRID_FOR_MOVEMENT,
  RESET_PANELS_ARRAY_FOR_MOVEMENT,
  REQUEST_ADD_PANELS,
  REMOVE_PANELS,
} from './panelsActions';
import { isGroundProduct, isRMFamily, isULA, isRMIFIProduct, isEcoFoot2Plus, isMetalX, isSolarMount, isRM10, isRM10Evolution, isNxtHorizon, isGFT, isRM5, isRMDT, isSMTiltPR, isAscender, isRmGridflex10, isRMGridFlex } from '__common/constants/products';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import { 
  CheckingImportedRoofStatus,
  createPanelArrayGrid,
  getPanelCollisionBounds, 
  getPanelRoofZoneForVirtualEdges,
  isPanelInsideOfRoofEdgesMemoized as isPanelInsideOfRoofEdges,
  openConfirmationModalForOverflowingModules,
} from './panels';
import { removePanelFromRtree, insertPanelIntoRtree } from './panelsCollisions';
import { gapLengthMeters } from './utils/drawGftPanelsInTables';
import sortBy from 'lodash/sortBy';
import { resetKdTree } from '__editor/panelsEditor/components/cursor/utils/kdTreeStore';
import { SAVE_ROOF_EDGES_POINTS } from '../background/backgroundActions';
import { getPanelsRange, arePanelsWithinRoofEdges } from './utils/calculatePanelsRange';
import { getOffsetInPixels } from './utils/rmOnBlankDesign';
import { shouldUpdateRoofEdgesOnPanelChange, shouldUseDesiredBuildingLengthWidth, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { dispatch } from '__common/store';
import { removePanelsWithNoSiblings, filterPanels } from './utils/panelsManagment';
import { checkObstructionZoneForPanelsInPanelEditor } from '../obstructions/obstructionsHelper';
import { recalculatePanelZones, recalculateRmZones } from './utils/recalculateZones';
import { isASCE716or722 } from '__common/constants/buildingCodes';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { _isULA } from '../cursor/utils/snapToGridHelper';
import { SET_TABLE_ROW_SPACING } from '../settings/settingsActions';
import { getTiltedModuleTableRowSpacings } from '../tiltedModule/tiltedModule';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { constructGraph, splitArraysIntoSubarrays } from './utils/dividePanelsintoSubArrays';
import _ from 'lodash';
import { degreesToRadians, inchesToMeters } from 'maths';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, OPEN_DRAWER_PAGE, SET_PANELS_IN_OVERFLOWING_STATE, SET_ROOF_AREA_SEEN, SET_USER_CLICKED_YES_OR_NO } from 'actions';
import { getDetectedModuleSpacing, traversePanelsGraphAndUpdateSpacings } from './utils/panelSpacingsUpdate';
import { getPanelBounds, getRoofBoundsMemoized as getRoofBounds } from '../roofEdges/roofEdgesCollisions';
import { checkPanelCollisionWithSetBackDistance } from '../roofZones/utils/setBackDistanceCollisionDetections';

export function calculateWhichPanelsCanBeAdded(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(PanelsActionTypes.REQUEST_ADD_PANELS)
    .switchMap((action: Action<REQUEST_ADD_PANELS_ACTION>) => {
      const {
        panels: {
          panels: existingPanels,
        },
        projectConfiguration: {productId},
      } = store.getState();
      const filteredPanels = filterPanels(action.payload.panels);
      const panels = [...existingPanels, ...filteredPanels];
      const panelsWithCollisionWithObstruction = checkObstructionZoneForPanelsInPanelEditor(panels);
      const panelsOnlyWithSiblings = removePanelsWithNoSiblings(panelsWithCollisionWithObstruction);
      if(isAscender(productId)) {
        dispatch(OPEN_DRAWER_PAGE('module selection'));
      }
      return of(ADD_PANELS(panelsOnlyWithSiblings));
    });
}

export function updateRestrictedAreaWhenPanelsAdded(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(PanelsActionTypes.ADD_PANELS, PanelsActionTypes.LOAD_PANELS, PanelsActionTypes.RESTORE_PANELS_COLLISIONS_BOUNDS)
  .filter((_) => isGroundProduct(store.getState().projectConfiguration.productId))
    .switchMap((action: Action<ADD_PANELS_ACTION>) => {
      const groups = groupBy<panelInState>(store.getState().panels.panels, (p: panelInState): number => p.groupId);
      const updatedPanels = flatten(Object.entries(groups).map(([groupId, panelsInGroup]) => {
        const panelsSortedByX = sortBy(panelsInGroup, (panel: panelInState) => panel.x);
        const { projectConfiguration: { projectEnvConfig: { tilt } } } = store.getState();

        // if the table reached a max available length we need to add gaps on each side of it, as we do when 
        // drawing with rubber band (in order to prevent creating 30+ pairs tables).

        // known issue #1 when you add 29 panel pairs using rubber band,
        // you can then add another 29 panel pairs using rubber band when you start snapping to the last panel.
        if (panelsSortedByX && panelsSortedByX.length) {
          const {
            background: { metersPerPixel }, 
            settings: { columnSpacing, rowSpacing, tableRowSpacing },
            projectConfiguration: { productId }
          } = store.getState();
          const restrictedAreaUpdatedPanels = panelsSortedByX.map((panel) => {
            const _tableRowSpacing = isGFT(productId)? tableRowSpacing : getTiltedModuleTableRowSpacings( tilt, panel.tableHeight);
            const _effective_row_spacing = isGFT(productId)? 0 : rowSpacing;
            removePanelFromRtree(panel.rTreeBounds);
            panel.rTreeBounds = getPanelCollisionBounds(panel, _effective_row_spacing, columnSpacing, metersPerPixel);
            getPanelCollisionBounds(panel, rowSpacing, columnSpacing, metersPerPixel);
            panel.rTreeBounds.minX -= (gapLengthMeters / metersPerPixel);
            panel.rTreeBounds.minY -= (_tableRowSpacing / metersPerPixel);
            panel.rTreeBounds.maxX += (gapLengthMeters / metersPerPixel);
            panel.rTreeBounds.maxY += (_tableRowSpacing / metersPerPixel);
            insertPanelIntoRtree(panel.rTreeBounds);
            return panel;
          });
          return restrictedAreaUpdatedPanels;
        }

        return panelsSortedByX;
      }));

      return of(UPDATE_PANELS(updatedPanels));
    });
}


export function resetKdTreeStoreOnRoofCleared(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(PanelsActionTypes.RESET_PANELS_STATE)
    .mergeMap(() => {
      // reset kdTree to avoid snapping to already deleted modules.
      resetKdTree();
      return Observable.empty<never>();
    });
}

export function resetVirtualRoofEdgesOnResetPanelState(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(PanelsActionTypes.RESET_PANELS_STATE)
    .filter((action) => {
      const { 
        projectConfiguration: { 
          productId,
          projectVersion,
        },
        roofsSelector: {
          mapType,
        },
      } = store.getState();
  
      return shouldUpdateRoofEdgesOnPanelChange(mapType, productId, projectVersion);
    })
    .switchMap(() => of(SAVE_ROOF_EDGES_POINTS([])));
}

const getCurrentRoofId = (store: Store<appState>): number => store.getState().background.selectedRoofId;

const onlyAsce716RMproducts = (store: Store<appState>) => (action: AnyAction): boolean => {
  const {
    projectConfiguration: { 
      productId,
      projectEnvConfig: {
        building_code,
      },
    },
  } = store.getState();

  return ((isRMFamily(productId) && isASCE716or722(building_code) && !isRMIFIProduct(productId)) || isRM10(productId) || isEcoFoot2Plus(productId) || isRM10Evolution(productId) || isRM5(productId) || isRmGridflex10(productId));
};

const onlyAsce716RMDTProduct = (store: Store<appState>) => (action: AnyAction): boolean => {
  const {
    projectConfiguration: { 
      productId,
    },
  } = store.getState();

  return isRMDT(productId);
};

export function setRoofEdgesForRMIFIBlankDesign(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(
    PanelsActionTypes.LOAD_PANELS,
  ).filter(() => {
    const {
      projectConfiguration: {
        productId,
        projectVersion
      },
      roofsSelector: {
        mapType,
      },
    } = store.getState();

    return (
      shouldUseDesiredBuildingLengthWidth(mapType, productId, projectVersion)
    );
  }).switchMap((action) => {

    const { background: { metersPerPixel, blank_map_building_length: length, blank_map_building_width: width, bgScale } } = store.getState();

    if (length && width) {
      let lengthPixels = feetsToMeters(length) / metersPerPixel;
      let widthPixels = feetsToMeters(width) / metersPerPixel;
      lengthPixels /= bgScale.x;
      widthPixels /= bgScale.x;
      const newRoofEdges: pixelPoint[] = [
        { x: -widthPixels/2, y: lengthPixels/2 },
        { x: widthPixels/2, y: lengthPixels/2 },
        { x: widthPixels/2, y: -lengthPixels/2 },
        { x: -widthPixels/2, y: -lengthPixels/2 },
      ];
      return(of(SAVE_ROOF_EDGES_POINTS(newRoofEdges)));
    }
    return Observable.empty();
  });
}

const getPanels = (store: Store<appState>) => (action: Action<ADD_PANELS_ACTION|REMOVE_PANELS_ACTION|LOAD_PANELS_ACTION>): panelInState[] => {
  const { 
    panels: {
      panels: _panels,
    },
  } = store.getState();
  return _panels;
};

export function updateRoofEdgesForRMBlankDesign(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(
    PanelsActionTypes.ADD_PANELS, 
    PanelsActionTypes.REMOVE_PANELS, 
    PanelsActionTypes.LOAD_PANELS,
  ).filter(() => {
    const { 
      projectConfiguration: { 
        productId,
        projectVersion,
      },
      roofsSelector: {
        mapType,
      },
      panels: {
        panels,
      },
    } = store.getState();

    return (
      shouldUpdateRoofEdgesOnPanelChange(mapType, productId, projectVersion) &&
      panels.length > 0
    );
  })
  .filter((action) => {
    // it doesn't make sense to recalculate the whole range
    // if the new panels are within existing range.
    const { 
      background: { 
        roofEdgesPixiCords,
        metersPerPixel,
      }, 
      projectConfiguration: {
        productId,
        projectEnvConfig : {
          setback_distance,
        },
      },
    } = store.getState();

    const offset = getOffsetInPixels(metersPerPixel, productId, setback_distance );
    const shouldRecalculateRange = !(
      arePanelsAdded(action) && 
      arePanelsWithinRoofEdges(roofEdgesPixiCords, action.payload.panels, offset)
    ); 

    return shouldRecalculateRange;
  }).switchMap((action) => {
    const { 
      background: { 
        bgScale, 
        metersPerPixel,
      }, 
      panels: { 
        panels,
      },
      projectConfiguration: {
        productId,
        projectEnvConfig : {
          setback_distance,
        },
      },
    } = store.getState();

    const offset = getOffsetInPixels(metersPerPixel, productId, setback_distance );

    const { minX, minY, maxX, maxY } = getPanelsRange(<panelInState[]>panels);

    const makePoint = (x: number, y: number): pixelPoint => ({
      // background is drawn on a different layer than panels
      // with different scale.
      x: x / bgScale.x, 
      y: y / bgScale.y,
    });
    const newRoofEdges: pixelPoint[] = [
      makePoint(minX - offset, minY - offset),
      makePoint(maxX + offset, minY - offset),
      makePoint(maxX + offset, maxY + offset),
      makePoint(minX - offset, maxY + offset),
    ];
    // We have to update roof edges BEFORE we recalculate roof zones.
    // Otherwise, they would be calculated according to the old edges.
    dispatch(SAVE_ROOF_EDGES_POINTS(newRoofEdges));

    if(isRMIFIProduct(productId)){
      return of(UPDATE_PANELS(panels));
    }

    const updatedPanels = (<panelInState[]>panels).map((panel) => {
      const roofZone = getPanelRoofZoneForVirtualEdges(panel);
      panel.roofZone = roofZone.zone;
      panel.edgeType = roofZone.edgeType;
      return panel;
    });

    return of(UPDATE_PANELS(updatedPanels));
  });
}

function arePanelsAdded(action: AnyAction): action is Action<ADD_PANELS_ACTION> {
  return action.type === PanelsActionTypes.ADD_PANELS;
}

export function checkTableWidthBounds(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(CursorActionTypes.FLIP_CURSOR)
  .filter(() => {
    const { editorCursor: { landscape }, panels: { desiredTableWidth } } = store.getState();
    if(_isULA()){
      let widthInBound = true;
      if(landscape && desiredTableWidth >= 3 && desiredTableWidth <= 5){
        widthInBound = false;
      } else {
        if(!landscape && desiredTableWidth>=1 && desiredTableWidth<=3){
          widthInBound = false;
        }
      }
      return widthInBound;
    }
  }).switchMap((action)=>{
    return of(CHANGE_DESIRED_TABLE_WIDTH(null));
  });
}

export function UpdateTableRowSpacingBasedOnTableHeight(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(PanelsActionTypes.CHANGE_DESIRED_TABLE_WIDTH, CursorActionTypes.FLIP_CURSOR)
  .filter(() => {
    const { projectConfiguration: { productId } } = store.getState();
    return isULA(productId);
  }).switchMap(()=>{
    const { projectConfiguration: { projectEnvConfig: { tilt } }, panels: { desiredTableWidth } } = store.getState();
    const tableRowSpacing = getTiltedModuleTableRowSpacings(tilt, desiredTableWidth);
    return of(SET_TABLE_ROW_SPACING(tableRowSpacing));
  });
}

export function getUpdatedPanelList(panels: panelInState[], rowSpacingPx, columnSpacingPx) {
  if (!panels || panels.length === 0) {
    return [];
  }
  const panels_ = [...panels];
  const graph = constructGraph(panels, rowSpacingPx, columnSpacingPx);
  const visited = splitArraysIntoSubarrays(graph);
  panels_.forEach(p=>{
    p.groupId = visited[p.id];
  });
  return panels_;
}

export function getUpdatedPanelListForAscender(panels: panelInState[], rowSpacingPx, columnSpacingPx) {
  if (!panels || panels.length === 0) {
    return [];
  }
  const panels_ = [...panels];
  const panelConfig = panels_[0].panelConfig;
  
  let visited = {}; 
  if (panelConfig === 1 || panelConfig === 2) {
    const graph = constructGraph(panels, rowSpacingPx, columnSpacingPx);
    visited = splitArraysIntoSubarrays(graph); }
  else {
    panels_.forEach(p=>visited[p.id]=p.groupId);
  }

  panels_.forEach(p=>{
    p.groupId = visited[p.id];
  });
  return panels_;
}


const shouldUpdatePanelGroupIdsToReflectActualGroups = (productId: number, tilt: number) => {
  return isMetalX(productId) || (isSolarMount(productId) && !(tilt || tilt === 0)) || 
    isEcoFoot2Plus(productId) || isRM10Evolution(productId) || isRM10(productId) || isNxtHorizon(productId) || isRM5(productId) || isSMTiltPR(productId)  ||  isAscender(productId) || isRmGridflex10(productId) || isRMGridFlex(productId);
};



export function updatePanelGroupIdsToReflectActualGroups(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(PanelsActionTypes.ADD_PANELS, PanelsActionTypes.REMOVE_PANELS,PanelsActionTypes.LOAD_PANELS, PanelsActionTypes.UPDATE_PANELS)
  .filter((action) => {
    const { projectConfiguration: { productId, projectEnvConfig: { tilt } } } = store.getState();
    return (action.type !== PanelsActionTypes.UPDATE_PANELS || action.payload?.type?.type === 'updateGroups') && shouldUpdatePanelGroupIdsToReflectActualGroups(productId, tilt);
  }).switchMap((action)=>{
    const { panels: { panels }, background: { metersPerPixel }, settings: { rowSpacing, columnSpacing }, projectConfiguration: { productId }} = store.getState();
    let panels_ = [];
    const [rowSpacingPx, columnSpacingPx] = [rowSpacing/metersPerPixel, columnSpacing/metersPerPixel];
    if (panels.length > 0){
      if (isAscender(productId)) {
        const panelsOnePType = panels.filter((p:panelInState) => p.panelConfig === 1 || p.panelConfig === 2);
        const panelsTwoPType = panels.filter((p:panelInState) => p.panelConfig === 3 || p.panelConfig === 4);
        panels_ = getUpdatedPanelListForAscender(panelsOnePType, rowSpacingPx, columnSpacingPx);
        panels_ = panels_.concat(...getUpdatedPanelListForAscender(panelsTwoPType, rowSpacingPx, columnSpacingPx));
      } else {
      const panelsLandscape = panels.filter((p:panelInState) => p.landscape);
      const panelsPortrait = panels.filter((p:panelInState) => !p.landscape);
      panels_ = getUpdatedPanelList(panelsLandscape, rowSpacingPx, columnSpacingPx);
      panels_ = panels_.concat(...getUpdatedPanelList(panelsPortrait, rowSpacingPx, columnSpacingPx));
    }
    }
    return of(UPDATE_PANELS(panels_, {type: 'groupId'}));
  });
}

export function updatePanelSpacings(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any>{
  return action$.ofType(PanelsActionTypes.UPDATE_PANEL_SPACINGS)
  .filter(() => {
    const { projectConfiguration: { productId, projectEnvConfig: { tilt } } }  = store.getState();
    return (isSolarMount(productId) && !(tilt || tilt === 0)) || isEcoFoot2Plus(productId) || isRM10Evolution(productId) || isRM10(productId) || isNxtHorizon(productId) || isRM5(productId);
  }).switchMap((action)=>{
    const { panels: { panels }, background: { metersPerPixel }, settings: { rowSpacing: stateRowSpacing, columnSpacing: stateColumnSpacing } } = store.getState();
    const { rowSpacingInches, columnSpacingInches } = action.payload;
    const [rowSpacing, columnSpacing] = [inchesToMeters(rowSpacingInches), inchesToMeters(columnSpacingInches)]
    let panels_ = [...panels];
    const [rowSpacingPx, columnSpacingPx] = [rowSpacing/metersPerPixel, columnSpacing/metersPerPixel];

    if (panels.length > 0){

      const panelsLandscape = panels.filter((p:panelInState) => p.landscape);      
      const panelsPortrait = panels.filter((p:panelInState) => !p.landscape);
      
      const { detectedRowSpacingPx, detectedColumnSpacingPx } = getDetectedModuleSpacing({ panels, stateRowSpacing, stateColumnSpacing, metersPerPixel });

      const graphLandscape = constructGraph(panelsLandscape, detectedRowSpacingPx, detectedColumnSpacingPx);
      const graphPortrait = constructGraph(panelsPortrait, detectedRowSpacingPx, detectedColumnSpacingPx);
      
      const visited = {};
      const panelIdMap = {};
      panels_.forEach(p=>panelIdMap[p.id] = p);
      panels_.forEach(p=>visited[p.id]=0);

      graphLandscape.forEach(p=>!visited[p.id] && traversePanelsGraphAndUpdateSpacings({panel: p, rowSpacingPx, columnSpacingPx, panelIdMap, visited}));
      graphPortrait.forEach(p=>!visited[p.id] && traversePanelsGraphAndUpdateSpacings({panel: p, rowSpacingPx, columnSpacingPx, panelIdMap, visited}));
      
      return of(UPDATE_PANELS(panels_, {type: 'updateGroups'}));
    }
    return Observable.empty();
  });
}


export function detectInvalidPanels(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(PanelsActionTypes.UPDATE_PANELS)
  .filter((action) => {
    const { projectConfiguration: { projectEnvConfig: { helioscope_id }, productId } } = store.getState();
    return helioscope_id && (action.payload?.type?.type === 'roofZone' || action.payload?.type?.type === 'groupId' || isRMDT(productId));
  })
  .switchMap((action)=>{
    const {
      background: {
        roofEdges,
        cords,
        zoom,
        rotationDegrees,
        bgXY,
        roofEdgesPixiCords,
        selectedRoofId,
        metersPerPixel,
      },
      tiltedRoof: {
        roofPitch,
      },
      panels: { panels },
      roofsSelector: {mapType},
      projectConfiguration: {productId, projectEnvConfig: {tilt}, projectVersion},
      settings: {
        panelWidth,
        panelHeight,
      },
      drawingManager:{roofs}
    } = store.getState();

    let totalPanels = 0;
    const panelIdsToRemove = [];
    const roofBound = getRoofBounds({roofEdges, roofCenter: cords, roofEdgesPixiCords,  bgOffSet: {x: bgXY.x, y: bgXY.y}, zoom, rotationRadians:degreesToRadians(rotationDegrees), roofPitch, tilt, productId, mapType});
    for (let i=0; i<panels.length; i++) {
      const panel = panels[i];
      const panelBound = getPanelBounds(panel, true, bgXY.x, bgXY.y);
      const isPanelInsideRoof = isPanelInsideOfRoofEdges(
        {panelBound,
        roofBound,
        mapType, productId}
      );
      const isPanelCollidesSetbackDistance = shouldUseSetBackDistance(mapType, productId, projectVersion) ? checkPanelCollisionWithSetBackDistance(
        panel,
        true,
        roofEdges,
        selectedRoofId,
        cords,
        zoom,
        metersPerPixel,
        rotationDegrees,
        bgXY.x,
        bgXY.y,
        panelWidth,
        panelHeight,
        productId,
        true,
        roofPitch,
        roofEdgesPixiCords,
        mapType,
        tilt,
      ) : false;
      const isPanelColliding = !isPanelInsideRoof || isPanelCollidesSetbackDistance;
      if (isPanelColliding) { 
        totalPanels++;
        panelIdsToRemove.push(panel.id);
      }
    }

    if(totalPanels){
      dispatch(SET_PANELS_IN_OVERFLOWING_STATE(selectedRoofId, true, totalPanels));
    }
    
    if (totalPanels && window.location.href.includes("project/design") && roofs[selectedRoofId].isConfirmedYesorNo === true) {
      openConfirmationModalForOverflowingModules(totalPanels, panelIdsToRemove, selectedRoofId);
    }
    else if(!totalPanels && panels.length > 0){
      dispatch(SET_ROOF_AREA_SEEN(selectedRoofId, true));
      CheckingImportedRoofStatus();  
    }
    return Observable.empty();

  });
}


export function updateRMDTPanelZone(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(
    PanelsActionTypes.ADD_PANELS,
    PanelsActionTypes.REMOVE_PANELS,
    PanelsActionTypes.LOAD_PANELS,
  ).filter( onlyAsce716RMDTProduct( store ) ).map( getPanels( store ) ).switchMap( ( panels ) => { 
    const {
      projectConfiguration: {
        productId,
      },
      settings: {
        rowSpacing, 
        columnSpacing,
      },
      background: {
        metersPerPixel,
      },
    } = store.getState();
    return fromPromise(
      recalculateRmZones( productId, rowSpacing,columnSpacing, metersPerPixel, panels, getCurrentRoofId( store ) ) );
  }).switchMap((updatedPanels) => of(UPDATE_PANELS(updatedPanels)));
}

export function updatePanelZone(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(
    PanelsActionTypes.ADD_PANELS)
    .switchMap( ( panels ) => { 
    return fromPromise(
      recalculatePanelZones( store));
  }).switchMap((updatedPanels) => of(UPDATE_PANELS(updatedPanels)));
}

export function updateRmPanelZone(action$: ActionsObservable<AnyAction>, store: Store<appState>): Observable<any> {
  return action$.ofType(
    PanelsActionTypes.UPDATE_PANELS)
    .filter(action => { 
      return action.payload?.type?.type === 'groupId'
    })
    .filter( onlyAsce716RMproducts( store ) ).map( getPanels( store ) ).switchMap( ( panels ) => { 
      const {
        projectConfiguration: {
          productId,
        },
        settings: {
          rowSpacing, 
          columnSpacing,
        },
        background: {
          metersPerPixel,
        },
      } = store.getState();
    return fromPromise(
      recalculateRmZones( productId, rowSpacing, columnSpacing, metersPerPixel, panels, getCurrentRoofId( store ) ) );
  }).switchMap((updatedPanels) => of(UPDATE_PANELS(updatedPanels, {type: 'roofZone'})));
}


export function setPanelsGroupArrangement(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(PanelsActionTypes.SET_PANELS_ARRAY_FOR_MOVEMENT)
  .filter(() => {
    const { panels: { panelsToBeMoved } } = store.getState();
    return panelsToBeMoved.length > 0
  }).switchMap((action)=>{
    const { panels: { panelsToBeMoved, bays: { panelBayMapping }}} = store.getState();
    const {grid, toggleAttachmentsGrid, panelConfig} = createPanelArrayGrid(panelsToBeMoved, panelBayMapping);
    return of(SET_PANELS_ARRAY_GRID_FOR_MOVEMENT(grid, toggleAttachmentsGrid, panelConfig));
  });
}


export function restorePanelsAfterNoMovement(action$: ActionsObservable<AnyAction>, store: Store<appState>) : Observable<any> {
  return action$.ofType(PanelsActionTypes.RESTORE_PANELS_FOR_NO_MOVEMENT)
  .switchMap((action)=>{
    const { panels: { panelsToBeMoved }} = store.getState();
    dispatch(REMOVE_PANELS(panelsToBeMoved.map(p => p.id)));
    return of(REQUEST_ADD_PANELS(panelsToBeMoved), dispatch(RESET_PANELS_ARRAY_FOR_MOVEMENT()));
  });
}