import * as PIXI from 'pixi.js';
import { detectCursorCollisionWithPanels } from '__editor/panelsEditor/components/cursor/cursorCollisions';
import { dispatch, state } from '__common/store';
import { getPanelPositionInfo } from '__editor/panelsEditor/components/roofEdges/roofEdgesCollisions';
import { getStage } from '__editor/panelsEditor/components/stage/stage';
import {
  canDrawCursor,
  cannotDrawCursor,
  drawCursor,
  followSystemCursor,
  mousePosition,
  setCursorStatus,
} from '__editor/panelsEditor/components/cursor/cursor';
import {
  MOVE_CURSOR,
} from 'actions';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { products, isGroundProduct, isRMDT, isGFT, isULA, isAscender } from '__common/constants/products';
import { snap } from './snapToGrid';
import { shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { isBlankMap } from '__common/constants/map';

export const drawCursorOnStage = (renderer, stage: PIXI.Container, cursor: PIXI.Graphics, canvasDomElem: any, productId: string) => {
  if (renderer.plugins && renderer.plugins.interaction.eventData.data === null) {
    return;
  }

  const { editorCursor, background: { moveMapMode }} = state();

  if (cursor && moveMapMode && cursor.alpha !== 1) {
    cursor.alpha = 0.0001;
  } else if (cursor) {
    cursor.alpha = 1;
  }

  const curX = stage.toLocal(renderer.plugins.interaction.eventData.data.global).x;
  const curY = stage.toLocal(renderer.plugins.interaction.eventData.data.global).y;
  const { x, y } = followSystemCursor(curX, curY, productId);
  dispatch(MOVE_CURSOR({ x, y }));

  if (!cursor && x && y) {
    stage.addChild(drawCursor({ x, y }, products[productId]));
  } else if (cursor && x && y) {
    cursor.x = x;
    cursor.y = y;

    const cursorCollision = cursorIsInsideRoof(cursor);

    const cursorColide = detectCursorCollisionWithPanels(cursor) || (cursorCollision && !cursorCollision.isInside);

    // if (cursorCollision && !cursorCollision.isInside) {
    //   dispatch(HIDE_CURSOR());
    //   return;
    // }

    if (cursorColide) {
      cannotDrawCursor();
    } else {
      canDrawCursor();
    }

    if (cursor && cursor.visible !== editorCursor.visible) {
      cursor.visible = editorCursor.visible;
    }

    if (canvasDomElem && canvasDomElem.style.cursor !== editorCursor.systemCursor) {
      canvasDomElem.style.cursor = editorCursor.systemCursor;
    }

    setCursorStatus(cursor, cursorCollision, cursorColide, productId);
  }
};

export function cursorIsInsideRoof(cursor: PIXI.Graphics) {
  const { 
    settings: { 
      panelWidth, 
      panelHeight,
    }, 
    background: { 
      bgXY: { 
        x, 
        y,
      },
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      moveArrayMode,
      metersPerPixel,
    }, 
    roofsSelector: { 
      mapType,
    },
    tiltedRoof: {
      roofPitch,
    },
    projectConfiguration: {
      productId,
      projectVersion,
    },
    panels: {
      panelsToBeMoved,
      panelsArrangementToBeMoved
    },
    settings: {
      columnSpacing,
      rowSpacing,
    }
  } = state();

  if (isBlankMap(mapType) && !shouldUseSetBackDistance(mapType, productId, projectVersion)) {
    return false;
  }
  
  if(moveArrayMode && panelsToBeMoved.length > 0) {
    const cursorCoords = {x : snap(mousePosition()).x, y: snap(mousePosition()).y};
    let panelsArray = []
    const gridCols = panelsArrangementToBeMoved.panelsToBeMovedGrid[0].length;
    const gridRows = panelsArrangementToBeMoved.panelsToBeMovedGrid.length;

    const columnSpacingPx = columnSpacing / metersPerPixel;
    const rowSpacingPx = rowSpacing / metersPerPixel;
  
    const initialY = cursorCoords.y - panelHeight/2;
    let firstModuleIndexOnFirstRow = 0;
    for(let j=0;j<gridCols;j++) {
      if(panelsArrangementToBeMoved.panelsToBeMovedGrid[0][j] == 1)  {
        firstModuleIndexOnFirstRow = j;
        break;
      }
    }
    
    let initialX = cursorCoords.x - (panelWidth + columnSpacingPx) * firstModuleIndexOnFirstRow;
    if(isRMDT(productId)) {
      initialX = cursorCoords.x - ( panelWidth * 2 + columnSpacingPx) * firstModuleIndexOnFirstRow;
    }
    
    for (let j=0; j<gridCols; j++) { 
      for(let i = 0; i < gridRows; i++){
        if(panelsArrangementToBeMoved.panelsToBeMovedGrid[i][j] == 1) {
          if(isRMDT(productId)) {
            panelsArray.push({x: initialX  + ( panelWidth * 2+ columnSpacingPx) * j, y : initialY+(i*(panelHeight+rowSpacingPx))});
            panelsArray.push({x: initialX  + panelWidth + (panelWidth * 2 + columnSpacingPx) * j, y : initialY+(i*(panelHeight+rowSpacingPx))});
          }
          else if(isGFT(productId)) {
            panelsArray.push({x: initialX + (panelWidth + columnSpacingPx) * j, y : initialY+(i*panelHeight)});
          }
          else if(isULA(productId)) {
            panelsArray.push({x: initialX + (panelWidth + columnSpacingPx) * j, y : initialY+(i*(panelHeight+rowSpacingPx))});
          }
          else {
            panelsArray.push({x: initialX + (panelWidth + columnSpacingPx) * j, y : initialY+(i*(panelHeight+rowSpacingPx))});
          }
        }
      }
    }
    
    const panelsArrayInfo = panelsArray.map(panel => getPanelPositionInfo(({x: panel.x, y: panel.y, height: panelHeight, width: panelWidth}), false, x, y,roofEdges, cords, zoom,  degreesToRadians(rotationDegrees), roofPitch));

    return {isInside: panelsArrayInfo.every((p) => p.isInside),   closestDistancesFromEdges: panelsArrayInfo[0].closestDistancesFromEdges};
  }
  const cursorCoords = getCursorCoords(cursor);
  if (cursorCoords !== null && Object.keys(cursorCoords).length > 0) {
    return getPanelPositionInfo(cursorCoords, false, x, y, roofEdges, cords, zoom, degreesToRadians(rotationDegrees), roofPitch);
  }

  return false;
}

function getCursorCoords(cursor: PIXI.Graphics): cursor | null {
  const { settings: { panelWidth, panelHeight }, projectConfiguration: { productId },} = state();
  const stage = getStage();
  const pixiPoint = new PIXI.Point(cursor.getBounds().x, cursor.getBounds().y);
  let { x, y } = stage.toLocal(pixiPoint);
  if (isGroundProduct(productId) || isAscender(productId)) {
    // x, y point to the Top Left Corner of the Cursor
    // update x, y to point to the Cursor center 
    // Width of the GFT/ULA Cursor table is the Width of the CursorContainer(cursor.width)
    // Height of the GFT/ULA Cursor table is the Height of the CursorContainer(cursor.height)
    x += cursor.width / 2;
    y += cursor.height / 2;
    return { x, y, width: cursor.width, height: cursor.height };
  }
  x += (panelWidth / 2);
  y += (panelHeight / 2);
  if (x < 0 || y < 0) {
    return null;
  }

  return { x, y, width: panelWidth, height: panelHeight };
}
